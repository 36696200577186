import React from 'react';
import { Backdrop, Modal, Grid, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { FacebookMessengerIcon, FacebookShareButton, TelegramIcon, TelegramShareButton, WhatsappIcon, WhatsappShareButton } from 'react-share';

const SharePostModal = ({ open, onClose, title, subUrl }) => {
  return (
    <>
      <Modal
        open={open}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        onClick={(e) => e.stopPropagation()}
        onClose={(e, reason) => {
          reason !== 'backdropClick' && onClose();
          e.stopPropagation();
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
          style: { backgroundColor: '#E0E0E0', opacity: '0.5' },
        }}
      >
        <Grid className="share-post-modal">
          <Grid className="header">
            <IconButton className="icon-btn" onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid className="body">
            <FacebookShareButton url={`${window.location.origin}/${subUrl}`} quote={title} hashtag="#creatverse">
              <FacebookMessengerIcon size={52} round={true} />
            </FacebookShareButton>
            <TelegramShareButton url={`${window.location.origin}/${subUrl}`} title={title} hashtag="#creatverse">
              <TelegramIcon size={52} round={true} />
            </TelegramShareButton>
            <WhatsappShareButton url={`${window.location.origin}/${subUrl}`} title={title} hashtag="#creatverse">
              <WhatsappIcon size={52} round={true} />
            </WhatsappShareButton>
          </Grid>
        </Grid>
      </Modal>
    </>
  );
};

export default SharePostModal;
