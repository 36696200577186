import { ResponsiveContainer, LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip } from 'recharts';
import { ChartData } from '../data';

const renderLineChart = (
  <ResponsiveContainer width="100%" height="95%">
    <LineChart width={1000} height={200} data={ChartData}>
      <CartesianGrid strokeDasharray="3 5" vertical={false} />
      <Line type="monotone" dataKey="TokenValue" stroke="#8D79F6" strokeWidth={5} dot={false} />
      <Line type="monotone" dataKey="CreatoValue" stroke="#FE98BF" strokeWidth={2} dot={false} />
      <CartesianGrid stroke="#ccc" />
      <XAxis dataKey="name" strokeWidth={0} />
      <Tooltip />
    </LineChart>
  </ResponsiveContainer>
);

const RechartComponet = () => {
  return <div className="rechart-component">{renderLineChart}</div>;
};

export default RechartComponet;
