import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import axios from 'axios';
import '../../../assets/scss/landing-page/forgot-password.scss';

const ForgotPassword = ({ registerPage }) => {
  const [status, setStatus] = useState(false);

  const formSchema = Yup.object().shape({
    email: Yup.string().email().required('Enter the email'),
  });

  const validationOpt = { resolver: yupResolver(formSchema) };

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm(validationOpt);

  const handleForgotPassword = async (data) => {
    await axios
      .post('https://creato-api.herokuapp.com/api/auth/forgot-password', {
        email: data.email,
      })
      .then((response) => {
        console.log(response);
        setStatus(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      {!status ? (
        <form className="password-recovery-container" onSubmit={handleSubmit(handleForgotPassword)}>
          <h1>Password Recovery</h1>
          <div className="password-recovery-container">
            <label>Email</label>
            <div className="password-recovery-input-container">
              <input type="text" {...register('email')} />
              <p className="error-text2">{errors.email?.message}</p>
            </div>
          </div>

          <div className="password-recovery-button-container">
            <button type="submit" className="forgot-password-submit" style={{ cursor: isDirty ? 'pointer' : 'not-allowed' }}>
              submit
            </button>
            <div className="login-again-button" onClick={registerPage}>
              try to login again
            </div>
          </div>
        </form>
      ) : (
        <div className="redirect-msg-container">
          <p>
            We have sent you a verification link on your email. <br /> Please check it!
          </p>
        </div>
      )}
    </>
  );
};
export default ForgotPassword;
