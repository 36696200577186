import React, { useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';
import '../../../assets/scss/home.scss';
import Header from './Header';
import Footer from './Footer';
import Player from 'video-react/lib/components/Player';
import ControlBar from 'video-react/lib/components/control-bar/ControlBar';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

const PostCard = ({ post, isLiked, setSharePost }) => {
  const [play, setPlay] = useState(false);
  const [fileExt, setFileExt] = useState('');

  useEffect(() => {
    const fileUrl = post.attributes.mediaUrl;
    const fileExt = fileUrl?.split(/[#?]/)[0].split('.').pop().trim();
    if (fileExt?.length) setFileExt(fileExt);
  }, [post.attributes.mediaUrl]);

  const getFile = (fileExt, imgUrl) => {
    switch (fileExt) {
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'gif':
      case 'svg':
        return (
          <div>
            <img
              src={imgUrl}
              alt="svg"
              style={{
                width: '100%',
                height: 'auto',
              }}
              loading="lazy"
            />
          </div>
        );
      case 'mp3':
        return (
          <div
            style={{
              // height: '50%',
              zIndex: '999',
              display: 'grid',
              margin: 'auto',
              alignItems: 'center',
            }}
          >
            <AudioPlayer src={imgUrl} hasDefaultKeyBindings={false} showJumpControls={false} />
          </div>
        );
      case 'mp4':
        return (
          <Box
            onMouseOut={() => setPlay(false)}
            onMouseOver={() => setPlay(true)}
            style={{
              zIndex: '999',
              height: '100%',
              display: 'grid',
              alignItems: 'center',
              // height: '20rem',
              width: '100%',
            }}
          >
            <Player ref={(player) => (!play ? player?.video.pause() : player?.video.play())} muted src={imgUrl} id="player">
              <ControlBar autoHide={false} disableDefaultControls={true}></ControlBar>
            </Player>
          </Box>
        );

      case 'pdf':
        return (
          <div style={{ height: '100vh' }}>
            <div
              style={{
                position: 'absolute',
                left: '10%',
                top: '10%',
                zIndex: 1,
              }}
            >
              {' '}
              {/* <img src={pdfIcon} alt="icon of pdf" />{' '} */}
            </div>
            {/* <Viewer fileUrl={imgUrl} plugins={[pageThumbnailPluginInstance, thumbnailPluginInstance]} /> */}
          </div>
        );
      // case "fbx":
      //   return (
      //     <div style={{ height: "40vh" }}>
      //       <ReactThreeFbxViewer
      //         cameraPosition={cameraPosition}
      //         url={previewValue?.imgUrl}
      //       />
      //     </div>
      //   );
      default:
        return <img srcSet={`"/images/content/card-pic-6@2x.jpg" 2x`} src={'/images/content/card-pic-6@2x.jpg'} alt="jpg" />;
    }
  };

  return (
    <Grid className="post-card">
      <Header post={post} />
      {post.attributes.mediaUrl !== '' ? (
        <Box sx={{ width: '100%' }}>{getFile(fileExt, post?.attributes?.mediaUrl)}</Box>
      ) : (
        <Box sx={{ width: '100%' }}>No Preview found</Box>
      )}
      <Footer post={post} isLiked={isLiked} setSharePost={setSharePost} />
    </Grid>
  );
};

export default PostCard;
