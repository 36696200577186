import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ProfileHeader from './ProfileHeader';
import Profile from './Profile';
import Team from './Team';
import { setIsEditable } from '../../state/user';
import '../../assets/scss/profile.scss';

const MyProfile = () => {
  const [selected, setSelected] = useState('Profile');
  const dispatch = useDispatch();

  return (
    <Grid className="my-profile">
      <Grid sx={{ display: 'flex', gap: '1rem' }}>
        <IconButton className="icon-btn" onClick={() => dispatch(setIsEditable())}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h4">My Profile</Typography>
      </Grid>
      <Box className="profile-wrapper">
        <ProfileHeader selected={selected} setSelected={setSelected} />
        {selected === 'Profile' && <Profile />}
        {selected === 'Team' && <Team />}
      </Box>
    </Grid>
  );
};

export default MyProfile;
