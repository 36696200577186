import React, { forwardRef } from 'react';

const Message = forwardRef((props, ref) => {
  const { m, currentUser, currentChat } = props;
  const profileImg =
    currentUser.displayPic.includes('https') || currentChat.displayPic.includes('https')
      ? m.fromSelf
        ? currentUser.displayPic
        : currentChat.displayPic
      : `data:image/svg+xml;base64,${m.fromSelf ? currentUser.displayPic : currentChat.displayPic}`;

  return (
    <div className={`message ${m.fromSelf ? 'my-self' : ''}`} ref={ref}>
      <img src={profileImg} alt="profile" id="profile-pic" />
      <div className="message-content-container">
        <div className="message-sender">
          <span id="sender">{m.fromSelf ? 'You' : currentChat.name}</span>
          <span id="msg-time">2m</span>
        </div>
        <div className="message-content">
          {/* {m.message.map((msg, i) => ( */}
          <span id="content">{m.message}</span>
          {/* ))} */}
          {/* {m.images && (
            <div id="image-gallery">
              {m.images.map((img) => (
                <img key={img.id} src={require(`../../../assets/images/${img.label}.png`)} alt={`img_${img.id}`} />
              ))}
            </div>
          )} */}
        </div>
      </div>
    </div>
  );
});

export default Message;
