import { setAccount, setWalletState, toggleIsConnecting } from '.';
import web3 from '../../ethereum/web3';
import { web3Service } from '../../ethereum/web3Service';

export const handleWalletConnect = () => async (dispatch) => {
  if (window?.ethereum) {
    dispatch(toggleIsConnecting(true));
    await window?.ethereum.enable();
    try {
      let account = await web3Service.getAccounts();
      let balance = await web3Service.getBalance(account[0]);
      let chain = await web3.eth.getChainId();
      metamaskEventHandler();
      dispatch(
        setWalletState({
          isConnected: true,
          connectedAccount: account[0],
          accountBalance: balance,
          chainId: chain,
          isConnecting: false,
        }),
      );
    } catch (er) {
      console.log('handle wallet connect error --->', er);
      dispatch(toggleIsConnecting(false));
    }
  }
};

const metamaskEventHandler = () => async (dispatch) => {
  if (window?.ethereum) {
    window.ethereum.on('chainChanged', (chainId) => {
      window.location.reload();
    });
    window.ethereum.on('accountsChanged', function (accounts) {
      if (accounts) {
        dispatch(setAccount(accounts[0]));
      }
    });
    window.ethereum.on('message', (message) => {});
    window.ethereum.on('disconnect', (code, reason) => {});
  }
};

export const handleBalance = () => async (dispatch) => {
  let account = await web3Service.getAccounts();
  let balance = await web3Service.getBalance(account[0]);
  dispatch(setWalletState({ accountBalance: balance }));
};
