import React from 'react';
import { Grid, Typography } from '@mui/material';

const CommentCard = ({ comment }) => {
  const date = comment.attributes.createdAt.split('T')[0];
  const time = comment.attributes.createdAt.split('T')[1].split('.')[0];
  const dp = comment.attributes.user.data.attributes.displayPic;
  const profileImg = dp.includes('https') ? dp : `data:image/svg+xml;base64,${dp}`;

  return (
    <Grid sx={{ display: 'flex', height: '4rem', gap: '1rem' }}>
      <img src={profileImg} alt="User Image" style={{ width: '3rem' }} />
      <Grid sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: '0.5rem' }}>
        <Grid sx={{ display: 'flex', gap: '1rem' }}>
          <Typography sx={{ alignSelf: 'center', fontWeight: 'bold' }}>{comment.attributes.user.data.attributes.name}</Typography>
          <Typography sx={{ alignSelf: 'center' }}>{comment.attributes.comment}</Typography>
        </Grid>
        <Typography sx={{ fontSize: '0.6rem' }}>
          {date}, {time}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default CommentCard;
