import React, { useState } from 'react';
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import { SocialLinkProviderData } from '../data';
import downRoundedArrow from '../../../assets/images/down-arrow-rounded.png';

const SocialLinks = (props) => {
  const { zIndex, index, handleRemoveLink, setUrl } = props;

  const [dropDownActive, setDropDownActive] = useState(false);
  const [provider, setProvider] = useState(SocialLinkProviderData[4]);
  const [deleteActive, setDeleteActive] = useState(false);

  const linkDropDownContStyle = {
    backgroundColor: dropDownActive && '#F9F9F9',
    boxShadow: dropDownActive && '1px -3px 12px rgba(0, 0, 0, 0.08)',
  };

  const dropDownListContStyle = {
    display: dropDownActive ? 'block' : 'none',
    backgroundColor: dropDownActive && '#F9F9F9',
    boxShadow: dropDownActive && '0px 3px 5px rgb(0 0 0 / 8%)',
  };

  const providerNameStyle = {
    color: !dropDownActive ? 'black' : '#A4A4A4',
  };

  const handleDropDownActive = () => {
    setDropDownActive(!dropDownActive);
  };

  const handleListClick = (item) => {
    setProvider(item);
  };

  const handleMoreIcon = () => {
    setDeleteActive(!deleteActive);
  };

  const handleUrlChange = (val, param) => {
    param === 'DROP'
      ? setUrl({
          website: '',
          websiteType: val.name,
        })
      : setUrl((prev) => {
          return {
            website: val,
            websiteType: provider.name === 'Website' ? 'Custom website' : prev.websiteType,
          };
        });
  };

  return (
    <div className="social-links-container" style={{ zIndex: zIndex,height : dropDownActive ? '250px' : 'auto' }}>
      <div className="social-links-body">
        <div className="link-dropdown-container">
          <div className="link-widget-left" onClick={handleDropDownActive} style={linkDropDownContStyle}>
            <div className="link-head-icon">
              <img src={provider.img} alt="icon" />
            </div>
            <p className="link-head-title" style={providerNameStyle}>
              {provider.name}
            </p>
            <img src={downRoundedArrow} alt="downRoundedArrow" />
            <div className="drop-down-list-container" style={dropDownListContStyle}>
              <ul>
                {SocialLinkProviderData.map((item) => (
                  <li
                    key={item.name}
                    onClick={() => {
                      handleListClick(item);
                      handleUrlChange(item, 'DROP');
                    }}
                  >
                    <img src={item.img} alt="icon" />
                    <p>{item.name}</p>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="link-widget-right">
            <MoreHorizOutlinedIcon onClick={handleMoreIcon} />
            <p onClick={() => handleRemoveLink(index)} style={{ display: deleteActive ? 'block' : 'none' }}>
              remove link
            </p>
          </div>
        </div>
        <div className="social-link-input-container">
          <label htmlFor="">Your {provider.name} URL</label>
          <input placeholder="https://" onChange={(e) => handleUrlChange(e.target.value, 'URL')} />
        </div>
      </div>
    </div>
  );
};

export default SocialLinks;
