/* User Icons */
import rahul from '../../../assets/images/Ellipse 3.jpg';
import user1 from '../../../assets/images/Ellipse 5.jpg';
import arun from '../../../assets/images/Ellipse 7.jpg';
import saranya from '../../../assets/images/Ellipse 6.jpg';
import { usePreferredState } from '../../../utils/customHooks';

const Community = ({ data }) => {
  const { currentUser } = usePreferredState('user');

  return (
    <div className="community-menu-container">
      <div className="user-icon">
        <img src={data?.data?.attributes?.displayPic} alt="user-icon" />
      </div>
      <div className="community-menu community-left">
        <div className="community-left-head">
          <h1>{data?.data?.attributes?.name}</h1>
          <p>{data?.data?.attributes?.name}</p>
        </div>
        <div className="community-left-body">
          <p>
            {data?.data?.attributes?.purpose}
            {/* <span>Purpose : </span>To form a group like minded people */}
          </p>
        </div>
        <div className="community-left-foot">
          <h1>Tags</h1>
          <div className="tags-container">
            <button className="cursor">Social</button>
            <button className="cursor">Createverse</button>
            <button className="cursor">+</button>
          </div>
        </div>
      </div>
      <div className="community-menu community-center">
        <h1>65.2</h1>
        <h1>
          Impact Score <span>i</span>
        </h1>
      </div>
      <div className="community-menu community-right">
        <div className="buy-sell-container">
          <div className="buy-container">
            <button className="buy-btn cursor">BUY</button>
            <p>{data?.data?.attributes?.name}</p>
          </div>
          <div className="sell-container">
            <button className="sell-btn cursor">SELL</button>
            <div className="sell-desc">
              <p>Price : 25 CREATO</p>
              <p>USD/INR : 252/0.500</p>
            </div>
          </div>
        </div>
        <div className="member-icons-container">
          <div className="member-icons">
            <img src={user1} alt="member-icons" />
            <img src={saranya} alt="member-icons" />
            <img src={arun} alt="member-icons" />
            <img src={rahul} alt="member-icons" />
            <img src={rahul} alt="member-icons" />
          </div>
          <p>+2250 Members</p>
        </div>
      </div>
    </div>
  );
};

export default Community;
