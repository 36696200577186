import React from 'react';
// import ResetPageLeft from './ResetPageLeft';
import ONBOARDING_LEFT_IMG2 from '../../assets/images/ONBOARDING-LEFT-IMG2.png';
import ResetPassword from '../../components/landing-page/onboarding/ResetPassword';

const ResetPasswordPage = () => {
  return (
    <div className="onboarding-page">
      <div className="onboarding-container">
        <div className="onboarding-content onboarding-content---left">
          <img src={ONBOARDING_LEFT_IMG2} alt="ONBOARDING_LEFT_IMG2" />
        </div>
        <div className="onboarding-content onboarding-content---right">
          <button
            className="closeButton"
            onClick={() => {
              window.location.href = window.location.href.split('reset-password')[0];
            }}
          >
            x
          </button>
          <ResetPassword />
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordPage;
