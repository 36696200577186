import { ResponsiveContainer, LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip } from 'recharts';
import { SideChartData } from '../data';

const renderLineChart = (
  <ResponsiveContainer width="100%" height="95%">
    <LineChart data={SideChartData} margin={{ top: 0, right: 0, bottom: 5, left: -25 }}>
      <CartesianGrid strokeDasharray="3 5" vertical={false}/>
      <Line type="monotone" dataKey="creatoValue" stroke="#7C7EF7" strokeWidth={3} dot={false}/>
      <XAxis dataKey="nameX" strokeWidth={0} />
      <YAxis strokeWidth={0}/>
      <Tooltip />
    </LineChart>
  </ResponsiveContainer>
);

const RechartComponet = () => {
  return <div className="rechart-component">{renderLineChart}</div>;
};

export default RechartComponet;
