import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import RechartComponet from './RechartComponent';

const Chart = () => {
  return (
    <div className="chart-container">
      <div className="head">
        <div className="head-data">
          <h1 className="total-balance">Total Balance</h1>
          <div className="price-detail">
            <h1>$60,542.54</h1>
            <p><ArrowUpwardIcon/>  54.345 %</p>
          </div>
        </div>
        <div className="report-btn">
          <button>View Report</button>
        </div>
      </div>
      <div className="body">
        <RechartComponet/>
      </div>
    </div>
  );
};

export default Chart;
