import React, { useState } from 'react';
import { Button, FormControlLabel, Grid, Slider, Switch, TextField, Box, Typography } from '@mui/material';
import styled from '@emotion/styled';
import HashTag from './HashTag';
import { strapiClient } from '../../../..';
import { CREATE_POST } from '../../../../config/mutations';
import Player from 'video-react/lib/components/Player';
import ControlBar from 'video-react/lib/components/control-bar/ControlBar';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import { usePreferredState } from '../../../customHooks';
import { setIsAnime, setLoading, updateRewards } from '../../../../state/user';
import { useDispatch } from 'react-redux';
import { updateAccount } from '../../../../state/user/actions';

const ImageDetails = ({ fileSize, fileExt, imgUrl, closeModal }) => {
  const [description, setDescription] = useState('');
  const [contentSize, setContentSize] = useState(20);
  const [selectedHashTag, setSelectedHashTag] = useState([]);
  const [play, setPlay] = useState(false);

  const { currentUser } = usePreferredState('user');

  const dispatch = useDispatch();
  const getHashtagIds = () => {
    let ids = [];
    selectedHashTag.map((data) => {
      ids.push(data.id);
    });
    return ids;
  };

  const variables = {
    data: {
      description: description,
      owner: currentUser.id,
      mediaUrl: imgUrl,
      likeCount: 0,
      hashtags: getHashtagIds(),
    },
  };

  const handlePost = () => {
    const rewards = currentUser.reward ? currentUser.reward + 100 : 100;

    dispatch(setLoading(true));
    strapiClient
      .request({
        document: CREATE_POST,
        variables: variables,
      })
      .then((data) => {
        const rewardVar = {
          updateAccountId: currentUser.id,
          data: {
            reward: rewards,
          },
        };
        dispatch(setIsAnime({ value: '100', open: true }));
        dispatch(updateRewards(rewards));
        dispatch(updateAccount(rewardVar));
        closeModal();
        dispatch(setLoading(false));
      })
      .catch((e) => {
        console.log('Create post error--->', e);
        dispatch(setLoading(false));
      });
  };

  const getFile = (fileExt, imgUrl) => {
    switch (fileExt) {
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'gif':
      case 'svg':
        return (
          <div>
            <img
              src={imgUrl}
              alt="svg"
              style={{
                width: '100%',
                height: 'auto',
              }}
              loading="lazy"
            />
          </div>
        );
      case 'mp3':
        return (
          <div
            style={{
              // height: '50%',
              zIndex: '999',
              display: 'grid',
              margin: 'auto',
              alignItems: 'center',
            }}
          >
            <AudioPlayer src={imgUrl} hasDefaultKeyBindings={false} showJumpControls={false} />
          </div>
        );
      case 'mp4':
        return (
          <div
            onMouseOut={() => setPlay(false)}
            onMouseOver={() => setPlay(true)}
            style={{
              zIndex: '999',
              height: '100%',
              display: 'grid',
              alignItems: 'center',
              height: '20rem',
            }}
          >
            <Player ref={(player) => (!play ? player?.video.pause() : player?.video.play())} muted src={imgUrl} id="player">
              <ControlBar autoHide={false} disableDefaultControls={true}></ControlBar>
            </Player>
          </div>
        );

      case 'pdf':
        return (
          <div style={{ height: '100vh' }}>
            <div
              style={{
                position: 'absolute',
                left: '10%',
                top: '10%',
                zIndex: 1,
              }}
            >
              {' '}
              {/* <img src={pdfIcon} alt="icon of pdf" />{' '} */}
            </div>
            {/* <Viewer fileUrl={imgUrl} plugins={[pageThumbnailPluginInstance, thumbnailPluginInstance]} /> */}
          </div>
        );
      // case "fbx":
      //   return (
      //     <div style={{ height: "40vh" }}>
      //       <ReactThreeFbxViewer
      //         cameraPosition={cameraPosition}
      //         url={previewValue?.imgUrl}
      //       />
      //     </div>
      //   );
      default:
        return <img srcSet={`"/images/content/card-pic-6@2x.jpg" 2x`} src={'/images/content/card-pic-6@2x.jpg'} alt="jpg" />;
    }
  };

  return (
    <Grid className="image-details">
      <Grid sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
        <Typography sx={{ fontSize: '1rem', fontWeight: 700 }}>Description</Typography>
        <TextField
          id="outlined-multiline-static"
          multiline
          rows={2}
          onChange={(e) => setDescription(e.target.value)}
          value={description}
          sx={{ borderRadius: '1.25rem' }}
        />
      </Grid>
      <HashTag setSelectedHashTag={setSelectedHashTag} selectedHashTag={selectedHashTag} />
      <Grid sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap' }}>
        <Grid sx={{ display: 'flex', gap: '0.5rem' }}>
          <Typography sx={{ fontWeight: 700 }}>Image Size</Typography>
          <Typography style={{ fontWeight: `${fileSize ? 'bold' : ''}` }}>{fileSize}MB</Typography>
        </Grid>
        <ImageSizeSlider
          size="small"
          value={fileSize}
          min={0}
          max={50}
          marks={sizeMarks}
          valueLabelDisplay="off"
          valueLabelFormat={(value) => <Typography>{value} MB</Typography>}
        />
      </Grid>
      <Grid sx={{ display: 'flex', gap: '1rem', justifyContent: 'space-between', paddingTop: '1rem' }}>
        <Typography sx={{ fontWeight: 700 }}>Estimated Registration Fee</Typography>
        <Typography>250 Creato Token</Typography>
      </Grid>
      <Grid sx={{ display: 'flex', justifyContent: 'start' }}>
        <FormControlLabel disabled control={<SwitchComponent />} label="Lossless Image Quality" labelPlacement="start" sx={{ marginLeft: 'unset' }} />
      </Grid>
      <ContentSlider
        marks={marks}
        value={contentSize}
        onChange={(event, newValue) => setContentSize(newValue)}
        valueLabelFormat={(value) => <Typography variant="body1">{value} mb</Typography>}
      />
      <Grid sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
        <Typography sx={{ fontWeight: 700 }}>Thumbnail Preview</Typography>
        {imgUrl !== '' ? (
          <Box sx={{ width: '20rem' }}>{getFile(fileExt, imgUrl)}</Box>
        ) : (
          <Box sx={{ width: '20rem', height: '10rem', background: 'lightGray', display: 'grid', placeContent: 'center' }}>No Preview found</Box>
        )}
      </Grid>
      <ConfirmButton onClick={() => handlePost()}>Confirm</ConfirmButton>
    </Grid>
  );
};

const marks = [
  {
    label: '20mb',
    value: 20,
  },
  {
    label: '100mb',
    value: 100,
  },
];

const sizeMarks = [
  {
    label: '50MB',
    value: 50,
  },
];

const ImageSizeSlider = styled(Slider)(({ theme }) => ({
  marginBottom: 'unset',
  width: '60%',
  '.MuiSlider-thumb': {
    display: 'none',
  },
  '.MuiSlider-rail': {
    color: ' #d9d9d9',
    height: '0.5rem',
  },
  '.MuiSlider-track': {
    color: '#7C7EF7',
    height: '0.5rem',
  },
  '@media (max-width:480px)': {
    width: '100%',
  },
}));

const ContentSlider = styled(Slider)(({ theme, value }) => ({
  '.MuiSlider-thumb': {
    color: 'white',
    // display: 'none',
    outline: '#d9d9d9',
    border: '1px solid #7C7EF7',
    height: value > 16 ? 2 + value : '1rem ',
    width: value > 16 ? 2 + value : '1rem ',
  },
  '.MuiSlider-rail': {
    color: ' #d9d9d9',
    height: ``,
  },
  '.MuiSlider-track': {
    color: '#7C7EF7',
    height: '0.5rem',
  },
}));

const ConfirmButton = styled(Button)(({ theme }) => ({
  background: '#7C7EF7',
  color: 'white',
  padding: '0.25rem 0.5rem',
  borderRadius: '0.5rem',
  textTransform: 'none',
  fontSize: '1rem',
  fontWeight: '500',
  width: '8rem',
  marginTop: '1rem',
  alignSelf: 'flex-end',
  '&:hover': {
    background: '#6c6ff5',
  },
}));

const SwitchComponent = () => {
  return (
    <div className="switch">
      <input className="react-switch-checkbox" id={`react-switch-new`} type="checkbox" disabled={true} />
      <label className="react-switch-label" htmlFor={`react-switch-new`} style={{ cursor: 'not-allowed' }}>
        <span className={`react-switch-button`} />
      </label>
    </div>
  );
};
export default ImageDetails;
