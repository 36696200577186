import React from 'react';
import { useNavigate } from 'react-router-dom';
import { NEG_COLORS } from '../../../utils/constants';
import { usePreferredState } from '../../../utils/customHooks';
import { HorizontalLoader } from '../../../utils/widgets/HorizontalLoader';

const CategoryCard = () => {
  const bgClr = (color) => {
    return { backgroundColor: `#${color}` };
  };
  const navigate = useNavigate();
  const { tokens } = usePreferredState('user');

  return (
    <div className="category-body">
      <ul style={{ justifyContent: tokens.isRequesting || (!tokens.isRequesting && tokens.payload.length === 0) ? 'center' : 'start' }}>
        {tokens.isRequesting && <HorizontalLoader />}
        {!tokens.isRequesting && tokens.payload.length === 0 && <span id="no-data">No tokens found.</span>}
        {!tokens.isRequesting && tokens.payload.length > 0
          ? tokens.payload.map((item) => {
              const random = Math.floor(Math.random() * 16777215).toString(16);
              const bgColor = bgClr(NEG_COLORS.includes(random) ? '388ef1' : random);
              return (
                <li key={item.id} onClick={() => navigate(`/tokens/${item.id}`)} className="movie-data-card" style={bgColor}>
                  <div className="card-head">
                    <div className="card-logo" style={bgColor}>
                      <h1>{item.attributes.name[0].toUpperCase()}</h1>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="card-body-left">
                      <div className="card-left-head">
                        <h1>${item.attributes.symbol}</h1>
                        <p>{item.attributes.name}</p>
                      </div>
                      <div className="card-left-foot">
                        <p>30.95 CREATO</p>
                        <p>=500.55 INR</p>
                      </div>
                    </div>
                    <div className="card-body-right">
                      <div className="card-right-head"></div>
                      <div className="card-right-foot">
                        <p>+3.90%</p>
                      </div>
                    </div>
                  </div>
                </li>
              );
            })
          : null}
      </ul>
    </div>
  );
};

export default CategoryCard;
