import React from 'react';
import { usePreferredState } from '../../../utils/customHooks';

const PostHeadNav = ({ navData, navigateClass = null, setNavigate }) => {
  const { isGuestUser } = usePreferredState('user');

  const handleAnimation = () => {
    setNavigate(null);
  };

  return (
    <>
      {navData.map((item, index) => (
        <p className={navigateClass} onAnimationEnd={handleAnimation} key={index} style={{ cursor: isGuestUser ? 'not-allowed' : 'pointer' }}>
          {item}
        </p>
      ))}
    </>
  );
};

export default PostHeadNav;
