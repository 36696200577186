import React, { useState } from 'react';
import { Backdrop, Modal, Grid, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ImageDetails from './ImageDetails';
import ImageUpload from './ImageUpload';

const UploadImageModal = ({ open, onClose, style }) => {
  const [fileSize, setFileSize] = useState(0);
  const [imgUrl, setImgUrl] = useState('');
  const [fileExt, setFileExt] = useState('');

  return (
    <>
      <Modal
        open={open}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        onClick={(e) => e.stopPropagation()}
        onClose={(e, reason) => {
          reason !== 'backdropClick' && onClose();
          e.stopPropagation();
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
          style: { backgroundColor: '#E0E0E0', opacity: '0.5' },
        }}
      >
        <Grid container className="upload-image-modal" style={style}>
          <Grid className="header">
            <IconButton
              onClick={() => {
                onClose();
                setFileSize(0);
                setImgUrl('');
                setFileExt('');
              }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid className="body">
            <ImageUpload setFileSize={setFileSize} imgUrl={imgUrl} setImgUrl={setImgUrl} setFileExt={setFileExt} />
            <ImageDetails
              fileSize={fileSize}
              fileExt={fileExt}
              imgUrl={imgUrl}
              closeModal={(e, reason) => {
                onClose();
              }}
            />
          </Grid>
        </Grid>
      </Modal>
    </>
  );
};

export default UploadImageModal;
