import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setCurrentChat, setReadMessages, setMarkAllAsRead } from '../../state/user';
import { usePreferredState } from '../../utils/customHooks';
import '../../assets/scss/notification-modal.scss';

const NotificationModal = ({ setNotificationModal }) => {
  const dispatch = useDispatch();

  const [seeMore, setSeeMore] = useState(false);
  const { contactsById, notifications } = usePreferredState('user');

  const handleChatSelection = (user) => {
    dispatch(setCurrentChat(user));
    dispatch(setReadMessages(user.id));
    setNotificationModal(false);
  };

  return (
    <div className={`notification-modal-container ${seeMore ? 'see-more' : ''}`}>
      <div className="notification-heading">
        <h4>Notifications</h4>
        <p
          onClick={() => {
            dispatch(setMarkAllAsRead());
            setNotificationModal(false);
          }}
        >
          Mark all as read
        </p>
      </div>
      <div className="notification-messages">
        {Object.keys(notifications).map((userId) => {
          const nInfo = notifications[userId];
          return nInfo.map((n, i) => {
            const nFrom = contactsById[n.from][0];
            const profileImg = nFrom.displayPic.includes('https') ? nFrom?.displayPic : `data:image/svg+xml;base64,${nFrom?.displayPic}`;

            return (
              <div className="notification-message-border" key={`${userId}_${i}`} onClick={() => handleChatSelection(nFrom)}>
                <div className="notification-image-container">
                  <img src={profileImg} alt="from user" />
                </div>
                <div className="notification-message-area">
                  <div className="notification-name">
                    <h4>{nFrom.name}</h4>
                    <p>2s</p>
                  </div>
                  <p>{n.message}</p>
                </div>
              </div>
            );
          });
        })}
      </div>
      <div className="notification-see-all">
        {Object.values(notifications).flat().length > 3 && <p onClick={() => setSeeMore(!seeMore)}>{seeMore ? 'see less' : 'see more'}</p>}
      </div>
    </div>
  );
};

export default NotificationModal;
