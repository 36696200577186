import React, { useEffect, useState } from 'react';
import { Chip, Grid, IconButton, Stack, Typography, Box, useMediaQuery } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import ReplyIcon from '@mui/icons-material/Reply';
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import Player from 'video-react/lib/components/Player';
import ControlBar from 'video-react/lib/components/control-bar/ControlBar';
import AudioPlayer from 'react-h5-audio-player';
import { updateAccount } from '../../../state/user/actions';
import { likePost, setIsAnime, setComments } from '../../../state/user';
import 'react-h5-audio-player/lib/styles.css';
import StarRatings from '../Ratings';
import { usePreferredState } from '../../customHooks';
import SharePostModal from '../modals/SharePostModal';

const DetailsContainer = ({ post, onClose }) => {
  const { currentUser, postData } = usePreferredState('user');
  const [play, setPlay] = useState(false);
  const [isLiked, setIsLiked] = useState(false);
  const [likedPosts, setLikedPosts] = useState([]);
  const [fileExt, setFileExt] = useState('');
  const [sharePost, setSharePost] = useState(false);
  const dp = post?.attributes?.owner?.data?.id === currentUser.id ? currentUser.displayPic : post?.attributes?.owner?.data?.attributes?.displayPic;
  const profileImg = dp.includes('https') ? dp : `data:image/svg+xml;base64,${dp}`;
  const dispatch = useDispatch();
  const matches = useMediaQuery('(max-width: 960px)');

  const currentPost = postData && postData.filter((item) => item.id === post.id)[0].attributes;

  useEffect(() => {
    const fileUrl = post.attributes.mediaUrl;
    const fileExt = fileUrl?.split(/[#?]/)[0].split('.').pop().trim();
    if (fileExt?.length) setFileExt(fileExt);
  }, [post.attributes.mediaUrl]);

  useEffect(() => {
    if (post) {
      let hasLike = currentPost.likedUsers.data.some((user) => user.id === currentUser.id);
      if (hasLike) setIsLiked(true);
    }
  }, [post]);

  const handleLikes = async (post) => {
    axios
      .post(
        `${process.env.REACT_APP_STRAPI_URL}/api/likePost/${post.id}`,
        {
          accountId: currentUser.id,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${localStorage.getItem('token')} `,
          },
        },
      )
      .then((res) => {
        if (res.data.data.id) {
          let rewards = currentUser.reward;
          rewards = res.data.data.likeCount > post.attributes.likeCount ? rewards + 10 : rewards - 10;
          const rewardVar = {
            updateAccountId: currentUser.id,
            data: {
              reward: rewards,
            },
          };
          if (res.data.data.likeCount > currentPost.likeCount && !isLiked) dispatch(setIsAnime({ value: '10', open: true }));
          dispatch(
            likePost({
              postId: res.data.data.id,
              liked: res.data.data.likeCount,
              user: { id: currentUser.id, attributes: { name: currentUser.name } },
            }),
          );
          dispatch(updateAccount(rewardVar));
          setIsLiked(!isLiked);
        }
      });
  };

  const getFile = (fileExt, imgUrl) => {
    switch (fileExt) {
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'gif':
      case 'svg':
        return (
          <img
            src={imgUrl}
            alt="svg"
            style={{
              width: '100%',
              height: '100%',
            }}
            loading="lazy"
          />
        );
      case 'mp3':
        return (
          <div
            style={{
              height: '100%',
              zIndex: '999',
              display: 'grid',
              margin: 'auto',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <AudioPlayer src={imgUrl} hasDefaultKeyBindings={false} showJumpControls={false} />
          </div>
        );
      case 'mp4':
        return (
          <Box
            onMouseOut={() => setPlay(false)}
            onMouseOver={() => setPlay(true)}
            style={{
              zIndex: '999',
              height: '100%',
              display: 'grid',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <Player
              ref={(player) => (!play ? player?.video.pause() : player?.video.play())}
              muted
              src={imgUrl}
              id="player"
              fluid={false}
              height="100%"
            >
              <ControlBar autoHide={false} disableDefaultControls={true}></ControlBar>
            </Player>
          </Box>
        );

      case 'pdf':
        return (
          <div style={{ height: '100vh' }}>
            <div
              style={{
                position: 'absolute',
                left: '10%',
                top: '10%',
                zIndex: 1,
              }}
            >
              {' '}
              {/* <img src={pdfIcon} alt="icon of pdf" />{' '} */}
            </div>
            {/* <Viewer fileUrl={imgUrl} plugins={[pageThumbnailPluginInstance, thumbnailPluginInstance]} /> */}
          </div>
        );
      // case "fbx":
      //   return (
      //     <div style={{ height: "40vh" }}>
      //       <ReactThreeFbxViewer
      //         cameraPosition={cameraPosition}
      //         url={previewValue?.imgUrl}
      //       />
      //     </div>
      //   );
      default:
        return <img srcSet={`"/images/content/card-pic-6@2x.jpg" 2x`} src={'/images/content/card-pic-6@2x.jpg'} alt="jpg" />;
    }
  };

  return (
    <Grid className="description-container">
      <Grid className="description-header">
        <Grid className="user-details">
          <img src={profileImg} alt="User Image" style={{ height: '4rem', borderRadius: '50%', width: '4rem' }} className="user-img" />
          <Grid sx={{ display: 'flex', flexDirection: 'column', gap: '0.25rem' }}>
            <Grid sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
              <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                {post?.attributes?.owner?.data?.attributes?.name}
              </Typography>
              <StarRatings rating={post?.attributes.likeCount} />
            </Grid>
            <Grid sx={{ display: 'flex', alignItems: 'center', gap: '0.25rem' }}>
              <Stack direction="row" spacing={1}>
                <Stack direction="row" spacing={1}>
                  {post.attributes.hashtags.data.slice(0, 3).map((data, i) => {
                    let randomColor = Math.floor(Math.random() * 16777215).toString(16);
                    return <Chip key={i} label={data.attributes.hashtag} className="chip" style={{ background: `${'#' + randomColor}` }} />;
                  })}
                </Stack>
              </Stack>
              <Grid sx={{ display: 'flex', alignItems: 'center' }}>
                <LocationOnIcon style={{ height: '1rem', color: '#ABABB1' }} />
                <Typography>
                  {/* {post.location} */}
                  chennai
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          {/* <Grid sx={{ display: 'flex', flexDirection: 'column', gap: '0.25rem', paddingLeft: '0.5rem' }}>
            <Grid className="menu-wrap">
              <MenuIcon />
              <Typography>0.50</Typography>
            </Grid>
            <Grid sx={{ display: 'flex', alignItems: 'center' }}>
              <AccessTimeIcon style={{ height: '0.75em' }} />
              <Typography>
                30 minutes left
              </Typography>
            </Grid>
          </Grid> */}
        </Grid>
        {/* <IconButton className="icon-btn">
          <WorkspacesIcon />
        </IconButton> */}
        <IconButton
          className="chat-btn"
          onClick={() => {
            dispatch(setComments());
            onClose();
          }}
        >
          <CloseIcon />
        </IconButton>
      </Grid>
      {post.attributes.mediaUrl !== '' ? (
        <Box
          sx={{
            width: '100%',
            minHeight: '40%',
            // maxHeight: '70%',
            overflow: 'hidden',
            border: '5px solid #7b7ef6',
            borderRadius: '0.5rem',
            display: 'flex',
          }}
        >
          {getFile(fileExt, post?.attributes?.mediaUrl)}
        </Box>
      ) : (
        <Box sx={{ width: '100%' }}>No Preview found</Box>
      )}
      {!matches && (
        <Grid className="description-footer">
          <Grid className="icon-grp">
            <IconButton className="fav-icon" onClick={() => handleLikes(post)}>
              {isLiked ? <FavoriteIcon style={{ color: '#e65a90' }} /> : <FavoriteIcon />}
            </IconButton>
            <IconButton className="forward-icon" onClick={() => setSharePost(true)}>
              <ReplyIcon />
            </IconButton>
          </Grid>
        </Grid>
      )}
      <SharePostModal
        open={sharePost}
        onClose={() => setSharePost(false)}
        title="Hey there! Check this new post on Creatverse."
        subUrl={`post/${post.id}`}
      />
    </Grid>
  );
};

export default DetailsContainer;
