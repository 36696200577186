import React, { useState } from 'react';
import '../../../assets/scss/chat/left-side-menu.scss';
import { MEMBERS_LIST, OPTIONS_LIST } from '../../../utils/constants';
import LiveCard from './LiveCard';
import MemberCard from './MemberCard';
import SearchIcon from '../../../assets/images/searchIcon.png';
import { usePreferredState } from '../../../utils/customHooks';
import { setCurrentChat, setReadMessages } from '../../../state/user';
import { useDispatch } from 'react-redux';

const LeftSideMenu = () => {
  const [page, setPage] = useState('DIRECT');
  const { contacts } = usePreferredState('user');
  const [searchedName, setSearchedName] = useState('');

  const dispatch = useDispatch();
  let filteredUser = contacts.filter((user) => user.name.toLocaleLowerCase().includes(searchedName));

  const getInputValue = (e) => {
    setSearchedName(e.target.value.trim().toLocaleLowerCase());
  };

  return (
    <div className="left-side-menu">
      <div className="search-container">
        <div className="input-with-icon">
          <input type="text" autoComplete="off" placeholder="Search" onChange={getInputValue} />
          <img src={SearchIcon} alt="search" />
        </div>
      </div>
      <div className="members-container">
        <div className="members-header">
          {['DIRECT', 'GROUPS', 'WORKSPACES'].map((label, i) => (
            <div key={`${i}_${label}`} className={`option`}>
              <span onClick={() => (i === 0 ? setPage(label) : {})} className={`label ${page === label ? 'selected' : ''}`}>
                {label}
              </span>
              <p className={`${page === label ? 'dot' : ''}`}></p>
            </div>
          ))}
        </div>
        <div className={`members-list ${searchedName.length > 0 && !filteredUser.length ? 'no-data' : ''}`}>
          {searchedName.length > 0 && !filteredUser.length ? (
            <span id="no-data">No users found.</span>
          ) : (
            filteredUser.map((member) => {
              const bgColors = ['#EAF9E6', '#F2F2FF', '#F8C56C', '#8C8EF8'];
              const bgColor = bgColors[Math.floor(Math.random() * Math.floor(bgColors.length))];
              return (
                <div
                  className={`member-card ${member.live ? 'live-card' : ''}`}
                  style={{ '--bgColor': '#EAF9E6' }}
                  key={member.id}
                  onClick={() => {
                    dispatch(setCurrentChat(member));
                    dispatch(setReadMessages(member.id));
                  }}
                >
                  {member.live ? <LiveCard member={member} /> : <MemberCard member={member} />}
                </div>
              );
            })
          )}
        </div>
      </div>
      {/* <div className="options-container">
        {OPTIONS_LIST.map((option) => (
          <div className="option" key={option.id}>
            <span id="icon">
              <img src={require(`../../../assets/images/${option.label}.png`)} alt={option.label} style={option.style} />
            </span>
            <span id="label">{option.label.toUpperCase()}</span>
          </div>
        ))}
      </div> */}
    </div>
  );
};

export default LeftSideMenu;
