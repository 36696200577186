import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Confirmation from './Confirmation';
import CreateContainer from './CreateContainer';
import useMediaQuery from '@mui/material/useMediaQuery';
import closeIcon from '../../../assets/images/Close.svg';
import closeIcon2 from '../../../assets/images/close.png';

const Layout = () => {
  const navigate = useNavigate();

  const [confirm, setConfirm] = useState(false);
  const matches = useMediaQuery('(max-width:480px)');

  const handleConfirm = () => {
    setConfirm(true);
  };

  const communityComponents = [<Confirmation handleConfirm={handleConfirm} />, <CreateContainer />];

  return (
    <div className="community-layout">
      <div className="close-icon">
        <img src={matches ? closeIcon2 : closeIcon} alt="icon" onClick={() => navigate(-1)} />
      </div>
      <div className="community-content" style={{ padding: confirm && '0' }}>
        {!confirm ? communityComponents[0] : communityComponents[1]}
      </div>
    </div>
  );
};

export default Layout;
