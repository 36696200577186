import { useRef } from 'react';
import CreateCommunityHead from './head/CreateCommunityHead';
import closeIcon from '../../../assets/images/close.png';
import { CommunityTopicsHeadData } from '../data';

const CommunityTopics = ({ topics, setTopics }) => {
  // const [topicList, setTopicList] = useState([]);
  const topicRef = useRef(null);

  const handleAddTopic = () => {
    let topic = topicRef.current.value;
    topic !== '' && setTopics([topic, ...topics]);
    topicRef.current.value = '';
  };

  const handleDeleteTopic = (id) => {
    let newTopicList = [...topics];
    newTopicList.splice(id, 1);
    setTopics([...newTopicList]);
  };

  const handleEditTopic = (e) => {
    let value = e.target.innerHTML;
    let parent = e.target.parentElement;
    parent.children[1].classList.add('inactive');
    parent.children[2].classList.add('active');
    parent.children[2].value = value;
  };

  const handleInputNewValue = (e, id) => {
    let newValue = e.target.value;
    let parent = e.target.parentElement;

    let newTopicList = [...topics];
    newTopicList[id] = newValue;
    setTopics([...newTopicList]);

    parent.children[1].classList.remove('inactive');
    parent.children[2].classList.remove('active');
  };

  return (
    <div className="community-topics-container">
      <CreateCommunityHead head={CommunityTopicsHeadData} />
      <div className="com-top-body">
        <div className="input-container">
          <label htmlFor="add-topic">Topic name</label>
          <input ref={topicRef} type="text" id="add-topic" placeholder="Your first topic here" />
        </div>
        <div className="topic-list-container">
          <ul className="scroll-bar-4">
            {topics.length > 0 &&
              topics.map((item, index) => (
                <li key={index}>
                  <p htmlFor="add-topic">Topic name</p>
                  <p onClick={handleEditTopic}>{item}</p>
                  <input type="text" onBlur={(event) => handleInputNewValue(event, index)} className="edit-topic" />
                  <img onClick={() => handleDeleteTopic(index)} src={closeIcon} alt="icon" />
                </li>
              ))}
          </ul>
        </div>
        <p onClick={handleAddTopic} className="add-topic-btn">
          + Add new topic
        </p>
      </div>
    </div>
  );
};

export default CommunityTopics;
