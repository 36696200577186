import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setCurrentChat, setReadMessages } from '../../../state/user';
import { usePreferredState } from '../../../utils/customHooks';
import { searchWord } from '../../../utils/helpers';
import SEARCH_ICON from '../../../assets/images/searchIcon.png';

const ChatBody = () => {
  const dispatch = useDispatch();
  const { contacts, notifications, activeUsers } = usePreferredState('user');
  const [searchedName, setSearchedName] = useState('');

  const getInputValue = (e) => {
    setSearchedName(e.target.value.toLocaleLowerCase());
  };

  let onlineUsers = contacts.filter((c) => activeUsers.includes(c.id));
  let filteredUser = onlineUsers.filter((user) => searchWord(user.name.toLocaleLowerCase().trim(), searchedName));

  const handleChatSelection = (user) => {
    dispatch(setCurrentChat(user));
    dispatch(setReadMessages(user.id));
  };

  return (
    <>
      <div className=" chat-search-input">
        <div className="chat-input-with-icon">
          <img src={SEARCH_ICON} alt="SEARCH_ICON" />
          <input type="text" placeholder="Search" onChange={getInputValue} />
        </div>
      </div>
      <div className="chat-content">
        {filteredUser.length > 0 ? (
          filteredUser.map((item) => {
            const profileImg = item.displayPic.includes('https') ? item.displayPic : `data:image/svg+xml;base64,${item?.displayPic}`;

            const hasNotifications = notifications[item.id]?.length;
            return (
              <div className="chat-border" key={item.id} onClick={() => handleChatSelection(item)}>
                <div className="chat-message">
                  <img src={profileImg} alt="avatar" />
                  <div style={{ height: '100%' }}>
                    <h3>{item.name}</h3>
                  </div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>{hasNotifications && <span id="noti-count">{hasNotifications}</span>}</div>
              </div>
            );
          })
        ) : (
          <div className="no-user">
            <p style={{ color: 'grey' }}>No active users found..!</p>
          </div>
        )}
      </div>
    </>
  );
};

export default ChatBody;
