import dollarIcon from '../../../assets/images/dollar-icon.png';
import dotCirc from '../../../assets/images/dot-circ.png';
import { AfterTokenData } from '../data';
import { useNavigate,Link } from 'react-router-dom';

const Confirmation = ({ handleConfirm }) => {
  const navigate = useNavigate();
  return (
    <div className="confirmation-container">
      <div className="confirmation-head">
        <h1>Confirmation</h1>
      </div>
      <div className="confirmation-body">
        <div className="coin-details">
          <div className="coin-details-top">
            <img src={dollarIcon} alt="icon" />
            <p>
              You need <span>10 000 creato tokens </span>
              to create a community
            </p>
          </div>
          <div className="coin-details-bottom">
            <div className="coin-bottom-left">
              <div className="coin-btm-icon">
                <img src={dollarIcon} alt="icon" />
              </div>
              <div className="coin-btm-details">
                <p>Table Creato Balance</p>
                <h1>19,500</h1>
              </div>
            </div>
            <div className="coin-bottom-right">
              <button>+ Buy</button>
            </div>
          </div>
        </div>
        <div className="token-details">
          <p className="token-details-title">After creating a community</p>
          <div className="token-details-content">
            <ul>
              {AfterTokenData.map((item) => (
                <li key={item.title}>
                  <div className="dot-circ">
                    <img src={dotCirc} alt="icon" />
                  </div>
                  <div className="token-details-data">
                    <p>{item.title}</p>
                    <p>{item.desc}</p>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="button-container">
          <button className="continue-btn" onClick={handleConfirm}>
            Continue
          </button>
          <Link to={'/community'}>
            <p>Cancel</p>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Confirmation;
