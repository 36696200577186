import React from 'react';
import { Grid, Typography } from '@mui/material';
import { PROFILE_HEADERS } from '../../utils/constants';
import vector from '../../assets/images/Vector.svg';

const ProfileHeader = ({ setSelected, selected }) => {
  return (
    <Grid className="profile-header">
      {PROFILE_HEADERS.map((heading, i) => (
        <div style={{ position: 'relative' }} key={i}>
          <Typography className={`heading ${selected === heading ? 'selectedColor' : ''}`} onClick={() => setSelected(heading)}>
            {heading}
          </Typography>
          <span className="vector">
            <img src={vector} className={`${(selected === 'Profile' || selected === 'Team') && selected === heading ? 'selected' : 'hide'}`} />
          </span>
        </div>
      ))}
    </Grid>
  );
};

export default ProfileHeader;
