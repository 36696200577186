import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import OnBoardingPage from './onboarding';
import { strapiClient } from '../..';
import { setGuestUser } from '../../state/user';
import BlurBg from './BlurBg';
import '../../assets/scss/landing-page/landing-page.scss';

const ProcessSelection = (props) => {
  const dispatch = useDispatch();
  const { onSignup, initialPage, onRegister } = props;

  const viewAsGuest = () => {
    strapiClient.setHeader('authorization', `Bearer ${process.env.REACT_APP_GUEST_KEY}`);
    localStorage.setItem('token', process.env.REACT_APP_GUEST_KEY);
    localStorage.setItem('isGuestUser', true);
    dispatch(setGuestUser());
  };

  return (
    <div className="modalBackground">
      <div
        className="modalContainer"
        style={{ width: !initialPage ? '100%' : '', height: !initialPage ? '100%' : '', backgroundColor: !initialPage ? 'transparent' : '' }}
      >
        <div className="orb-canvas-container">
          <BlurBg />
          <div className="landingpage-container">
            <h1 className="createverse-title animate__animated animate__fadeIn">Creatverse</h1>
            <div className="welcome-container">
              <div className="welcome-component">
                <div className="welcome-head">
                  <h1 className="animate__animated animate__fadeIn">Welcome</h1>
                </div>
                <div className="welcome-body animate__animated animate__fadeIn">
                  <button className="welcome-btn register-btn animate__animated animate__fadeInDown" onClick={onRegister}>
                    Register Account
                  </button>
                  <button className="welcome-btn guest-btn animate__animated animate__fadeInDown" onClick={viewAsGuest}>
                    View as Guest
                  </button>
                </div>
                <div className="welcome-foot animate__animated animate__fadeInDown">
                  <p>
                    Already have an account?{' '}
                    <span onClick={onSignup} style={{ cursor: 'pointer' }}>
                      Sign In
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const LandingPage = () => {
  const [openModal, setOpenModal] = useState(true);
  const [initialPage, setInitialPage] = useState(false);
  const [isLogin, setIsLogin] = useState(false);

  const handleOnClose = () => {
    setInitialPage(false);
    setIsLogin(false);
  };

  const onSignup = () => {
    setInitialPage(true);
    setIsLogin(true);
  };

  if (openModal && initialPage === false)
    return <ProcessSelection initialPage={initialPage} onSignup={onSignup} onRegister={() => setInitialPage(true)} />;

  if (openModal && initialPage === true)
    return (
      <OnBoardingPage
        isLogin={isLogin}
        setIsLogin={setIsLogin}
        handleClose={handleOnClose}
        previousPage={() => {
          setInitialPage(false);
          setIsLogin(false);
        }}
      />
    );
};

export default LandingPage;

//old comment
// (
//   <>
//     <h1>Welcome to Creatverse</h1>
//     <h2>Let's start!</h2>
//     <div className="videoContainer">
//       <img src={VideoFrameImg} alt="" draggable="false" />
//     </div>
//     <button className="registerButton" onClick={() => setInitialPage(true)}>
//       Register Account
//     </button>
//     <button className="guestButton" onClick={viewAsGuest}>
//       View as Guest
//     </button>
//     <p>
//       Already have an account?{' '}
//       <span
//         onClick={() => {
//           setInitialPage(true);
//           setIsLogin(true);
//         }}
//       >
//         Sign in
//       </span>
//     </p>
//   </>
// )

// return (
//   <>
//     {openModal && initialPage === false ? (
//       <ProcessSelection initialPage={initialPage} onSignup={onSignup} onRegister={() => setInitialPage(true)} />
//     ) : openModal && initialPage === true ? (
//       <OnBoardingPage
//         isLogin={isLogin}
//         setIsLogin={setIsLogin}
//         handleClose={handleOnClose}
//         previousPage={() => {
//           setInitialPage(false);
//           setIsLogin(false);
//         }}
//       />
//     ) : null}
//   </>
// );
