//Movie Images
import KabaliBlurred from '../../../../../assets/images/KABALI_BLURRED.png';
import Kabali from '../../../../../assets/images/KABALI.png';
import Enthiran from '../../../../../assets/images/ENTHIRAN_2.png';
import Kaala from '../../../../../assets/images/KAALA.png';

//Book Images
import Book1 from '../../../../../assets/images/BOOK_1.png';
import Book2 from '../../../../../assets/images/BOOK_2.png';

import PORTFOLIO_IMAGE_1 from '../../../../../assets/images/PORTFOLIO_IMAGE_1.png';
import PORTFOLIO_IMAGE_2 from '../../../../../assets/images/PORTFOLIO_IMAGE_2.png';
import PORTFOLIO_IMAGE_3 from '../../../../../assets/images/PORTFOLIO_IMAGE_3.png';
import PORTFOLIO_IMAGE_4 from '../../../../../assets/images/PORTFOLIO_IMAGE_4.png';
import PORTFOLIO_IMAGE_5 from '../../../../../assets/images/PORTFOLIO_IMAGE_5.png';

export const movieDataRaw = [
  {
    id: Math.random(),
    name: 'kabali-blurred',
    backgroundImage: `url(${KabaliBlurred})`,
  },
  {
    id: Math.random(),
    name: 'kabali',
    backgroundImage: `url(${Kabali})`,
  },
  {
    id: Math.random(),
    name: 'enthiran-2',
    backgroundImage: `url(${Enthiran})`,
  },
  {
    id: Math.random(),
    name: 'kaala',
    backgroundImage: `url(${Kaala})`,
  },
  {
    id: Math.random(),
    name: 'petta',
    backgroundImage: `url(https://i.pinimg.com/originals/40/6d/9a/406d9adc02a28a28ca9daa584ae09ceb.png)`,
  },
  {
    id: Math.random(),
    name: 'annathe',
    backgroundImage: `url(https://pbs.twimg.com/media/EucOEGcXUAAr1eL?format=jpg&name=4096x4096)`,
  },
];

export const bookDataRaw = [
  {
    id: Math.random(),
    name: 'book-1',
    backgroundImage: `url(${Book1})`,
  },
  {
    id: Math.random(),
    name: 'book-2',
    backgroundImage: `url(${Book2})`,
  },
];

export const PORTFOLIO_IMAGES = [
  { id: 1, src: PORTFOLIO_IMAGE_1 },
  { id: 2, src: PORTFOLIO_IMAGE_2 },
  { id: 3, src: PORTFOLIO_IMAGE_3 },
  { id: 4, src: PORTFOLIO_IMAGE_4 },
  { id: 5, src: PORTFOLIO_IMAGE_5 },
];
