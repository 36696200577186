import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import Message from './Message';
import { sendMessageRoute, recieveMessageRoute } from '../../../routes/APIRoutes';
import { usePreferredState } from '../../../utils/customHooks';
import { ChatState } from '../../context/ChatProvider';
import { setMessages } from '../../../state/user';
import { HorizontalLoader } from '../../../utils/widgets/HorizontalLoader';

const MESSAGE_ICONS = [
  { id: 1, label: 'mic' },
  { id: 2, label: 'attach' },
  { id: 3, label: 'send' },
];

const ChatAreaBody = () => {
  const { socket } = ChatState();
  const dispatch = useDispatch();
  const scrollRef = useRef();

  const [message, setMessage] = useState('');
  const [msgLoading, setMsgLoading] = useState(false);
  // const [typing, setTyping] = useState(false);
  // const [istyping, setIsTyping] = useState(false);
  const { currentChat, currentUser, messages } = usePreferredState('user');

  const profileImg = currentUser.displayPic.includes('https') ? currentUser.displayPic : `data:image/svg+xml;base64,${currentUser?.displayPic}`;

  useEffect(() => {
    const getMessages = async () => {
      setMsgLoading(true);
      try {
        const response = await axios.post(recieveMessageRoute, {
          from: currentUser.id,
          to: currentChat.id,
        });

        dispatch(setMessages(response.data));
        socket.current.emit('join chat', { chatId: currentChat.id, userId: currentUser.id });
        setMsgLoading(false);
      } catch (e) {
        console.log('Getting messages from back-end error --->', e);
        setMsgLoading(false);
      }
    };
    getMessages();
  }, [currentChat]);

  useEffect(() => {
    if (msgLoading) return;
    scrollRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages.length, msgLoading]);

  // useEffect(() => {
  //   socket.current.on('typing', () => setIsTyping(true));
  //   socket.current.on('stop typing', () => setIsTyping(false));

  //   // eslint-disable-next-line
  // }, []);

  // const typingHandler = (e) => {
  //   setMessage(e.target.value);

  //   // if (!socketConnected) return;

  //   if (!typing) {
  //     setTyping(true);
  //     socket.current.emit('typing', currentChat.id);
  //   }
  //   let lastTypingTime = new Date().getTime();
  //   var timerLength = 3000;
  //   setTimeout(() => {
  //     var timeNow = new Date().getTime();
  //     var timeDiff = timeNow - lastTypingTime;
  //     if (timeDiff >= timerLength && typing) {
  //       socket.current.emit('stop typing', currentChat.id);
  //       setTyping(false);
  //     }
  //   }, timerLength);
  // };

  const sendMessage = async () => {
    if (message.trim().length > 0) {
      socket.current.emit('stop typing', currentChat.id);
      try {
        await axios.post(sendMessageRoute, {
          from: currentUser.id,
          to: currentChat.id,
          message,
        });

        socket.current.emit('send-msg', {
          from: currentUser.id,
          to: currentChat.id,
          message,
        });

        const msgs = [...messages];
        msgs.push({ fromSelf: true, message });
        dispatch(setMessages(msgs));
        setMessage('');
      } catch (error) {
        console.log('Sending new message error --->', error);
      }
    }
  };

  return (
    <div className="chat-area-body">
      <div className="message-container">
        <p id="time">Today 10.30 pm</p>
        <div
          style={{
            display: `${msgLoading || messages.length === 0 ? 'flex' : ''}`,
            justifyContent: `${msgLoading || messages.length === 0 ? 'center' : ''}`,
            alignItems: `${msgLoading || messages.length === 0 ? 'center' : ''}`,
            height: '100%',
          }}
        >
          {!msgLoading &&
            messages.length !== 0 &&
            messages.map((m) => <Message ref={scrollRef} key={uuidv4()} m={m} currentChat={currentChat} currentUser={currentUser} />)}
          {msgLoading && <HorizontalLoader />}
          {!msgLoading && messages.length === 0 && <p id="no-data">No chats yet...</p>}
        </div>
      </div>
      <div className="message-input-container">
        <div className="input-with-icon">
          <input
            type="text"
            autoComplete="off"
            placeholder="Your message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            onKeyPress={(e) => e.key === 'Enter' && sendMessage()}
          />
          <img id="profile" src={profileImg} alt="current-user-avatar" style={{ height: '1.875rem', width: '1.875rem' }} />
          <div className="message-operations">
            {MESSAGE_ICONS.map((icon, i) => (
              <img
                key={icon.id}
                src={require(`../../../assets/images/${icon.label}.png`)}
                alt={icon.label}
                onClick={() => (i === 2 ? sendMessage() : {})}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatAreaBody;
