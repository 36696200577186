import React, { useEffect, useState } from 'react';
import { Grid, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { usePreferredState } from '../../customHooks';
import { useDispatch } from 'react-redux';
import { strapiClient } from '../../..';
import { CREATE_COMMENT } from '../../../config/mutations';
import { addComment, setComments } from '../../../state/user';
import CommentCard from './CommentCard';
import { HorizontalLoader } from '../HorizontalLoader';

const MESSAGE_ICONS = [
  { id: 1, label: 'mic' },
  { id: 2, label: 'attach' },
  { id: 3, label: 'send' },
];

const CommentContainer = ({ onClose, postId, commentsLoading }) => {
  const { currentUser, comments } = usePreferredState('user');
  const dispatch = useDispatch();
  const [message, setMessage] = useState('');
  const profileImg = currentUser.displayPic.includes('https') ? currentUser.displayPic : `data:image/svg+xml;base64,${currentUser.displayPic}`;

  const handleSendMsg = () => {
    if (message.trim().length) {
      const variables = {
        data: {
          comment: message,
          post: postId,
          user: currentUser.id,
        },
      };
      strapiClient
        .request(CREATE_COMMENT, variables)
        .then((res) => {
          dispatch(addComment(res.createComment.data));
          setMessage('');
        })
        .catch((e) => console.log('Sending comments error --->', e));
    }
  };

  return (
    <Grid className="comments-container">
      <Grid className="header">
        <Typography variant="h4">COMMENTS</Typography>
        <IconButton
          className="icon-btn"
          onClick={() => {
            dispatch(setComments());
            onClose();
          }}
        >
          <CloseIcon />
        </IconButton>
      </Grid>
      <Grid className="body">
        <Grid
          sx={{
            justifyContent: `${comments.length === 0 ? 'center' : 'start !important'}`,
            alignItems: `${comments.length === 0 ? 'center' : 'start !important'}`,
          }}
          className="comments"
        >
          {comments.map((comment) => (
            <CommentCard key={comment.id} comment={comment} />
          ))}
          {commentsLoading && <HorizontalLoader />}
          {comments.length === 0 && !commentsLoading && <Typography variant="h4">No Comments Yet...</Typography>}
        </Grid>
        <Grid className="message-input-container">
          <div className="input-with-icon">
            <input
              type="text"
              autoComplete="off"
              placeholder="Add a comment..."
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              onKeyPress={(e) => e.key === 'Enter' && handleSendMsg()}
            />
            <img id="profile" src={profileImg} alt="current-user-avatar" style={{ height: '1.875rem', width: '1.875rem', borderRadius: '50%' }} />
            <div className="message-operations">
              {MESSAGE_ICONS.map((icon, i) => (
                <img
                  key={icon.id}
                  src={require(`../../../assets/images/${icon.label}.png`)}
                  alt={icon.label}
                  onClick={() => (i === 2 ? handleSendMsg() : {})}
                />
              ))}
            </div>
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CommentContainer;
