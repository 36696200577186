import React, { useEffect, useState } from 'react';
import HistoryTable from './Table';
import RechartComponet from './RechartComponent';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import toggle from '../../../assets/images/toggle.png';
import logo from '../../../assets/images/logo-trans.png';
import { CreatoBalanceBtnData } from '../data';

const SideSection = () => {
  const [active, setActive] = useState(true);
  const handleToggle = () => setActive(!active);
  const [activeCard, setActiveCard] = useState('blue');

  const bgClr = (item) => {
    return { backgroundColor: item };
  };

  const creatoStalkBgClrOne = [
    { name: 'blue', color: bgClr('rgba(82, 202, 237, 0.43)') },
    { name: 'orange', color: bgClr('rgba(248, 197, 108, 0.62)') },
    { name: 'pink', color: bgClr('rgba(254, 152, 191, 0.54)') },
  ];
  const creatoStalkBgClrTwo = [
    { name: 'orange', color: bgClr('rgba(248, 197, 108, 0.62)') },
    { name: 'pink', color: bgClr('rgba(254, 152, 191, 0.54)') },
    { name: 'blue', color: bgClr('rgba(82, 202, 237, 0.43)') },
  ];
  const creatoStalkBgClrThree = [
    { name: 'pink', color: bgClr('rgba(254, 152, 191, 0.54)') },
    { name: 'blue', color: bgClr('rgba(82, 202, 237, 0.43)') },
    { name: 'orange', color: bgClr('rgba(248, 197, 108, 0.62)') },
  ];
  
  const [creatoStalkBgClr, setCreatoStalkBgClr] = useState(creatoStalkBgClrOne);

  useEffect(() => {
    if (activeCard === 'orange') {
      setCreatoStalkBgClr(creatoStalkBgClrTwo);
    } else if (activeCard === 'pink') {
      setCreatoStalkBgClr(creatoStalkBgClrThree);
    } else {
      setCreatoStalkBgClr(creatoStalkBgClrOne);
    }
  }, [activeCard]);

  return (
    <div className={active ? 'side-section-container scroll-bar-1' : 'side-section-container side-section-close'}>
      <div className="toggle-container">
        <img onClick={handleToggle} src={toggle} alt="icon" />
      </div>
      <div className={active ? 'side-section-content active' : 'side-section-content'}>
        <div className="title-container">
          <p>Your Investments</p>
          <AddCircleOutlineIcon />
        </div>
        <div className="creato-stalk">
          <div className="creato-stalk-body">
            {[1, 2, 3].map((item, index) => (
              <div
                key={index}
                style={creatoStalkBgClr[index].color}
                className={`creato-stalk-card card-${item}`}
                onClick={() => setActiveCard(creatoStalkBgClr[index].name)}
              >
                <h1>CREATO</h1>
                <div className="dollar">
                  <h1>$</h1>
                </div>
              </div>
            ))}
          </div>
          <div className="creato-stalk-foot">
            <div className="creato-foot-content">
              <p>Stalked</p>
              <p>$ 500</p>
            </div>
            <div className="creato-foot-pointer">
              <div className="pointer"></div>
            </div>
          </div>
        </div>
        <div className="creato-value">
          <div className="creato-value-head">
            <p>CREATO VALUE</p>
            <span>
              <ArrowUpwardIcon />
              <p>15.35 %</p>
            </span>
          </div>
          <div className="creato-value-chart">
            <RechartComponet />
            <div className="creato-value-buy-sell">
              <p className="creato-value-buy">
                <div className="buy-indicator"></div>BUY
              </p>
              <p className="creato-value-sell">
                <div className="sell-indicator"></div>SELL
              </p>
            </div>
          </div>
        </div>
        <div className="creato-balance">
          <div className="creato-balance-left">
            <div className="logo-icon">
              <img src={logo} alt="icon" />
            </div>
          </div>
          <div className="creato-balance-right">
            <div className="balance-details-container">
              <div className="details-head">
                <p>Available Balance</p>
              </div>
              <div className="details-body">
                <p>0 INR</p>
                <p>0 CREATO</p>
              </div>
              <div className="details-foot">
                <p>
                  Locked <LockOutlinedIcon />{' '}
                </p>
                <p>0 CREATO</p>
              </div>
            </div>
            <div className="balance-btn-container">
              {CreatoBalanceBtnData.map((item) => (
                <button style={{ backgroundColor: item.bgColor }}>
                  <span className="icon">s {item.icon}</span> {item.name}
                </button>
              ))}
            </div>
          </div>
        </div>
        <div className="creato-history">
          <div className="create-history-title">
            <p>Recent Purchase History</p>
          </div>
          <div className="create-history-content">
            <HistoryTable />
            <div className="view-all">
              <p>View all</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SideSection;
