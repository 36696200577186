import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { uploadFile } from 'react-s3';
import { strapiClient } from '../..';
import axios from 'axios';
import { TextField } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CheckBox from '../../components/chat/right-side-menu/CheckBox';
import { tokensConfigs } from '../../config';
import { CREATE_TOKEN } from '../../config/mutations';
import { usePreferredState } from '../../utils/customHooks';
import { HorizontalLoader } from '../../utils/widgets/HorizontalLoader';
import { setTokens } from '../../state/user';
import { useWallet } from '../../state/wallet';
import { handleWalletConnect } from '../../state/wallet/helper';
import { useMediaQuery } from '@mui/material';

const TokenCreationMainSection = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const bgClr = (item) => {
    return { backgroundColor: item };
  };
  const creatoStalkBgClr = [bgClr('rgba(82, 202, 237, 0.75)'), bgClr('rgba(248, 197, 108, 0.62)'), bgClr('rgba(254, 152, 191, 0.54)')];

  const [isChecked, setIsChecked] = useState(false);
  const [tokenName, setTokenName] = useState('');
  const [tokenSymbol, setTokenSymbol] = useState('');
  const [tokenSupply, setTokenSupply] = useState('');
  const [tokenSupplyMoney, setTokenSupplyMoney] = useState('');
  const [tokenAbout, setTokenAbout] = useState('');
  const [tokenDescription, setTokenDescription] = useState('');
  const [imgUrl, setImgUrl] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCard, setSelectedCard] = useState({
    id: '',
    background: {},
  });
  const { currentUser, tokens } = usePreferredState('user');
  const { isConnected, connectedAccount } = useWallet();
  const isLg = useMediaQuery('(min-width: 960px)');

  const [activeCard, setActiveCard] = useState('blue');

  const creatoStalkBgClrOne = [
    { name: 'blue', color: bgClr('rgba(82, 202, 237, 0.75)') },
    { name: 'orange', color: bgClr('rgba(248, 197, 108, 0.62)') },
    { name: 'pink', color: bgClr('rgba(254, 152, 191, 0.54)') },
  ];
  const creatoStalkBgClrTwo = [
    { name: 'orange', color: bgClr('rgba(248, 197, 108, 0.62)') },
    { name: 'pink', color: bgClr('rgba(254, 152, 191, 0.54)') },
    { name: 'blue', color: bgClr('rgba(82, 202, 237, 0.75)') },
  ];
  const creatoStalkBgClrThree = [
    { name: 'pink', color: bgClr('rgba(254, 152, 191, 0.54)') },
    { name: 'blue', color: bgClr('rgba(82, 202, 237, 0.75)') },
    { name: 'orange', color: bgClr('rgba(248, 197, 108, 0.62)') },
  ];

  const [creatoStalkBgClr2, setCreatoStalkBgClr2] = useState(creatoStalkBgClrOne);

  useEffect(() => {
    if (activeCard === 'orange') {
      setCreatoStalkBgClr2(creatoStalkBgClrTwo);
    } else if (activeCard === 'pink') {
      setCreatoStalkBgClr2(creatoStalkBgClrThree);
    } else {
      setCreatoStalkBgClr2(creatoStalkBgClrOne);
    }
  }, [activeCard]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (isConnected) {
      axios
        .post('https://market-predicitions.herokuapp.com/api/tokenFactory/Create/ERC20TOKEN', {
          tokenName: tokenName,
          tokenSymbol: tokenSymbol,
          decimal: 18,
          owner: connectedAccount,
          totalSupply: tokenSupply,
        })
        .then((res) => {
          const variables = {
            data: {
              name: tokenName,
              symbol: tokenSymbol,
              supply: tokenSupply,
              about: tokenAbout,
              description: tokenDescription,
              tokenPic: imgUrl,
              erc20Address: res.data,
              ownerAccount: connectedAccount,
              creator: currentUser.id,
            },
          };
          strapiClient
            .request(CREATE_TOKEN, variables)
            .then((data) => {
              if (data.createToken.data.id) {
                dispatch(setTokens([...tokens.payload, data.createToken.data]));
                navigate('/tokens');
                setIsLoading(false);
              }
            })
            .catch((e) => {
              console.log('Creating new token error--->', e);
            });
        });
    } else {
      await dispatch(handleWalletConnect());
      setIsLoading(false);
    }
  };

  const handleImageUpload = (e) => {
    // let timer = setInterval(() => {
    //   setProgress((prevProgress) => (prevProgress >= 100 ? 100 : prevProgress + 10));
    // }, 1000);
    const uploadedFile = e.target.files[0];
    uploadFile(uploadedFile, tokensConfigs)
      .then((res) => {
        setImgUrl(res.location);
        // setFileSize(getFileSizeInMB(uploadedFile.size));
        // setFile(uploadedFile);
      })
      .catch((e) => {
        // clearInterval(timer);
        // setProgress(0);
      });
  };

  return (
    <div className="token-creation-main-section-container">
      <h1 className="token-creation-heading">
        <IconButton className="back-icon" onClick={() => navigate(-1)}>
          <ArrowBackIcon />
        </IconButton>
        Token Creation
      </h1>
      <div className="token-creation-form">
        <div className="token-creation-card-heading">
          <div>Pick your Card</div>
          {isLg && selectedCard.id !== '' && <div>Preview</div>}
        </div>
        <div className="token-creation-card-container" style={isLg && selectedCard.id !== '' ? { justifyContent: 'space-between' } : {}}>
          {isLg && (
            <div className="token-creation-card-body">
              {[1, 2, 3].map((item, index) => (
                <div
                  style={creatoStalkBgClr[index]}
                  className={`creato-stalk-card card-${item}`}
                  onClick={() => {
                    setSelectedCard({ id: item, background: creatoStalkBgClr[index] });
                  }}
                >
                  <h3>CREATO</h3>
                  <div className="square"></div>
                </div>
              ))}
            </div>
          )}
          {isLg && selectedCard.id !== '' && (
            <>
              <MoreHorizIcon className="three-dots" />
              <div className="blue-card" style={{ background: selectedCard.background.backgroundColor }}>
                <h3>CREATO</h3>
                <div className="square"></div>
              </div>
            </>
          )}
          {!isLg && (
            <div className="token-creation-card-body">
              {[1, 2, 3].map((item, index) => (
                <div
                  key={index}
                  style={creatoStalkBgClr2[index].color}
                  className={`creato-stalk-card card-${item}`}
                  onClick={() => {
                    setActiveCard(creatoStalkBgClr2[index].name);
                  }}
                >
                  <h3>CREATO</h3>
                  <div className="square"></div>
                </div>
              ))}
            </div>
          )}
        </div>
        <form noValidate autoComplete="off" onSubmit={handleSubmit}>
          <div className="input-field">
            <label>Name</label>
            <TextField
              className="text-field"
              variant="outlined"
              fullWidth
              required
              helperText="This can be your Discord Server,Project or your own Name."
              onChange={(e) => setTokenName(e.target.value)}
            />
          </div>
          <div className="input-field">
            <label>Symbol</label>
            <TextField
              className="text-field"
              variant="outlined"
              fullWidth
              required
              helperText="Your Token Symbol ( 1-7 Characters ),No “$” Sign required"
              onChange={(e) => setTokenSymbol(e.target.value)}
            />
          </div>{' '}
          <div className="input-field">
            <label>Supply</label>

            <TextField
              className="text-field-supply"
              variant="outlined"
              helperText="Recommended Supply - 10"
              defaultValue="0"
              onChange={(e) => setTokenSupply(e.target.value)}
              InputProps={{
                endAdornment: (
                  // <Select
                  //   variant="standard"
                  //   IconComponent={() => <ArrowDropDownCircleIcon style={{ color: '#7C7EF7' }} />}
                  //   className="select-supply"
                  //   disableUnderline
                  //   onChange={(e) => setTokenSupplyMoney(e.target.value)}
                  // >
                  //   <MenuItem value={10}>Million</MenuItem>
                  //   <MenuItem value={20}>Billion</MenuItem>
                  //   <MenuItem value={30}>Trillion</MenuItem>
                  // </Select>

                  <select style={{ border: 'none', outline: 'none', background: 'none', padding: '0 1rem' }}>
                    <option>Million </option>
                    <option>Billion</option>
                    <option>Trillion </option>
                  </select>
                ),
              }}
              required
            />
          </div>{' '}
          <div className="input-field">
            <label>About</label>
            <TextField
              className="text-field"
              variant="outlined"
              fullWidth
              required
              // defaultValue="psy65dudmd8d67fhd7shf7sjf6sf6shfsufjs78jsad6aydhayhda"
              helperText="Write something about your token"
              onChange={(e) => setTokenAbout(e.target.value)}
            />
          </div>{' '}
          <div className="input-field">
            <label>Description</label>
            <TextField
              className="text-field"
              variant="outlined"
              fullWidth
              required
              helperText="Give us a Breif Description on how you would use the Token."
              onChange={(e) => setTokenDescription(e.target.value)}
            />
          </div>{' '}
          <div className="input-field">
            <label>File</label>
            <TextField
              className="text-field"
              variant="outlined"
              fullWidth
              required
              type="file"
              helperText="Upload token cover image"
              onChange={(e) => handleImageUpload(e)}
            />
          </div>
          <div className="token-agreement" onClick={() => setIsChecked(!isChecked)}>
            <p>I understand that these values cant be changed after deployment and I hereby accept the Token Agreement</p>
            <CheckBox
              isChecked={isChecked}
              style={{
                borderRadius: '50px',
                minHeight: '26px',
                minWidth: '26px',
                display: isChecked ? 'block' : 'none',
                backgroundColor: isChecked ? '#BCBDFF' : '',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                border: '1px solid rgb(188, 189, 255)',
              }}
              style2={{ height: '9px', display: isChecked ? 'block' : 'none' }}
            />
          </div>
          {isConnected && (
            <div className="input-field">
              <label>Connected Account : {connectedAccount}</label>
            </div>
          )}
          <button className="token-creation-button" type="submit">
            {isLoading ? <HorizontalLoader /> : isConnected ? 'Create' : 'Connect Wallet'}
          </button>
        </form>
      </div>
    </div>
  );
};
export default TokenCreationMainSection;
