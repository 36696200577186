import { ResponsiveContainer, LineChart, Line, CartesianGrid, XAxis, YAxis } from 'recharts';
import { ChartData } from '../data';

const renderLineChart = (
  <ResponsiveContainer width="95%" height="80%">
    <LineChart
      width={800}
      height={200}
      data={ChartData}
      margin={{
        top: 20,
      }}
    >
      <Line type="monotone" dataKey="uv" stroke="#8884d8" />
      <CartesianGrid stroke="#ccc" viewBox={1000} />
      <XAxis dataKey="name" />
      <YAxis />
    </LineChart>
  </ResponsiveContainer>
);

const RechartComponet = () => {
  return <div className="rechart-component">{renderLineChart}</div>;
};

export default RechartComponet;
