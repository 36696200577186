import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import ForgotPassword from './ForgotPassword';
import SignInPage from './SignInPage';
import NEXTSTEP from '../../../assets/images/next step.svg';
import BACKARROW from '../../../assets/images/back-arrow.svg';
import '../../../assets/scss/landing-page/registration-page.scss';

const RegistrationPage = (props) => {
  const { setRegisterInfo, nextStep, previousStep, isLogin, setIsLogin } = props;
  const phoneRegExp = /^[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4}$/im;

  const [forgotPage, setForgotPage] = useState(false);
  const [signIn, setSignIn] = useState(true);

  const formSchema = Yup.object().shape({
    username: Yup.string().required('UserName is required'),
    name: Yup.string().required('Name is required'),
    email: Yup.string().email().required('Email is required'),
    phonenumber: Yup.string().matches(phoneRegExp, 'Phone number is not valid'),
    password: Yup.string().min(6).required('Password is required'),
    confirmpassword: Yup.string()
      .required('Confirm Password is required')
      .oneOf([Yup.ref('password')], 'Passwords should match'),
  });

  const validationOpt = { resolver: yupResolver(formSchema), mode: 'onTouched' };

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isDirty },
  } = useForm(validationOpt);

  useEffect(() => {
    isLogin === true ? setSignIn(false) : setSignIn(true);
  }, [isLogin]);

  const handleRegister = (data) => {
    setRegisterInfo({ ...data });
    nextStep();
  };

  return (
    <>
      {!forgotPage ? (
        <div className="registration-login-page">
          <h2>{signIn ? 'Registration' : 'Login'} </h2>
          {signIn ? (
            <form className="registration-form" onSubmit={handleSubmit(handleRegister)}>
              <div className="registration-header">
                <div className="input-field">
                  <input type="text" placeholder="User Name" className="username" {...register('username')} />
                  <p className="error-text">{errors.username?.message}</p>
                </div>
                <div className="input-field">
                  <input type="text" placeholder="Name" className="name" {...register('name')} />
                  <p className="error-text">{errors.name?.message}</p>
                </div>

                <div className="input-field">
                  <input type="email" placeholder="Email Id" className="email" {...register('email')} />
                  <p className="error-text">{errors.email?.message}</p>
                </div>

                <div className="input-field">
                  <select {...register('countrycode')}>
                    <option value="+91">+91</option>
                    <option value="+86">+86</option>
                    <option value="+92">+92</option>
                    <option value="+1">+1</option>
                    <option value="+36">+36</option>
                  </select>
                  <input type="number" placeholder="Phone number (optional)" className="phone-number" {...register('phonenumber')} />
                  <p className="error-text">{errors.phonenumber?.message}</p>
                </div>

                <div className="input-field">
                  <input type="password" placeholder="Password" className="password" {...register('password')} />

                  <p className="error-text">{errors.password?.message}</p>
                </div>

                <div className="input-field">
                  <input type="password" placeholder="Confirm Password" className="confirm-password" {...register('confirmpassword')} />
                  <p className="error-text">{errors.confirmpassword?.message}</p>
                </div>

                <div className="bottom-text">
                  <p>
                    Already have an account?&nbsp;
                    <span
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        setSignIn(false);
                        setIsLogin(true);
                      }}
                    >
                      Sign In!
                    </span>
                  </p>
                  <p>
                    <span onClick={() => setForgotPage(true)} style={{ cursor: 'pointer' }}>
                      Forgot Password?
                    </span>
                  </p>
                </div>
              </div>
              <div className="button-field">
                <div className="back-arrow" onClick={previousStep}>
                  <img src={BACKARROW} alt="BACKARROW" />
                </div>

                <button type="submit" style={{ cursor: isDirty ? 'pointer' : 'not-allowed' }}>
                  Next Step 2 &nbsp;
                  <img src={NEXTSTEP} alt="NEXTSTEP" />
                </button>
              </div>
            </form>
          ) : (
            <SignInPage
              previousStep={() => {
                setSignIn(true);
                setIsLogin(false);
              }}
              setForgotPage={setForgotPage}
            />
          )}
        </div>
      ) : (
        <ForgotPassword
          registerPage={() => {
            setForgotPage(false);
            setSignIn(false);
            setIsLogin(true);
          }}
        />
      )}
    </>
  );
};

export default RegistrationPage;
