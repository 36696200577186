import React, { useState } from 'react';
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';

const CommunityRules = (props) => {
  const { index, handleRemoveLink, setRule } = props;

  const [deleteActive, setDeleteActive] = useState(false);

  const handleMoreIcon = () => {
    setDeleteActive(!deleteActive);
  };

  const handleRuleChange = (val, param) => {
    param === 'NAME'
      ? setRule({
          name: val,
          ruleIdentity: `#${index + 1} Rule`,
          description: '',
        })
      : setRule((prev) => {
          return {
            name: prev.name,
            ruleIdentity: `#${index + 1} Rule`,
            description: val,
          };
        });
  };

  return (
    <div className="community-rules">
      <div className="head-container">
        <div className="link-widget-left">
          <p>#{index + 1} Rule</p>
        </div>
        <div className="link-widget-right">
          <MoreHorizOutlinedIcon onClick={handleMoreIcon} />
          <p onClick={() => handleRemoveLink(index)} style={{ display: deleteActive ? 'block' : 'none' }}>
            remove link
          </p>
        </div>
      </div>
      <div className="input-container">
        <div className="int-name-container f-int">
          <label htmlFor="name">Rule Name</label>
          <input type="text" name="" placeholder="Add Rule Name" id="name" onChange={(e) => handleRuleChange(e.target.value, 'NAME')} />
        </div>
        <div className="int-desc-container f-int">
          <label htmlFor="desc">Description</label>
          <textarea
            id="desc"
            placeholder="Add Rule Description"
            rows="5"
            cols="50"
            onChange={(e) => handleRuleChange(e.target.value, 'DESC')}
          ></textarea>
        </div>
      </div>
    </div>
  );
};

export default CommunityRules;
