import React from "react";
import Live from "../../../assets/images/live.png";
import MORE from "../../../assets/images/More.png";
import { CONTRIBUTERS } from "../../../utils/constants";

const LiveCard = () => {
  return (
    <>
      <div className="live-info">
        <img
          src={MORE}
          alt="live"
          style={{ margin: "0 0.7rem", cursor: "pointer" }}
        />
        <div id="live">
          <img src={Live} alt="live" />
          <span id="live-text">LIVE</span>
        </div>
        <span id="time">12.34</span>
      </div>
      <div className="live-contributers">
        <div className="contributer">
          {CONTRIBUTERS.map((contributer, i) => {
            let style = {
              width: "30px",
              height: "31px",
            };
            if (i === 1)
              style = {
                ...style,
                position: "relative",
                left: "-13px",
                bottom: "-14px",
                zIndex: "999",
              };
            let Avatar =
              i <= 1 ? (
                <img
                  key={contributer.id}
                  src={require(`../../../assets/images/${contributer.label}.png`)}
                  alt="contributer"
                  style={style}
                />
              ) : (
                <span className="contributer-count">
                  +{CONTRIBUTERS.length - 2}
                </span>
              );
            return Avatar;
          })}
        </div>
        <span id="count">8</span>
      </div>
    </>
  );
};

export default LiveCard;
