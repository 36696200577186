import MainSection from './mainSection/Mainsection';
import SideSection from './sideSection/SideSection';
import '../../assets/scss/community-page/index.scss';

const CommunityPage = ({ data }) => {
  return (
    <div className="community-page-container">
      <MainSection data={data} />
      <SideSection />
    </div>
  );
};

export default CommunityPage;
