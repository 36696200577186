import React, { useEffect, useState } from 'react';
import TradingTable from './TradingTable';
import RechartComponet from './RechartComponent';
import '../../../assets/scss/wallet/sections/tokenDetails/tokenDetails.scss';
import { IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import WAVING_HAND_EMOJI from '../../../assets/images/WAVING_HAND_EMOJI.png';
import { useNavigate, useParams } from 'react-router-dom';
import { usePreferredState } from '../../../utils/customHooks';
import { HorizontalLoader } from '../../../utils/widgets/HorizontalLoader';
import { useMediaQuery } from '@mui/material';

const TokenDetails = () => {
  const { tokenId } = useParams();
  const [tokenData, setTokenData] = useState({});
  const navigate = useNavigate();
  const { tokens } = usePreferredState('user');
  const matches = useMediaQuery('(min-width: 600px)');

  useEffect(() => {
    const findToken = async () => {
      const data = await tokens.payload.filter((t) => t.id === tokenId)[0];
      setTokenData(data);
    };
    if (tokenId && tokens.payload.length) {
      findToken();
    }
  }, [tokenId, tokens.payload.length]);

  return (
    <div className="token-details-container scroll-bar-1">
      <div className="token-details-header">
        <IconButton
          className="icon-btn"
          onClick={() => {
            navigate(-1);
          }}
        >
          <ArrowBackIcon />
        </IconButton>
        <h1>
          Welcome to{' '}
          {tokenData?.attributes?.name ? `${tokenData.attributes.name.charAt(0).toUpperCase() + tokenData.attributes.name.slice(1)} token` : 'Token'}{' '}
          {matches && <img src={WAVING_HAND_EMOJI} alt="WAVING_HAND_EMOJI" />}
        </h1>
      </div>
      <div className={`token-details ${!tokenId || !tokenData.hasOwnProperty('id') ? 'no-data' : ''}`}>
        {tokenId && tokenData.id ? (
          <>
            <div className="token-header-container" style={{ position: 'relative' }}>
              <div className="token-icon">
                <p>{tokenData.attributes.name[0].toUpperCase()}</p>
              </div>
              <div className="banner-wrapper" style={{ backgroundImage: `url(${tokenData.attributes.tokenPic})` }}></div>
              <div className="banner-wrapper-img"></div>
            </div>
            <div className="token-content-container">
              <div className="about-section">
                <h1>About {tokenData.attributes.name.charAt(0).toUpperCase() + tokenData.attributes.name.slice(1)}</h1>
                <p>{tokenData.attributes.about}</p>
              </div>
              <div className="full-token-information">
                <h1>Full Token Information</h1>
                <div className="token-summary">
                  <h1>Token Summary</h1>
                  <p>{tokenData.attributes.description}</p>
                </div>
                <div className="token-supply">
                  <h1>Total Supply</h1>
                  <p>{tokenData.attributes.supply}</p>
                </div>
              </div>
              <div className="trading">
                <h1>Trading</h1>
                <TradingTable />
                {matches && (
                  <div className="trading-chart">
                    <p>Current Market Place</p>
                    <p>$60,542,54</p>
                    <RechartComponet />
                  </div>
                )}
              </div>
            </div>
          </>
        ) : (
          <HorizontalLoader />
        )}
      </div>
    </div>
  );
};

export default TokenDetails;
