import React, { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import Draggable from 'react-draggable';
import ErrorBoundary from './chatComponent/ErrorBoundary';
import ChatComponent from './chatComponent/ChatComponent';
import ChatPage from '../../components/chat';
import { usePreferredState } from '../../utils/customHooks';
import { getAllAccounts } from '../../state/user/actions';
import useMediaQuery from '@mui/material/useMediaQuery';
import '../../assets/scss/draggableChat/draggableChat.scss';


const DraggableChat = () => {
  const dispatch = useDispatch();
  const { currentChat, currentUser } = usePreferredState('user');
  const matches = useMediaQuery('(max-width:1500px)');

  /*----Related to Draggable-----*/
  const [drag, setDrag] = useState(true);
  const [openChat, setOpenChat] = useState(false);
  const [isCancelled,setIsCancelled] = useState(true)
  const dragRef = useRef(null);
  const handleDrag = () => {
    setDrag(!drag);
  };
  /*----Related to Draggable-----*/

  useEffect(() => {
    if (currentUser) {
      dispatch(getAllAccounts(currentUser.id));
    }
  }, [currentUser]);

  useEffect(()=>{
    setIsCancelled(!isCancelled);
  },[openChat])

  const isDraggable = matches && isCancelled;

  return (
    <>
      <Draggable
        onDrag={handleDrag}
        onStart={() => setIsCancelled(!isCancelled)}
        onStop={() => setIsCancelled(true)}
        cancel={isDraggable ? '.chat-container' : ''}
      >
        <div ref={dragRef} className="draggable-chat-container">
          <ErrorBoundary>
            <ChatComponent drag={{ drag, handleDrag }} openChat={openChat} setOpenChat={setOpenChat} />
          </ErrorBoundary>
        </div>
      </Draggable>

      {currentChat && (
        <div className="draggable-chat-section">
          <ChatPage />
        </div>
      )}
    </>
  );
};

export default DraggableChat;
