import React from 'react';
import ChatAreaBody from './ChatAreaBody';
import ChatAreaHeader from './ChatAreaHeader';
import '../../../assets/scss/chat/chat-area.scss';

const ChatArea = () => {
  return (
    <div className="chat-area">
      <ChatAreaHeader />
      <ChatAreaBody />
    </div>
  );
};

export default ChatArea;
