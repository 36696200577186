import React from 'react';
import { ReactionBarSelector } from '@charkour/react-reactions';
import { Popper } from '@mui/material';

const Reactions = ({ anchorEl, handleEmoji }) => {
  return (
    <Popper open={Boolean(anchorEl)} anchorEl={anchorEl} placement="top">
      <ReactionBarSelector onSelect={handleEmoji} iconSize={25} style={{ display: 'flex', flexDirection: 'column' }} />
    </Popper>
  );
};

export default Reactions;
