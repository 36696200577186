import React, { useState } from 'react';
import Cookie from 'js-cookie';
import Fab from '@mui/material/Fab';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import BlurBg from '../landing-page/BlurBg';
import logo from '../../assets/images/logo-trans.png';
import '../../assets/scss/welcome-page/index.scss';
var CryptoJS = require('crypto-js');

const WelcomePage = () => {
  const [password, setPassword] = useState('');
  const [error, setError] = useState(false);

  const handleChange = (e) => {
    setPassword(e.target.value);
    error && setError(false);
  };

  const encryptCrypto = (data) => {
    let salt = process.env.REACT_APP_SERCET_KEY ?? '';
    var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data), salt).toString();
    return ciphertext;
  };


  const handleSubmit = ()=>{
    let user = process.env.REACT_APP_USER_PASSWORD === password;
    if (user) {
      let result = encryptCrypto('true');
      Cookie.set('user', result);
      window.location.reload();
    } else {
      setError(true);
    }
  }

  const handleOnEnter = (e) => {
    if (e.keyCode === 13) {
      handleSubmit()
    }
  };

  const handleClick = ()=>{
     handleSubmit();
  }

  return (
    <div className="welcome-page-container">
      <div className="logo-container">
        <img src={logo} alt="icon" />
        <h1 className="createverse-title">Creatverse</h1>
      </div>
      <div className="welcome-password-input-container">
        <input type="password" onChange={handleChange} onKeyDown={handleOnEnter} placeholder="Enter password here..." className="password" />
      </div>
      <BlurBg />
      {error && <p className="error-text">Please enter the correct password</p>}
      <div className="floating-btn">
        <Fab
          onClick={handleClick}
          disableElevation
          disableRipple
          sx={{
            backgroundColor: '#7c7ef7',
            '&:hover': {
              backgroundColor: '#7c7ef7',
            },
          }}
          className="floating-arrow-bg"
          size="large"
          aria-label="add"
        >
          <ArrowForwardIcon sx={{ color: 'white' }} className="floating-arrow" />
        </Fab>
      </div>
    </div>
  );
};

export default WelcomePage;
