import React from 'react';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { strapiClient } from '../../..';
import { Snack } from '../../../utils/widgets/Toast';
import { checkAccount } from '../../../state/user/actions';
import '../../../assets/scss/landing-page/forgot-password.scss';

const ResetPassword = () => {
  let [searchParams, setSearchParams] = useSearchParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const formSchema = Yup.object().shape({
    password: Yup.string().min(6).required('Password is required'),
    confirmPassword: Yup.string()
      .required('Confirm Password is required')
      .oneOf([Yup.ref('password')], 'Passwords must and should match'),
  });

  const validationOpt = { resolver: yupResolver(formSchema), mode: 'onTouched' };
  // console.log(searchParams.get('code'));

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm(validationOpt);

  const handleResetPassword = async (data) => {
    const code = await searchParams.get('code');
    console.log('Reset Data : ', data);
    console.log('Code Data : ', code);
    await axios
      .post('https://creato-api.herokuapp.com/api/auth/reset-password', {
        code: code,
        password: data.password,
        passwordConfirmation: data.confirmPassword,
      })
      .then((response) => {
        if (response.data.jwt) {
          localStorage.setItem('token', response.data.jwt);
          strapiClient.setHeader('authorization', `Bearer ${response.data.jwt}`);
          dispatch(checkAccount(response.data.user.id, response.data.jwt)).then((success) => {
            if (success) {
              Snack('Reset password successfully!', 2000, 'success');
              navigate('/');
            }
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <form className="password-recovery-container" onSubmit={handleSubmit(handleResetPassword)}>
      <h1>Reset Password</h1>
      <div className="password-recovery-container">
        <label>Password</label>
        <div className="password-recovery-input-container">
          <input type="password" placeholder="Password" className="password" {...register('password')} />
          <p className="error-text2">{errors.password?.message}</p>
        </div>
      </div>
      <div className="password-recovery-container">
        <label>Confirm Password</label>
        <div className="password-recovery-input-container">
          <input type="password" placeholder="Confirm Password" {...register('confirmPassword')} />
          <p className="error-text2">{errors.confirmPassword?.message}</p>
        </div>
      </div>
      <div className="password-recovery-button-container">
        <button type="submit" className="forgot-password-submit" style={{ cursor: isDirty ? 'pointer' : 'not-allowed' }}>
          Reset Password
        </button>
      </div>
    </form>
  );
};

export default ResetPassword;
