import arrowRight from '../../assets/images/arrow-right.svg';

const DashBoardCard = ({ item }) => {
  return (
    <div className="d-body-card">
      <div className="user-card">
        <div className="user-card-head">
          <div className="user-icon-container">
            <img src={item.userImg} alt="image" />
          </div>
          <div className="user-details">
            <h1>{item.userName}</h1>
            <p>Organizer</p>
          </div>
        </div>
        <div className="user-card-body">
          <h1>Special Guest : {item.guest}. The Artist Manager Role</h1>
          <p>15 members</p>
        </div>
        <div className="user-card-foot">
          <div className="join-button">
            <p className="join">Join Meeting</p>
            <img className="" src={arrowRight} alt="icon" />
          </div>
          <div className="users-list">
            <div className="user-profiles">
              <div className="user-available">
                <img src={item.available[0]} alt="image" />
              </div>
              <div className="user-available">
                <img src={item.available[1]} alt="image" />
              </div>
              <div className="user-available">
                <img src={item.available[2]} alt="image" />
              </div>
              <div className="user-available">
                <img src={item.available[3]} alt="image" />
              </div>
            </div>
            <p>+16</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashBoardCard;
