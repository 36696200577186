import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Grid } from '@mui/material';
import '../../../assets/scss/postDescription.scss';
import DetailsContainer from './DetailsContainer';
import CommentContainer from './CommentContainer';
import { getPostComments } from '../../../state/user/actions';
import { usePreferredState } from '../../customHooks';
import { HorizontalLoader } from '../HorizontalLoader';

const PostDescription = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const [commentsLoading, setCommentsLoading] = useState(false);
  const [post, setPost] = useState({});
  const { postData } = usePreferredState('user');

  useEffect(() => {
    if (postData.length) {
      setPost(postData.find((post) => post.id === id));
      setCommentsLoading(true);
      dispatch(getPostComments(id, setCommentsLoading));
    }
  }, [postData.length]);

  return (
    <>
      {!post?.id ? (
        <div className="horizontal-loader-container">
          <HorizontalLoader />
        </div>
      ) : (
        <Grid className="post-description-container">
          <DetailsContainer post={post} onClose={() => navigate('/')} />
          <CommentContainer onClose={() => navigate('/')} postId={id} commentsLoading={commentsLoading} />
        </Grid>
      )}
    </>
  );
};

export default PostDescription;
