import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';

const initialState = {
  isConnected: false,
  isConnecting: false,
  connectedAccount: null,
  accountBalance: '0',
  chainId: 4,
};

const sliceName = 'wallet';

export const walletSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setWalletState: (state, action) => {
      state.isConnected = action.payload?.isConnected ?? state.isConnected;
      state.connectedAccount = action.payload?.connectedAccount ?? state.connectedAccount;
      state.accountBalance = action.payload?.accountBalance ?? state.accountBalance;
      state.chainId = action.payload?.chainId ?? state.chainId;
      state.isConnecting = action.payload?.isConnecting ?? state.isConnecting;
    },
    toggleIsConnecting: (state, action) => {
      state.isConnecting = action.payload;
    },
    setAccount: (state, action) => {
      state.connectedAccount = action.payload;
    },
  },
});

export const { setWalletState, toggleIsConnecting, setAccount } = walletSlice.actions;

export default walletSlice.reducer;

export const useWallet = () => useSelector((state) => state.wallet);
