import React from 'react';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Button } from '@mui/material';

const Footer = ({ modalPage, setModalPage, handleUpdation }) => {
  return (
    <div className="footer">
      {modalPage === 1 ? (
        <span></span>
      ) : (
        <Button
          onClick={() => setModalPage(modalPage - 1)}
          variant="contained"
          style={{ borderRadius: '22px', padding: '10px', backgroundColor: '#7C7EF7' }}
        >
          <ArrowBackIcon />
        </Button>
      )}

      <Button
        // disabled={true}
        onClick={() => (modalPage === 3 ? handleUpdation() : setModalPage(modalPage + 1))}
        variant="contained"
        style={{ borderRadius: '22px', padding: '12px 60px', backgroundColor: '#7C7EF7' }}
      >
        {`${modalPage === 3 ? 'Update' : 'Next'}`} <NavigateNextIcon style={{ position: 'absolute', right: '10px' }} />
      </Button>
    </div>
  );
};

export default Footer;
