export const Menu = ['Posts', 'Threads', 'Leaders', 'Tasks', 'Rewards', 'Rules', 'About', 'Settings'];

export const LiquidPoolData = [
  {
    id: Math.random(),
    num: 987,
    name: 'Movie Making',
  },
  {
    id: Math.random(),
    num: 600,
    name: 'Editing/VFX',
  },
  {
    id: Math.random(),
    num: 854,
    name: 'Photography',
  },
  {
    id: Math.random(),
    num: 916,
    name: 'Music',
  },
  {
    id: Math.random(),
    num: 887,
    name: 'Politics',
  },
];
