import React, { useEffect, useRef, useState } from 'react';
import { uploadFile } from 'react-s3';
import { useDispatch } from 'react-redux';
import { collectiblesConfigs } from '../../../config';
import { IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EditIcon from '@mui/icons-material/Edit';
import CheckBox from '../../../components/chat/right-side-menu/CheckBox';
import Button from '../../../utils/widgets/Button';
import ImagePreview from '../../../utils/imageCrop/ImagePreview';
import { usePreferredState } from '../../../utils/customHooks';
import { setCoverPic, setIsEditable, setSelectedProfile } from '../../../state/user';
import { updateAccount } from '../../../state/user/actions';
import homeBanner from '../../../assets/images/home-widget-banner.png';
import WAVING_HAND_EMOJI from '../../../assets/images/WAVING_HAND_EMOJI.png';
import FIRE_EMOJI from '../../../assets/images/FIRE_EMOJI.png';
import EditImg from '../../../assets/images/EditProfile.svg';

const Header = ({ profileInfo }) => {
  const dispatch = useDispatch();
  const hiddenFileInput = useRef(null);

  const [isChecked, setIsChecked] = useState(true);
  const [file, setFile] = useState(null);
  const [image, setImage] = useState(null);
  const { currentUser, isGuestUser, selectedProfile } = usePreferredState('user');

  useEffect(() => {
    if (file) {
      const reader = new FileReader();
      reader.addEventListener(
        'load',
        () => {
          setImage(reader.result);
        },
        false,
      );
      reader.readAsDataURL(file);
    }
  }, [file]);
  /* Crop Image Part */

  const handleFinalImage = (image) => {
    const uploadedFile = image;
    uploadFile(uploadedFile, collectiblesConfigs)
      .then((res) => {
        const variables = {
          updateAccountId: currentUser.id,
          data: {
            coverPic: res.location,
          },
        };

        dispatch(
          updateAccount(variables, 'Cover pic', (coverPic) => {
            if (coverPic) dispatch(setCoverPic(coverPic));
            else console.log('Update display pic error');
          }),
        );
      })
      .catch((e) => {
        console.log('Update display pic error--->', e);
      });
    setImage(null);
  };
  /* Crop Image Part Ends Here */

  const handleClick = async (e) => {
    hiddenFileInput.current.click();
  };

  return (
    <div className="hw-header">
      <div className="welcome-container">
        <div className="welcome-note">
          <IconButton className="icon-btn" onClick={() => dispatch(setSelectedProfile())}>
            <ArrowBackIcon />
          </IconButton>
          <h1>
            Welcome {profileInfo.name} <img src={WAVING_HAND_EMOJI} alt="WAVING_HAND_EMOJI" />{' '}
          </h1>
        </div>
        <div className="welcome-container-content">
          <p>
            Complete your daily tasks <br />
            to build your streaks & get rewarded
          </p>
          <div className="week-days">
            <span className="fire-container">
              <img src={FIRE_EMOJI} alt="FIRE_EMOJI" />
              <p>M</p>
            </span>
            <span className="fire-container">
              <img src={FIRE_EMOJI} alt="FIRE_EMOJI" />
              <p>T</p>
            </span>

            {['W', 'T', 'F', 'S', 'S'].map((item, i) => (
              <div className="days-border" key={i}>
                {item}
              </div>
            ))}
            <p className="twox">x2</p>
          </div>
        </div>
      </div>
      <div className="head-widgets">
        <div className="available-container" onClick={() => setIsChecked(!isChecked)}>
          <div className="online"></div>
          <p className="online-text">Available</p>
          <CheckBox
            isChecked={isChecked}
            style={{
              borderRadius: '50px',
              height: '14px',
              width: '14px',
              display: isChecked ? 'block' : 'none',
              backgroundColor: isChecked ? '#7C7EF7' : '',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            style2={{ height: '5px', display: isChecked ? 'block' : 'none' }}
          />
        </div>
        {!isGuestUser && currentUser.id === selectedProfile && (
          <div className="join-button-container">
            <Button
              content={
                <div style={{ display: 'flex', height: '1.6rem', alignItems: 'center', gap: '0.25rem' }}>
                  <img src={EditImg} alt="Edit Profile" style={{ height: '1.15rem' }} />
                  <p>Edit Profile</p>
                </div>
              }
              style={{ padding: '0.4rem 1rem', borderRadius: '0.5rem' }}
              onClick={() => dispatch(setIsEditable(true))}
              disabled={isGuestUser || currentUser.id !== selectedProfile}
            />
          </div>
        )}
      </div>
      <div className="banner-container">
        <img src={profileInfo.coverPic ? profileInfo.coverPic : homeBanner} alt="banner" />
        {!isGuestUser && currentUser.id === selectedProfile && (
          <>
            <IconButton id="img-edit-btn" className="img-edit-btn" onClick={handleClick}>
              <EditIcon style={{ color: '#fff' }} className="img-edit-icon" />
            </IconButton>
            <input type="file" name="file" style={{ display: 'none' }} ref={hiddenFileInput} onChange={(e) => setFile(e.target.files[0])} />
          </>
        )}
      </div>
      {image && <ImagePreview image={image} setImage={setImage} handleFinalImage={handleFinalImage} />}
    </div>
  );
};

export default Header;
