import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { GraphQLClient } from 'graphql-request';
import './assets/css/index.css';
import './polyfill';
export const strapiClient = new GraphQLClient(process.env.REACT_APP_STRAPI_URL + '/graphql');

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
