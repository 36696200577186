import ArrowUpwardOutlinedIcon from '@mui/icons-material/ArrowUpwardOutlined';
import ArrowDownwardOutlinedIcon from '@mui/icons-material/ArrowDownwardOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import ReplayOutlinedIcon from '@mui/icons-material/ReplayOutlined';

export const CategoryList = ['Movies', 'Music', 'Art', 'VFX', 'Gaming', 'Design', 'Video'];

export const CategoryCard = [
  {
    title: 'KAB',
    name: 'Kabali',
    creato: '30.95',
    inr: '500.55',
    per: '3.90',
    theme: '#50AF95',
  },
  {
    title: 'BIL',
    name: 'Billa',
    creato: '34.95',
    inr: '700.55',
    per: '5.60',
    theme: '#F8C56C',
  },
  {
    title: 'GUL',
    name: 'Gully Boy',
    creato: '60.95',
    inr: '800.55',
    per: '5.30',
    theme: '#52CAED',
  },
  {
    title: 'BHB',
    name: 'Baahubali',
    creato: '80.95',
    inr: '900.55',
    per: '6.10',
    theme: '#FE98BF',
  },
  {
    title: 'LOP',
    name: 'Life of Pi',
    creato: '1200.95',
    inr: '1200.55',
    per: '8.50',
    theme: '#1E1E1E',
  },
];

export const CreatoBalanceBtnData = [
  {
    name: 'Withdraw',
    icon: <ArrowUpwardOutlinedIcon />,
    bgColor: '#7C7EF7',
  },
  {
    name: 'Receive',
    icon: <ArrowDownwardOutlinedIcon />,
    bgColor: '#55D78B',
  },
  {
    name: 'Top-up',
    icon: <AddOutlinedIcon />,
    bgColor: '#FDBB48',
  },
  {
    name: 'Transactions',
    icon: <ReplayOutlinedIcon />,
    bgColor: '#38C9F3',
  },
];

export const ChartData = [
  { name: 'Jan', TokenValue: 400, CreatoValue: 100, pv: 2400, amt: 2400 },
  { name: 'Feb', TokenValue: 500, CreatoValue: 300, pv: 2400, amt: 2500 },
  { name: 'Mar', TokenValue: 350, CreatoValue: 250, pv: 2400, amt: 2600 },
  { name: 'Apr', TokenValue: 300, CreatoValue: 200, pv: 2400, amt: 2600 },
  { name: 'May', TokenValue: 700, CreatoValue: 400, pv: 2400, amt: 2600 },
  { name: 'Jun', TokenValue: 800, CreatoValue: 200, pv: 2400, amt: 2600 },
  { name: 'Jul', TokenValue: 550, CreatoValue: 450, pv: 2400, amt: 2600 },
  { name: 'Aug', TokenValue: 650, CreatoValue: 350, pv: 2400, amt: 2600 },
  { name: 'Sep', TokenValue: 400, CreatoValue: 120, pv: 2400, amt: 2600 },
  { name: 'Oct', TokenValue: 350, CreatoValue: 250, pv: 2400, amt: 2600 },
  { name: 'Nov', TokenValue: 400, CreatoValue: 310, pv: 2400, amt: 2600 },
  { name: 'Dec', TokenValue: 630, CreatoValue: 150, pv: 2400, amt: 2600 },
];

export const SideChartData = [
  { nameX: 'Jan', nameY: '0', creatoValue: 3, pv: 2400, amt: 2400 },
  { creatoValue: 3.5, pv: 2400, amt: 2400 },
  { nameX: 'Feb', nameY: '100', creatoValue: 7.5, pv: 2400, amt: 2500 },
  { creatoValue: 5.5, pv: 2400, amt: 2500 },
  { nameX: 'Mar', nameY: '1k', creatoValue: 5, pv: 2400, amt: 2600 },
  { creatoValue: 4, pv: 2400, amt: 2600 },
  { nameX: 'Apr', nameY: '2.5k', creatoValue: 5.1, pv: 2400, amt: 2600 },
  { creatoValue: 3, pv: 2400, amt: 2600 },
  { nameX: 'May', nameY: '3.5k', creatoValue: 1.2, pv: 2400, amt: 2600 },
  { creatoValue: 6, pv: 2400, amt: 2600 },
  { nameX: 'Jun', nameY: '5.0k', creatoValue: 5.4, pv: 2400, amt: 2600 },
  { creatoValue: 7.4, pv: 2400, amt: 2600 },
  { nameX: 'Jul', nameY: '7.5k', creatoValue: 4.5, pv: 2400, amt: 2600 },
];
