import React from 'react';
import { useNavigate } from 'react-router-dom';
import { usePreferredState } from '../../utils/customHooks';
import { IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import hand from '../../assets/images/hand.svg';
import search from '../../assets/images/Search.svg';
import plusIcon from '../../assets/images/Add.svg';
import { useMediaQuery } from '@mui/material';
import PLUS from '../../assets/images/plus.png';

const DashBoardHeader = ({ location, searchValue, setSearchValue }) => {
  const { currentUser, isGuestUser } = usePreferredState('user');
  const matches = useMediaQuery('(max-width: 720px)');

  const navigate = useNavigate();

  return (
    <div className="d-header">
      <div className="head-title">
        <IconButton className="icon-btn" onClick={() => navigate(-1)}>
          <ArrowBackIcon />
        </IconButton>
        <div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <h1>Hi {currentUser.name}&nbsp;</h1>
            <span>
              <img src={hand} alt="icon" />
            </span>
          </div>
          <p>Let's get started for today</p>
        </div>
      </div>
      <div className="head-widget">
        {location ? (
          <div className="search-container">
            <form action="/search">
              <input
                className="search expandright"
                id="searchright"
                type="search"
                name="search"
                placeholder="Search"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
              />
              <label className="button searchbutton" htmlFor="searchright">
                <img className="icon mglass" src={search} alt={search} />
              </label>
            </form>
          </div>
        ) : (
          <img className="icon mglass" src={search} alt={search} />
        )}
        {!isGuestUser &&
          (matches ? (
            <img
              src={PLUS}
              alt="PLUS"
              style={{ cursor: 'pointer' }}
              onClick={() => (location === 'community' ? navigate('/communitycreation') : {})}
            />
          ) : (
            <button
              className="btn-blue"
              style={{ cursor: 'pointer' }}
              title={location === 'community' ? 'Create Community' : ' Create Meeting'}
              onClick={() => (location === 'community' ? navigate('/communitycreation') : {})}
            >
              {location === 'community' ? 'CREATE COMMUNITY' : ' CREATE MEETING'}
              <span>
                <img src={plusIcon} alt="add-icon" />
              </span>
            </button>
          ))}
      </div>
    </div>
  );
};

export default DashBoardHeader;
