import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

function createData(name, price, value) {
  return { name, price, value };
}

const rows = [
  createData('Kabali', 1000, 5000),
  createData('Rahman Coin', 6000, 2000),
  createData('Rajini Coin', 4000, 2000),
  createData('Branson', 9000, 2000),
];

const HistoryTable = () => {
  const nameIconBg = ['#50AF95', '#50AF95', '#FE98BF', '#F8C56C'];

  return (
    <TableContainer>
      <Table aria-label="simple table">
        <TableHead className="table-head">
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell align="center">Price</TableCell>
            <TableCell align="right">Value in INR</TableCell>
          </TableRow>
        </TableHead>
        <TableBody className="table-body">
          {rows.map((row, index) => (
            <TableRow key={row.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell className="name" component="th" scope="row">
                <p>
                  <span style={{ backgroundColor: nameIconBg[index] }}>{row.name[0].toUpperCase()}</span> {row.name}
                </p>
              </TableCell>
              <TableCell className="price" align="right">
                {row.price} CREATO
              </TableCell>
              <TableCell className="value" align="right">
                {row.value} &#8377;
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
export default HistoryTable;
