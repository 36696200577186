import React from 'react';
import { usePreferredState } from '../../utils/customHooks';
import notify from '../../assets/images/notify.svg';
import notifyBlue from '../../assets/images/notify-blue.svg';
import calendar from '../../assets/images/calendar.svg';
import arrowDown from '../../assets/images/arrow-down.svg';
import naveen from '../../assets/images/Ellipse 2.jpg';
import rahul from '../../assets/images/Ellipse 3.jpg';
import user1 from '../../assets/images/Ellipse 5.jpg';
import saranya from '../../assets/images/Ellipse 6.jpg';
import ranjith from '../../assets/images/Ellipse 4.jpg';
import rightArrowBtn from '../../assets/images/right-arrow-btn.png';

const SideSection = () => {
  const { currentUser } = usePreferredState('user');
  const profileImg = currentUser.displayPic.includes('https') ? currentUser.displayPic : `data:image/svg+xml;base64,${currentUser?.displayPic}`;
  const list = [1, 2, 3, 4, 5];

  return (
    <div className="d-side-section">
      <div className="side-section-head">
        <div className="side-section-user-profile">
          <div className="user-icon">
            <img src={profileImg} alt="profile" />
          </div>
          <div className="user-detail">
            <h1>{currentUser.name}</h1>
            <p>{currentUser.occupation}</p>
          </div>
        </div>
        <div className="side-section-notification">
          <div className="notification-icon">
            <img src={notify} alt="image" />
          </div>
        </div>
      </div>
      <div className="side-section-body">
        <div className="recommend-title">
          <h1>Recommended</h1>
        </div>
        <div className="design-card-container scroll-bar-1">
          {list.map((item) => (
            <div key={item} className="design-card">
              <div className="design-card-head">
                <div className="design-card-title">
                  <h1>Daily#10 "Design Project"</h1>
                  <p>14 Participants</p>
                </div>
                <div className="design-card-icon">
                  <img src={notifyBlue} alt="image" />
                </div>
              </div>
              <div className="design-card-foot">
                <p>
                  Web,Feb 3{' '}
                  <span>
                    <img src={calendar} alt="image" />
                  </span>
                </p>
              </div>
            </div>
          ))}
        </div>
        <div className="show-more-container">
          <p className="show-more">Show more</p>
          <img src={arrowDown} alt="icon" />
        </div>
      </div>
      <div className="side-section-foot">
        <div className="community-title">
          <h1>Community</h1>
          <div className="community-icons-container">
            <div className="community-icons">
              <img src={naveen} alt="icons" />
              <img src={rahul} alt="icons" />
              <img src={ranjith} alt="icons" />
              <img src={user1} alt="icons" />
              <img src={saranya} alt="icons" />
              <div className="num46">
                <p>+46</p>
              </div>
            </div>
          </div>
        </div>
        <div className="invite-container">
          <p>Invite More People</p>
          <div className="email-container">
            <input type="email" placeholder="Enter Email" />
            <img src={rightArrowBtn} alt="icon" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SideSection;
