import React from 'react';
import { usePreferredState } from '../../../utils/customHooks';

const MemberCard = ({ member }) => {
  const { notifications, activeUsers } = usePreferredState('user');
  const hasNotifications = notifications[member.id]?.length;
  const profileImg = member.displayPic.includes('https') ? member.displayPic : `data:image/svg+xml;base64,${member?.displayPic}`;

  return (
    <>
      <div className="member-avatar">
        <img src={profileImg} alt={member.name} width="51px" height="50px" />
        {activeUsers.includes(member.id) && <p id="badge" />}
      </div>
      <div className="member-info">
        <p id="name">
          {member.name} {member.pin && <img src={member.pin} alt="pinned" />}
        </p>
        {/* <p id="message">{member.message}</p> */}
      </div>
      <div className="message-count">
        <span id={`${hasNotifications ? 'count' : 'time'}`} style={{ '--countClr': '#7c7ef7' }}>
          {hasNotifications ? hasNotifications : ''}
        </span>
      </div>
    </>
  );
};

export default MemberCard;
