import React from 'react';

const PageIndicator = ({ modalPage }) => {
  return (
    <div className="page-indicator">
      <span className={`${modalPage === 1 ? 'extra loader' : 'loader'}`}></span>
      <span className={`${modalPage === 2 ? 'extra loader' : 'loader'}`}></span>
      <span className={`${modalPage === 3 ? 'extra loader' : 'loader'}`}></span>
      <span className={`${modalPage === 4 ? 'extra loader' : 'loader'}`}></span>
    </div>
  );
};

export default PageIndicator;
