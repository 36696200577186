import Header from './Header';
import Community from './Community';
import PostSection from './Post';

const MainSection = ({ data }) => {
  return (
    <div className="hw-main-section scroll-bar-1">
      <Header data={{ data }} />
      <Community data={{ data }} />
      <PostSection data={{ data }} />
    </div>
  );
};

export default MainSection;
