import { createSlice, current } from '@reduxjs/toolkit';
import { avatarApi } from '../../routes/APIRoutes';
import { groupBy } from '../../utils/helpers';

const initialState = {
  connected: false,
  isGuestUser: false,
  currentUser: null,
  contacts: [],
  contactsById: {},
  currentChat: null,
  notifications: {},
  postData: [],
  tags: [],
  messages: [],
  userInfo: { username: '', id: '', email: '', confirmed: false },
  accountInfo: {},
  cacheId: localStorage.getItem('accountId'),
  loading: false,
  selectedProfile: null,
  likedUsers: [],
  comments: [],
  hashTags: [],
  activeUsers: [],
  isEditable: false,
  anime: { value: '', open: false },
  communities: { isRequesting: false, payload: [] },
  selectedCommunity: { id: null, payload: {} },
  tokens: { isRequesting: false, payload: [] },
  registerModal: { show: false, payload: {} },
  tagPostData: [],
  searchCommunitiesData: [],
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setGuestUser: (state) => {
      state.connected = true;
      state.isGuestUser = true;
      state.currentUser = {
        id: '9f8e22e6-58d6-4a3c-b755-16aee2f0c13d',
        name: 'Guest',
        username: 'Guest',
        occupation: 'Viewer',
        address: 'Guest@test.com',
        skills: [],
        displayPic: `${avatarApi}boy4-min.png`,
      };
    },
    setCurrentUser: (state, action) => {
      const payload = action.payload ? action.payload : initialState.accountInfo;
      state.connected = true;
      state.currentUser = payload;
    },
    setCurrentChat: (state, action) => {
      const payload = action.payload ? action.payload : initialState.currentChat;
      state.currentChat = payload;
    },
    setContacts: (state, action) => {
      state.contacts = action.payload;
      const contactsById = groupBy(action.payload, 'id');
      state.contactsById = contactsById;
    },
    setCacheId: (state, action) => {
      const payload = action.payload ? action.payload : '';
      state.cacheId = payload;
    },
    setSelectedProfile: (state, action) => {
      const payload = action.payload ? action.payload : initialState.selectedProfile;
      state.selectedProfile = payload;
    },
    setNotifications: (state, action) => {
      let p = action.payload;
      if (!p) return;
      if (!state.notifications.hasOwnProperty(p.from)) {
        state.notifications[p.from] = [{ fromSelf: false, message: p.message }];
      } else {
        state.notifications[p.from].push({ fromSelf: false, message: p.message });
      }
    },
    setReadMessages: (state, action) => {
      if (!action.payload) return;

      const updatedNotifications = Object.keys(state.notifications).reduce((acc, key) => {
        if (key !== action.payload) {
          acc[key] = state.notifications[key];
        }
        return acc;
      }, {});

      state.notifications = updatedNotifications;
    },
    setMessages: (state, action) => {
      state.messages = action.payload;
    },
    setArraivalMessages: (state, action) => {
      if (state.currentChat && action.payload.from === state.currentChat.id) {
        state.messages.push({ fromSelf: false, message: action.payload.message });
      }
      if (!state.currentChat || action.payload.from !== state.currentChat.id) {
        if (!state.notifications.hasOwnProperty(action.payload.from)) {
          state.notifications[action.payload.from] = [{ fromSelf: false, message: action.payload.message, from: action.payload.from }];
        } else {
          state.notifications[action.payload.from].push({ fromSelf: false, message: action.payload.message, from: action.payload.from });
        }
      }
    },
    setMarkAllAsRead: (state) => {
      state.notifications = {};
    },
    setUserInfo: (state, action) => {
      const payload = action.payload ? action.payload : initialState.userInfo;
      state.userInfo = payload;
    },

    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    onLogout: (state) => {
      state.connected = initialState.connected;
      state.currentUser = initialState.currentUser;
      state.currentChat = initialState.currentChat;
      state.isGuestUser = initialState.isGuestUser;
      state.userInfo = initialState.userInfo;
      state.accountInfo = initialState.accountInfo;
      state.cacheId = '';
    },
    setPostData: (state, action) => {
      const payload = action.payload;

      const ids = payload.map((post) => {
        return { postId: post.id, users: post.attributes.likedUsers.data };
      });

      state.likedUsers = ids;
      state.postData = action.payload;
    },
    setTags: (state, action) => {
      state.tags = action.payload;
    },
    setSelectedHashTag: (state, action) => {
      state.tags = action.payload;
    },
    likePost: (state, action) => {
      const { postId, liked, user } = action.payload;
      let likedIndex = state.postData.findIndex((post) => post.id == postId);
      const presentCount = state.postData[likedIndex].attributes.likeCount;
      if (liked > presentCount) {
        state.postData[likedIndex].attributes.likedUsers.data.push(user);
        state.currentUser.reward = +state.currentUser.reward + 10;
      } else {
        state.postData[likedIndex].attributes.likedUsers.data = state.postData[likedIndex].attributes.likedUsers.data.filter(
          (item) => item.id !== user.id,
        );
        state.currentUser.reward = +state.currentUser.reward - 10;
      }
      state.postData[likedIndex].attributes.likeCount = liked;
    },
    // likePost: (state, action) => {
    //   const { postId, liked, user } = action.payload;
    //   let likedIndex = state.postData.findIndex((post) => post.id == postId);
    //   const presentCount = state.postData[likedIndex].attributes.likeCount;
    //   console.log('State Liked : ', state.postData[likedIndex]);
    //   if (liked > presentCount) {
    //     state.postData[likedIndex].attributes.likedUsers.data.push(user);
    //     state.currentUser.reward = +state.currentUser.reward + 10;
    //   } else {
    //     state.postData[likedIndex].attributes.likedUsers.data = state.postData[likedIndex].attributes.likedUsers.data.filter(
    //       (item) => item.id !== user.id,
    //     );
    //     state.currentUser.reward = +state.currentUser.reward - 10;
    //   }
    //   state.postData[likedIndex].attributes.likeCount = liked;
    // },
    setComments: (state, action) => {
      const payload = action.payload ? action.payload : initialState.comments;
      state.comments = payload;
    },
    addComment: (state, action) => {
      state.comments.push(action.payload);
    },
    setAllHashTags: (state, action) => {
      state.hashTags = action.payload;
    },
    updateRewards: (state, action) => {
      state.currentUser.reward = action.payload;
    },
    updateProfile: (state, action) => {
      const payload = action.payload;
      for (const [key, value] of Object.entries(payload)) {
        state.currentUser[key] = value;
      }
    },
    setActiveUsers: (state, action) => {
      const payload = action.payload ? action.payload : initialState.activeUsers;
      const excludeMe = Object.keys(payload).filter((id) => id != state.currentUser.id);
      const isSame = JSON.stringify(excludeMe) == JSON.stringify(state.activeUsers);
      if (isSame) return;
      // const onlineUsers = Object.keys(state.contactsById).filter((id) => Object.keys(payload).includes(id));
      state.activeUsers = excludeMe;
    },
    setIsEditable: (state, action) => {
      const payload = action.payload ? action.payload : initialState.isEditable;
      state.isEditable = payload;
    },
    setIsAnime: (state, action) => {
      const payload = action.payload ? action.payload : initialState.anime;
      state.anime.value = payload.value;
      state.anime.open = payload.open;
    },
    setCoverPic: (state, action) => {
      state.currentUser.coverPic = action.payload;
    },
    setProfileImage: (state, action) => {
      state.currentUser.profileImage = action.payload;
    },
    setPortfolioImage: (state, action) => {
      state.currentUser.portfolioImage = action.payload;
    },
    setRequestingCommunities: (state, action) => {
      const payload = action.payload ? action.payload : initialState.communities.isRequesting;
      state.communities.isRequesting = payload;
    },
    setCommunities: (state, action) => {
      // if (!action.payload || !action.payload.length) return;
      state.communities.isRequesting = false;
      state.communities.payload = action.payload;
    },
    setSelectedCommunity: (state, action) => {
      const payload = action.payload ? action.payload : initialState.selectedCommunity;
      state.selectedCommunity = payload;
    },
    setRequestingTokens: (state, action) => {
      const payload = action.payload ? action.payload : initialState.tokens.isRequesting;
      state.tokens.isRequesting = payload;
    },
    setTokens: (state, action) => {
      if (!action.payload || !action.payload.length) return;
      state.tokens.isRequesting = false;
      state.tokens.payload = action.payload;
    },
    setRegisterModal: (state, action) => {
      const payload = action.payload ? action.payload : initialState.registerModal;
      state.registerModal = { ...state.registerModal, ...payload };
    },
    setTagPostData: (state, action) => {
      state.tagPostData = action.payload;
    },
    setSearchCommunitiesData: (state, action) => {
      state.searchCommunitiesData = action.payload;
    },
  },
});

export const {
  setCurrentUser,
  setCurrentChat,
  setContacts,
  setNotifications,
  setReadMessages,
  setMessages,
  setArraivalMessages,
  setMarkAllAsRead,
  onLogout,
  setUserInfo,
  setGuestUser,
  setCacheId,
  setLoading,
  setPostData,
  setTags,
  likePost,
  setComments,
  setSelectedProfile,
  setAllHashTags,
  addComment,
  setActiveUsers,
  updateRewards,
  updateProfile,
  setIsEditable,
  setIsAnime,
  setCoverPic,
  setProfileImage,
  setPortfolioImage,
  setRequestingCommunities,
  setCommunities,
  setSelectedCommunity,
  setRequestingTokens,
  setTokens,
  setRegisterModal,
  setTagPostData,
  setSearchCommunitiesData,
} = userSlice.actions;

export default userSlice.reducer;
