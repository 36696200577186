import React, { useEffect, useState } from 'react';
import SideSection from './SideSection';
import DashBoardHeader from '../dashboard/Header';
import CommunityCard from '../../components/cards/CommunityCard';
import CommunityPage from '../community-page';
import { HorizontalLoader } from '../../utils/widgets/HorizontalLoader';
import { usePreferredState } from '../../utils/customHooks';
import label from '../../assets/images/label.svg';
import filter from '../../assets/images/Filter.svg';
import '../../assets/scss/community-home/index.scss';
import { useDispatch } from 'react-redux';
import { setCommunities } from '../../state/user';
import { useMediaQuery } from '@mui/material';
import SideBar from '../../components/post-connect/side-bar';

const absoluteCenteringStyle = { position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' };
const CommunityHome = () => {
  const { communities, selectedCommunity, searchCommunitiesData } = usePreferredState('user');
  const [searchCommunity, setSearchCommunity] = useState('');
  const dispatch = useDispatch();
  const matches = useMediaQuery('(max-width: 1200px)'); //xl

  useEffect(() => {
    if (searchCommunity !== '') {
      handleSearchCommunity();
    } else {
      dispatch(setCommunities(searchCommunitiesData));
    }
  }, [searchCommunity]);

  const handleSearchCommunity = async () => {
    let result = await searchCommunitiesData.filter((data) => {
      let text = searchCommunity.toLowerCase().split(' ');
      return text.some((el) => data.attributes.name.toLowerCase().includes(el));
    });

    dispatch(setCommunities(result));
  };

  return (
    <>
      <div className="community-home">
        {!selectedCommunity.id ? (
          <>
            <div className="d-container">
              <DashBoardHeader location="community" searchValue={searchCommunity} setSearchValue={setSearchCommunity} />
              <div className="c-body">
                <div className="c-body-head">
                  <div className="status">
                    <p className="status-pointer"></p>
                    <h1>LIVE NOW</h1>
                  </div>
                  <div className="body-widget">
                    <p>
                      <span>
                        <img src={label} alt="label" />
                      </span>{' '}
                      Labels
                    </p>
                    <p>
                      <span>
                        <img src={filter} alt="filter" />
                      </span>{' '}
                      Filter
                    </p>
                  </div>
                </div>
                <div className={`c-body-content ${communities.isRequesting || !communities.payload.length ? 'c-body-loader' : ''} scroll-bar`}>
                  {communities.isRequesting && <HorizontalLoader style={absoluteCenteringStyle} />}
                  {!communities.isRequesting && communities.payload.length === 0 && (
                    <span id="no-data" style={absoluteCenteringStyle}>
                      No community found.
                    </span>
                  )}
                  {!communities.isRequesting && communities.payload.length > 0
                    ? communities.payload.map((c) => <CommunityCard key={c.id} community={c} />)
                    : null}
                </div>
              </div>
            </div>
            {!matches && <SideSection />}
          </>
        ) : (
          <CommunityPage data={selectedCommunity.payload} />
        )}
      </div>
      <SideBar className="side-bar-mb mb" />
    </>
  );
};

export default CommunityHome;
