import React from 'react';
import { useDispatch } from 'react-redux';
import JOIN_MEETING_BUTTON from '../../assets/images/JOIN_MEETING_BUTTON.png';
import { setSelectedCommunity } from '../../state/user';
import { FOLLOWERS_LIST } from '../../utils/constants';

const CommunityCard = ({ community }) => {
  const dispatch = useDispatch();

  return (
    <div className="community-card-main-container" onClick={() => dispatch(setSelectedCommunity({ id: community.id, payload: community }))}>
      <div className="community-card-cover-image">
        <img src={community?.attributes?.coverImg} alt="coverImg" className="cover-image-src" />
      </div>
      <img src={community?.attributes?.displayPic} alt="displayPic" className="community-card-profile-image" />
      <div className="community-card-user-name">
        <p className="community-card-username">{community?.attributes?.name}</p>
        <p className="organizer">Organizer</p>
        <p className="community-card-purpose">{community?.attributes?.purpose}</p>
        <p className="community-card-members-count">15 members</p>
      </div>
      <div className="community-card-footer">
        <div className="join-meeting-button">
          {/* <p>Join Meeting</p> */}
          <img src={JOIN_MEETING_BUTTON} alt="join-meeting-button" />
        </div>
        <div className="community-card-followers-image">
          {FOLLOWERS_LIST.map((follower) => (
            <div className="community-card-followers-image-border" key={follower.id}>
              <img src={require(`../../assets/images/${follower.src}.jpg`)} alt={follower.src} />
            </div>
          ))}
          <p className="community-card-extra-followers">+16</p>
        </div>
      </div>
    </div>
  );
};

export default CommunityCard;
