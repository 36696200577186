import React from 'react';
import '../../assets/scss/loader.scss';

export const HorizontalLoader = ({ style }) => {
  return (
    <div className="lds-ellipsis" style={style}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};
