import React,{ useRef, useState, useEffect } from 'react';
import { uploadFile } from 'react-s3';
import { communityCoverConfig, communityImgConfig } from '../../../config';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import CropOutlinedIcon from '@mui/icons-material/CropOutlined';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import SearchIcon from '@mui/icons-material/Search';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import createComProfile from '../../../assets/images/create-com-profile.png';
import createComBanner from '../../../assets/images/create-com-banner.png';
import blueTick from '../../../assets/images/blue-tick.png';
import greenTick from '../../../assets/images/green-tick.png';
import ImagePreview from '../../../utils/imageCrop/ImagePreview';
import { LanguageListData } from '../data';

const CreateCommunity = (props) => {
  const { setName, setDescription, setCoverImg, setImg, img, coverImg } = props
  
  const [nameLen, setNameLen] = useState(0);
  const [language, setLanguage] = useState(LanguageListData[0]);
  const [langContActive, setLangContActive] = useState(false);
  const [languageList, setLangaugeList] = useState([...LanguageListData]);
  const [file, setFile] = useState(null);
  const [image, setImage] = useState(null);
  const hiddenFileInput = useRef(null);
  const hiddenImgInput = useRef(null);

  const langContStyle = {
    boxShadow: langContActive && '0px -3px 8px rgba(0, 0, 0, 0.05)',
    backgroundColor: langContActive && 'white',
  };

  /* Crop Image Part */
  useEffect(() => {
    if (file) {
      const reader = new FileReader();
      reader.addEventListener(
        'load',
        () => {
          setImage(reader.result);
        },
        false,
      );
      reader.readAsDataURL(file);
    }
  }, [file]);

  const handleFinalImage = (image) => {
    const uploadedFile = image;
    uploadFile(uploadedFile, communityCoverConfig)
      .then((res) => {
        setCoverImg(res.location);
      })
      .catch((e) => {
        console.log(e);
      });
    setImage(null);
  };
  /* Crop Image Part Ends Here */

  const handleNameChange = (e) => {
    let value = e.target.value;
    setNameLen(value.length);
    setName(e.target.value);
  };

  const handleLangContActive = (value) => {
    setLangContActive(value);
  };

  const handleSelectLanguage = (item) => {
    setLanguage(item);
    setLangContActive(false);
    setLangaugeList([...LanguageListData]);
  };

  const handleLangFilter = (e) => {
    let searchKey = e.target.value;
    let result = LanguageListData.filter((item) => {
      return item.lang.toLowerCase().includes(searchKey);
    });
    setLangaugeList(result);
  };

  const handleCommunityImgUpload = (e) => {
    const uploadedFile = e.target.files[0];
    uploadFile(uploadedFile, communityImgConfig)
      .then((res) => {
        setImg(res.location);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <div className="crt-com-container">
      <div className="crt-com-head">
        <h1>Create Community</h1>
      </div>
      <div className="crt-com-banner">
        <img src={coverImg !== '' ? coverImg : createComBanner} alt="banner" />
        <div className="top-widget-container">
          <FileUploadOutlinedIcon style={{ cursor: 'pointer' }} onClick={() => hiddenFileInput.current.click()}></FileUploadOutlinedIcon>
          <input type="file" name="file" style={{ display: 'none' }} ref={hiddenFileInput} onChange={(e) => setFile(e.target.files[0])} />
          <DeleteOutlinedIcon />
          <CropOutlinedIcon />
        </div>
        <div className="profile-icon" onClick={() => hiddenImgInput.current.click()} style={{ cursor: 'pointer' }}>
          <input type="file" name="file" style={{ display: 'none' }} ref={hiddenImgInput} onChange={handleCommunityImgUpload} />
          <img src={img !== '' ? img : createComProfile} alt="profile-icon" />
          <div className="camera-icon">
            <CameraAltOutlinedIcon />
          </div>
        </div>
        <div className="banner-camera-icon">
          <CameraAltIcon onClick={() => hiddenFileInput.current.click()} />
        </div>
      </div>
      <div className="com-form">
        <div style={{ border: nameLen > 0 ? '1px solid #4edbb0' : 'none' }} className="int-name-container f-int">
          <label htmlFor="name">Community Name</label>
          <input onChange={handleNameChange} type="text" name="" placeholder="Create Community Name" id="name" />
          <img style={{ display: nameLen > 0 ? 'inline' : 'none' }} src={greenTick} alt="icon" />
        </div>
        <div className="int-desc-container f-int">
          <label htmlFor="desc">Community Description</label>
          <textarea
            id="desc"
            placeholder="Tell here about your community"
            rows="5"
            cols="50"
            onChange={(e) => setDescription(e.target.value)}
          ></textarea>
        </div>
        <div className="int-lang-container" style={langContStyle}>
          <div className="lang-input" onClick={() => handleLangContActive(true)}>
            {langContActive ? (
              <>
                <SearchIcon />
                <input onChange={handleLangFilter} type="text" name="" placeholder="Choose Language" id="name" />
              </>
            ) : (
              <ul className="selected-lang" onClick={() => handleLangContActive(true)}>
                <li>
                  <div className="lang-img-cont">
                    <img src={language.img} alt="icon" />
                  </div>
                  <div className="lang-content">
                    <p>{language.lang}</p>
                    <p>{language.lang}</p>
                  </div>
                </li>
              </ul>
            )}

            <ExpandCircleDownIcon style={{ color: langContActive ? 'black' : '#CDCDCD' }} />
          </div>
          <div className="lang-option-container scroll-bar-1" style={{ display: langContActive ? 'block' : 'none' }}>
            <ul>
              {languageList.length > 0 &&
                languageList.map((item, index) => (
                  <li key={item.id} onClick={() => handleSelectLanguage(item)}>
                    <div className="lang-img-cont">
                      <img src={item.img} alt="icon" />
                    </div>
                    <div className="lang-content">
                      <p>{item.lang}</p>
                      <p>{item.lang}</p>
                    </div>
                    <img className="select-lang-icon" src={blueTick} alt="icon" />
                  </li>
                ))}
            </ul>
          </div>
        </div>
      </div>
      {image && <ImagePreview image={image} setImage={setImage} handleFinalImage={handleFinalImage} />}
    </div>
  );
};

export default CreateCommunity;
