import { useState } from 'react';
import CreateCommunityHead from './head/CreateCommunityHead';
import CommunityRules from './CommunityRules';
import { CommunityRulesHeadData } from '../data';

const CommunityRulesContainer = (props) => {
  const { singleRule, setSingleRule, rules, setRules } = props;

  const [rulesList, setRulesList] = useState([0]);

  const handleRemoveLink = (index) => {
    let newList = [...rulesList];
    newList.splice(index, 1);
    setRulesList([...newList]);
  };

  const handleAddRule = () => {
    let newLink = rulesList.length + 1;
    setRulesList([newLink, ...rulesList]);
    setRules([...rules, singleRule]);
  };

  return (
    <div className="community-rules-container">
      <CreateCommunityHead head={CommunityRulesHeadData} />
      <div className="community-rules-body scroll-bar-4">
        {rulesList.map((item, index) => (
          <CommunityRules key={index} index={index} handleRemoveLink={handleRemoveLink} rule={singleRule} setRule={setSingleRule} />
        ))}
      </div>
      <div className="community-rules-add-btn">
        <button onClick={handleAddRule}>+ Add Rule</button>
      </div>
    </div>
  );
};

export default CommunityRulesContainer;
