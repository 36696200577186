import comTopBanner from '../../../../assets/images/com-top-banner.png';

const CreateCommunityHead = ({ head }) => {
  return (
    <div className="com-top-head">
      <h1>{head.title}</h1>
      {head.img && (
        <div className="com-head-banner">
          <img src={comTopBanner} alt="banner" />
        </div>
      )}
      <p>{head.desc}</p>
    </div>
  );
};

export default CreateCommunityHead;
