import React from 'react';
import { useNavigate } from 'react-router-dom';
import CachedIcon from '@mui/icons-material/Cached';
import { Box } from '@mui/material';
import { IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { usePreferredState } from '../../../utils/customHooks';

const Head = () => {
  const { currentUser } = usePreferredState('user');
  const navigate = useNavigate();
  return (
    <div className="main-section-head">
      <div className="head-title">
        <IconButton onClick={() => navigate('/')}>
          <ArrowBackIcon />
        </IconButton>
        <h1>
          Welcome back, <span style={{ whiteSpace: 'nowrap' }}>{currentUser.name}</span>
        </h1>
      </div>
      <div className="head-widget">
        <button className="notify">
          <span></span>Notifications
        </button>
        <button className="sync">
          <CachedIcon />
          Sync
        </button>
        <button className="sync" onClick={() => navigate('/tokencreation')}>
          Create Token
        </button>
        {/* <button className="sync" onClick={() => navigate('/tokencreation')}>
          Create Token
        </button> */}
      </div>
    </div>
  );
};

export default Head;
