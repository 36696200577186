import React from 'react';
import { Box, Chip, Grid, IconButton, Stack, Typography } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import MenuIcon from '@mui/icons-material/Menu';
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import StarRatings from '../Ratings';
import { usePreferredState } from '../../customHooks';
import { setSelectedProfile } from '../../../state/user';
import { useDispatch } from 'react-redux';
import useMediaQuery from '@mui/material/useMediaQuery';

const Header = ({ post }) => {
  const { isGuestUser, currentUser } = usePreferredState('user');
  const dispatch = useDispatch();
  const dp = post?.attributes?.owner?.data?.id === currentUser.id ? currentUser.displayPic : post?.attributes?.owner?.data?.attributes?.displayPic;
  const profileImg = dp.includes('https') ? dp : `data:image/svg+xml;base64,${dp}`;
const matches = useMediaQuery('(min-width:720px)');

  return (
    <Grid className="post-card-header">
      <Grid className="user-details">
        <img
          src={profileImg}
          className="user-image"
          alt="User Image"
          onClick={() => dispatch(setSelectedProfile(post?.attributes?.owner?.data?.id))}
        />
        <Grid sx={{ display: 'flex', flexDirection: 'column', gap: '0.25rem' }}>
          <Grid sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
            <Typography className="user-name" onClick={() => dispatch(setSelectedProfile(post?.attributes?.owner?.data?.id))}>
              {post?.attributes?.owner?.data?.attributes?.name}
            </Typography>
            { matches &&  <StarRatings rating={post?.attributes.likeCount} size={25} /> }
            { !matches &&  <StarRatings rating={post?.attributes.likeCount} size={15} /> }
          </Grid>
          <Grid sx={{ display: 'flex', alignItems: 'center', gap: '0.25rem' }}>
            <Stack direction="row" spacing={1}>
              <Stack direction="row" spacing={1}>
                {post.attributes.hashtags.data.slice(0, 3).map((data, i) => {
                  let randomColor = Math.floor(Math.random() * 16777215).toString(16);
                  return <Chip key={i} label={data.attributes.hashtag} className="chip" style={{ background: `${'#' + randomColor}` }} />;
                })}
              </Stack>
            </Stack>
            <Grid sx={{ display: 'flex', alignItems: 'center' }}>
              <LocationOnIcon className="user-location-icon" />
              <Typography className="user-location">chennai</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid className="menu-time-container">
          <Grid className="menu-wrap" style={{ cursor: isGuestUser ? 'not-allowed' : 'pointer' }}>
            <MenuIcon className="menu-icon" />
            <Typography className="menu-icon-text">0.50</Typography>
          </Grid>
          <Grid sx={{ display: 'flex', alignItems: 'center' }}>
            <AccessTimeIcon className="user-time-left-icon" />
            <Typography className="user-time-left">30 minutes left</Typography>
          </Grid>
        </Grid>
      </Grid>
      <IconButton className="icon-btn" disabled={isGuestUser}>
        <WorkspacesIcon className="workspace-icon" />
      </IconButton>
    </Grid>
  );
};

export default Header;
