import React, { useState } from 'react';
import BasicTable from './BasicTable';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import SearchIcon from '@mui/icons-material/Search';
import { isNullOrUndefined } from 'util';
import { useMediaQuery } from '@mui/material';

const Table = () => {
  const [toggleActive, setToggleActive] = useState(0);
  const [searchTable, setSearchTable] = useState('');
  const matches = useMediaQuery('(min-width: 600px)');

  return (
    <div className="table-container">
      <div className="table-container-head">
        <div className="table-head-left">
          <h1>Recent Transaction History</h1>
        </div>
        {matches && (
          <div className="table-head-right">
            <button className="btn download-btn" disabled>
              <CloudDownloadOutlinedIcon /> Download
            </button>
            <button className="btn report-btn" disabled>
              View Report
            </button>
          </div>
        )}
      </div>
      <div className="table-container-widget">
        <div className="table-widget-left">
          <ul>
            {['View All', 'Investments', 'Earned'].map((item, index) => (
              <li
                key={index}
                className={toggleActive === index && 'active'}
                style={{ cursor: item !== 'View All' ? 'not-allowed' : 'pointer' }}
                onClick={() => setToggleActive(0)}
              >
                {item}
              </li>
            ))}
          </ul>
        </div>
        {matches && (
          <div className="table-widget-right">
            <div className="input-container">
              <SearchIcon />
              <input placeholder="Search" value={searchTable} onChange={(e) => setSearchTable(e.target.value)} />
            </div>
          </div>
        )}
      </div>
      <BasicTable search={searchTable} />
    </div>
  );
};

export default Table;
