import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
// import axios from 'axios';
// import { Buffer } from 'buffer';
import { strapiClient } from '../../..';
import OnboardingLeft2 from './OnboardingLeft2';
import UserProfile from './UserProfile';
import RegistrationPage from './RegistrationPage';
import { avatarApi } from '../../../routes/APIRoutes';
import { REGISTER_USER } from '../../../config/mutations';
import { Snack } from '../../../utils/widgets/Toast';
import { setLoading, setRegisterModal } from '../../../state/user';
import { createAccount } from '../../../state/user/actions';
import '../../../assets/scss/landing-page/onboarding.scss';
import { MALE } from '../../../utils/constants';
import ONBOARDING_LEFT_IMG1 from '../../../assets/images/ONBOARDING-LEFT-IMG1.png';
import ONBOARDING_LEFT_IMG2 from '../../../assets/images/ONBOARDING-LEFT-IMG2.png';
import ONBOARDING_LEFT_IMG3 from '../../../assets/images/ONBOARDING-LEFT-IMG3.png';

// import PdfQrPage from './PdfQrPage';
// import InterestPage from './InterestPage';
// import BuyToken from './BuyToken';

const toastOptions = {
  position: 'bottom-right',
  autoClose: 8000,
  pauseOnHover: true,
  draggable: true,
};

const OnBoardingPage = (props) => {
  const { previousPage, isLogin, setIsLogin, handleClose } = props;
  //   const [onbordingState, setOnbordingState] = useState(1);
  const dispatch = useDispatch();
  const [isChecked, setIsChecked] = useState([1]);
  const [page, setPage] = useState(1);
  const [registerInfo, setRegisterInfo] = useState({});
  const [userProfileInfo, setUserProfileInfo] = useState({});

  const handleRegister = async (userInfo) => {
    const { email, username, password, phonenumber, name, countrycode } = registerInfo;
    const { gender } = userInfo;

    dispatch(setLoading(true));

    // const image = await axios.get(`${avatarApi}/${Math.round(Math.random() * 1000)}`);
    // console.log(image);
    // const buffer = new Buffer(image.data);
    // const avatarImage = buffer.toString('base64');

    const randomNo = Math.round(Math.random() * 7) + 1;
    const avatarImage = `${avatarApi}${gender === MALE ? 'boy' : 'girl'}${randomNo}-min.png`;

    const variables = {
      input: {
        username: username,
        email: email,
        password: password,
      },
    };
    strapiClient
      .request(REGISTER_USER, variables)
      .then((res) => {
        if (res.register.jwt) {
          strapiClient.setHeader('authorization', `Bearer ${res.register.jwt}`);
          delete userInfo.skills;
          dispatch(
            createAccount(
              {
                ...userInfo,
                phoneNumber: phonenumber,
                countryCode: countrycode,
                name: name,
                user: res.register.user.id,
                displayPic: avatarImage,
                address: email,
                reward: 100,
              },
              (success) => {
                if (success) {
                  localStorage.setItem('token', res.register.jwt);
                  dispatch(setRegisterModal({ show: true }));
                  Snack('User registered successfully!', 2000, 'success');
                }
              },
            ),
          );
        }
      })
      .catch((e) => {
        dispatch(setLoading(false));
        localStorage.clear();
        console.log('Registration error--->', e);
        if (e.response?.errors) {
          Snack(e.response.errors[0].message, 2000, 'error');
        } else Snack(e.response.error.message, 2000, 'error');
      });
  };

  return (
    <>
      <div className="onboarding-page">
        <div className="onboarding-container">
          <div className="onboarding-content onboarding-content---left">
            {page === 1 ? (
              !isLogin ? (
                <img src={ONBOARDING_LEFT_IMG1} alt="ONBOARDING_LEFT_IMG1" />
              ) : (
                <img src={ONBOARDING_LEFT_IMG2} alt="ONBOARDING_LEFT_IMG2" />
              )
            ) : (
              page === 2 && <img src={ONBOARDING_LEFT_IMG3} alt="ONBOARDING_LEFT_IMG3" />
            )}
          </div>
          <div className="onboarding-content onboarding-content---right">
            {/* <button className="closeButton" onClick={handleClose}>
              x
            </button> */}
            {page === 1 ? (
              <RegistrationPage
                registerInfo={registerInfo}
                setRegisterInfo={setRegisterInfo}
                nextStep={() => {
                  setPage(2);
                }}
                isLogin={isLogin}
                setIsLogin={setIsLogin}
                previousStep={() => previousPage()}
              />
            ) : page === 2 ? (
              <UserProfile
                previousStep={() => {
                  setPage(1);
                  setIsChecked([1, 2]);
                }}
                handleRegister={handleRegister}
                setUserProfileInfo={setUserProfileInfo}
                userProfileInfo={userProfileInfo}
              />
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default OnBoardingPage;

//old comment
// {
//   page === 2 && (
//     <UserProfile
//       previousStep={() => {
//         setPage(1);
//         setIsChecked([1, 2]);
//       }}
//       handleRegister={handleRegister}
//       setUserProfileInfo={setUserProfileInfo}
//       userProfileInfo={userProfileInfo}
//     />
//   );
// }
// {
//   page === 2 && (
//     <PdfQrPage
//       setIsChecked={setIsChecked}
//       previousStep={() => {
//         setPage(1);
//         setIsChecked([1]);
//       }}
//       nextStep={() => {
//         setPage(3);
//       }}
//     />
//   );
// }
// {
//   page === 4 && (
//     <InterestPage
//       setIsChecked={setIsChecked}
//       previousStep={() => {
//         setPage(3);
//         setIsChecked([1, 2, 3]);
//       }}
//       nextStep={() => setPage(5)}
//     />
//   );
// }
// {
//   page === 5 && (
//     <BuyToken
//       registerInfo={registerInfo}
//       setRegisterInfo={setRegisterInfo}
//       setIsChecked={setIsChecked}
//       setIsLogin={setIsLogin}
//       previousStep={() => {
//         setPage(4);
//         setIsChecked([1, 2, 3, 4]);
//       }}
//       nextStep={setPage}
//     />
//   );
// }

/* {page === 1 &&
(!isLogin ? (
  <img src={ONBOARDING_LEFT_IMG1} alt="ONBOARDING_LEFT_IMG1" />
) : (
  <img src={ONBOARDING_LEFT_IMG2} alt="ONBOARDING_LEFT_IMG2" />
))}

{page === 2 && <img src={ONBOARDING_LEFT_IMG3} alt="ONBOARDING_LEFT_IMG3" />} */
