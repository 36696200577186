import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { strapiClient } from '../..';
import Calendar from 'react-calendar';
import { TelegramShareButton, TelegramIcon, FacebookMessengerIcon, FacebookShareButton, WhatsappShareButton, WhatsappIcon } from 'react-share';
import { Grid } from '@mui/material';
import CategoryCard from '../wallet/mainsection/CategoryCard';
import HomeRightSuggestion from './HomeRightSuggestion';
import { setAllHashTags, setPostData, setSelectTags } from '../../state/user';
import { usePreferredState } from '../../utils/customHooks';
import { HorizontalLoader } from '../../utils/widgets/HorizontalLoader';
import { GET_ALL_HASH_TAGS } from '../../config/queries';
import { TAG_COLORS } from '../../utils/constants';
import '../../assets/scss/home.scss';

const HomeRightSide = () => {
  const [value, onChange] = useState(new Date());
  const { isGuestUser, hashTags, postData, tagPostData } = usePreferredState('user');
  const [loading, setLoading] = useState(true);
  const [SelectTag, setSelectTags] = useState([]);
  const dispatch = useDispatch();
  // console.log('postData', postData);
  // useEffect(() => {
  //   const interval = setTimeout(() => setLoading(!hashTags.length ? false : true), 2500);

  //   return () => clearInterval(interval);
  // }, [hashTags]);

  useEffect(() => {
    const getAllHashTags = () => {
      strapiClient
        .request({
          document: GET_ALL_HASH_TAGS,
        })
        .then((data) => {
          let hash = data.hashtags.data.map((data) => {
            // const random = Math.floor(Math.random() * 16777215).toString(16);
            const random = 'hsl(' + 360 * Math.random() + ',' + (25 + 70 * Math.random()) + '%,' + (85 + 10 * Math.random()) + '%)';
            const bgColor = TAG_COLORS.includes(random) ? 'hsl(212, 87%, 58%)' : random;
            return {
              id: data.id,
              name: data.attributes.hashtag,
              posts: data.attributes.posts.data,
              bgColor: bgColor,
            };
          });
          dispatch(setAllHashTags(hash));
        });
    };
    getAllHashTags();
  }, []);

  useEffect(() => {
    if (SelectTag.length) {
      handleHashTagFilter();
    } else {
      dispatch(setPostData([...tagPostData]));
    }
  }, [SelectTag.length]);

  const handleHashTagFilter = async () => {
    let result = await tagPostData.filter((data) => {
      let items = data?.attributes?.hashtags?.data.filter((val) => {
        return SelectTag.includes(val.attributes.hashtag);
      });
      if (items.length) return items;
    });
    if (result.length) {
      dispatch(setPostData(result));
    }
  };

  const handleSlectTags = async (tags) => {
    // console.log('SelectTag.includes(tags)', SelectTag.includes(tags));
    if (SelectTag.includes(tags) === true) {
      let filteredTag = await SelectTag.filter((val) => val !== tags);
      setSelectTags(filteredTag);
    } else {
      setSelectTags([...SelectTag, tags]);
    }
  };

  return (
    <div className="container2">
      <HomeRightSuggestion />
      <div className={`invite-friends ${isGuestUser ? 'disabled' : ''}`}>
        <p>
          + <b>INVITE FRIENDS</b>
        </p>
        <Grid sx={{ display: 'flex', gap: '1rem', justifyContent: 'flex-start' }}>
          <TelegramShareButton url={window.location.origin} title="Hey, check out this new app creatverse." hashtag="#creatverse">
            <TelegramIcon size={32} round={true} />
          </TelegramShareButton>
          <FacebookShareButton url={window.location.origin} quote="Hey, check out this new app creatverse." hashtag="#creatverse">
            <FacebookMessengerIcon size={32} round={true} />
          </FacebookShareButton>
          <WhatsappShareButton url={window.location.origin} title="Hey, check out this new app creatverse." hashtag="#creatverse">
            <WhatsappIcon size={32} round={true} />
          </WhatsappShareButton>
        </Grid>
      </div>
      <div className="groups">
        <h4 style={{ marginBottom: '0.5rem' }}>Tokens</h4>
        {/* <div className="group-container">
          {['SlideImg1', 'SlideImg2', 'SlideImg1', 'SlideImg2', 'SlideImg1', 'SlideImg2'].map((item, i) => (
            <div key={i}>
              <img src={require(`../../assets/images/${item}.jpg`)} alt={item} />
            </div>
          ))}
        </div> */}
        <CategoryCard />
      </div>
      <div className="hash-tags">
        <h4>HASHTAGS</h4>
        <div className="hash-tag-container">
          {hashTags.length ? (
            hashTags
              .filter((t) => t.posts.length)
              .map((tag) => (
                <div
                  id="tag-container"
                  key={tag.id}
                  style={{ background: tag.bgColor, cursor: 'pointer', border: SelectTag.includes(tag.name) ? '#7c7ef7 3px solid' : 'none' }}
                  onClick={() => handleSlectTags(tag.name)}
                >
                  #{tag.name}
                  {/* <img src={require(`../../assets/images/hash_comment.png`)} alt="hash_comment" /> */}
                  <span>{tag.posts.length}</span>
                </div>
              ))
          ) : loading ? (
            <HorizontalLoader />
          ) : (
            <span id="no-data">No hashTags found.</span>
          )}
        </div>
      </div>
      <div className="calendar">
        <Calendar onChange={onChange} value={value} />
      </div>
    </div>
  );
};

export default HomeRightSide;
