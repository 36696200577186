import React from 'react';
import { Route, Routes } from 'react-router-dom';
import DashBoard from '../pages/dashboard';
import Home from '../pages/home/index';
import MarketPlace from '../pages/marketplace';
import UserProfile from '../pages/user-profile';
import Wallet from '../pages/wallet/index';
import TokenCreation from '../pages/token-creation';
import Community from '../pages/community/index';
import Token from '../pages/wallet/Token';
import CommunityHome from '../pages/community-home/CommunityHome';
import PostDescription from '../utils/widgets/post-description';

const AppRoutes = () => {
  return (
    <>
      <Routes>
        <Route path={`/`} element={<Home />} />
        <Route path={'/dashboard'} element={<DashBoard />} />
        <Route path={`/marketplace`} element={<MarketPlace />} />
        <Route path={`/user-profile`} element={<UserProfile />} />
        <Route path={`/tokens`} element={<Wallet />} />
        <Route path={`/tokens/:tokenId`} element={<Token />} />
        <Route path={`/tokencreation`} element={<TokenCreation />} />
        <Route path={`/communitycreation`} element={<Community />} />
        <Route path={`/community`} element={<CommunityHome />} />
        <Route path={`/post/:id`} element={<PostDescription />} />
      </Routes>
    </>
  );
};

export default AppRoutes;
