import Layout from './sections/Layout'
import '../../assets/scss/community/index.scss'

const Community = () => {
  return (
    <div className='community-container'>
        <Layout/>
    </div>
  )
}

export default Community