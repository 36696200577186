import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Provider } from 'react-redux';
import Cookie from 'js-cookie';
import Layout from './containers/layout';
import store from './state/store';
import WelcomePage from './components/welcome-page';
import ChatProvider from './components/context/ChatProvider';

//STYLES
import './assets/scss/modals.scss';
import './assets/scss/app.scss';
import 'react-toastify/dist/ReactToastify.css';

var CryptoJS = require('crypto-js');

const App = () => {
  let IsAuthenticatedUser = Cookie.get('user');

  const decryptCrypto = (data) => {
    let salt = process.env.REACT_APP_SERCET_KEY ?? '';
    var bytes = CryptoJS.AES.decrypt(data, salt);
    var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return decryptedData;
  };

  const getIsAuth = () => {
    let isAuth = false;

    if (IsAuthenticatedUser) {
      let data = decryptCrypto(IsAuthenticatedUser);
      if (data === 'true') isAuth = true;
    }
    return isAuth;
  };

  return (
    <ChatProvider>
      <Provider store={store}>
        <Router>
          {/* Welcome page is the page that filters users and developers */}
          {/* Layout is the starting page of the website */}
          {getIsAuth() ? <Layout /> : <WelcomePage />}
          <ToastContainer newestOnTop limit={1} hideProgressBar={true} pauseOnFocusLoss={false} closeButton={false} position="bottom-right" />
        </Router>
      </Provider>
    </ChatProvider>
  );
};
export default App;
