import { CREATE_ACCOUNT, UPDATE_ACCOUNT } from '../../config/mutations';
import { GET_ACCOUNT, GET_ALL_ACCOUNTS, GET_ALL_POSTS, GET_POST_COMMENTS, GET_ALL_TOKENS } from '../../config/queries';
import {
  onLogout,
  setCacheId,
  setComments,
  setContacts,
  setCurrentUser,
  setIsEditable,
  setLoading,
  setPostData,
  setUserInfo,
  setRequestingTokens,
  setTokens,
  setTagPostData,
} from './index';
import { Snack } from '../../utils/widgets/Toast';
import { strapiClient } from '../..';

export const checkAccount = (userId, token) => async (dispatch) => {
  const response = await fetch(`${process.env.REACT_APP_STRAPI_URL}/api/checkAccount/${userId}`, {
    method: 'GET',
    headers: {
      authorization: `Bearer ${token}`,
    },
  });
  const res = await response.json();
  if (res?.account.length) {
    const user = res.account[0].user;
    localStorage.setItem('token', token);
    localStorage.setItem('accountId', res.account[0].id);
    dispatch(setCacheId(res.account[0].id));
    dispatch(setCurrentUser({ ...res.account[0], reward: +res.account[0].reward }));
    dispatch(setUserInfo({ id: user.id, username: user.username, email: user.email, confirmed: user.confirmed }));
    dispatch(setLoading(false));
    return true;
  }
};

export const createAccount =
  (data, cb = null) =>
  (dispatch) => {
    const variables = {
      data: data,
    };

    strapiClient
      .request(CREATE_ACCOUNT, variables)
      .then((res) => {
        localStorage.setItem('accountId', res.createAccount.data.id);
        const account = res.createAccount.data.attributes;
        const user = res.createAccount.data.attributes.user.data.attributes;
        const userdata = {
          username: user.username,
          email: user.email,
          confirmed: user.confirmed,
          id: res.createAccount.data.attributes.user.data.id,
        };
        const accountdata = {
          DOB: account.DOB,
          id: res.createAccount.data.id,
          address: account.address,
          countryCode: account.countryCode,
          displayPic: account.displayPic,
          experience: account.experience,
          expertiseIn: account.expertiseIn,
          gender: account.gender,
          isFreelance: account.isFreelance,
          isMentor: account.isMentor,
          name: account.name,
          username: user.username,
          skills: account.skills.data,
          occupation: account.occupation,
          phoneNumber: account.phoneNumber,
          userType: account.userType,
          reward: account.reward,
          bio: account.bio,
          coverPic: account.coverPic,
        };
        dispatch(setCurrentUser(accountdata));
        dispatch(setUserInfo(userdata));
        dispatch(setCacheId(res.createAccount.data.id));
        if (cb) cb(true);
        dispatch(setLoading(false));
      })
      .catch((e) => {
        dispatch(setLoading(false));
        console.log('Create account error--->', e.response);
        Snack('Could not create account!', 2000, 'error');
      });
  };

export const getAccount = (accountId, socket) => (dispatch, getState) => {
  const isGuestUser = getState().user;
  dispatch(listenToken(socket));
  strapiClient
    .request(GET_ACCOUNT, { accountId: accountId })
    .then((res) => {
      const account = res.account.data.attributes;
      const user = res.account.data.attributes.user.data.attributes;
      const userdata = { username: user.username, email: user.email, confirmed: user.confirmed, id: res.account.data.attributes.user.data.id };

      const accountdata = {
        DOB: account.DOB,
        id: res.account.data.id,
        address: account.address,
        countryCode: account.countryCode,
        displayPic: account.displayPic,
        experience: account.experience,
        expertiseIn: account.expertiseIn,
        gender: account.gender,
        isFreelance: account.isFreelance,
        isMentor: account.isMentor,
        name: account.name,
        username: user.username,
        skills: account.skills.data,
        occupation: account.occupation,
        phoneNumber: account.phoneNumber,
        userType: account.userType,
        reward: account.reward,
        bio: account.bio,
        coverPic: account.coverPic,
      };
      dispatch(setCurrentUser(accountdata));
      dispatch(setUserInfo(userdata));
      dispatch(setCacheId(res.account.data.id));
      dispatch(setLoading(false));
    })
    .catch((e) => {
      console.log('Get account error--->', e);
      dispatch(setLoading(false));
      if (e.response.error.status === 401 && !isGuestUser) {
        dispatch(onSignOut(socket));
      }
    });
};

export const listenToken = (socket) => (dispatch) => {
  const token = localStorage.getItem('token');
  if (token) {
    try {
      const decodedJwt = JSON.parse(window.atob(token.split('.')[1]));
      if (decodedJwt.exp * 1000 <= Date.now()) {
        console.log('---->Token expired!');
        dispatch(onSignOut(socket));
      }
    } catch (e) {
      dispatch(onSignOut(socket));
    }
  }
};

export const getAllAccounts = (accountId) => (dispatch) => {
  const isGuestUser = localStorage.getItem('isGuestUser');

  const isGuest = {
    filters: {
      id: {
        ne: null,
      },
    },
  };

  const regUser = {
    filters: {
      id: {
        ne: accountId,
      },
    },
    pagination: {
      start: 0,
      limit: 100,
    },
  };

  strapiClient
    .request(GET_ALL_ACCOUNTS, isGuestUser ? isGuest : regUser)
    .then((res) => {
      if (res.accounts.data.length) {
        const modifiedContacts = res.accounts.data.map((account) => {
          const accountdata = {
            id: account.id,
            DOB: account.attributes.DOB,
            address: account.attributes.address,
            countryCode: account.attributes.countryCode,
            displayPic: account.attributes.displayPic,
            experience: account.attributes.experience,
            expertiseIn: account.attributes.expertiseIn,
            gender: account.attributes.gender,
            isFreelance: account.attributes.isFreelance,
            isMentor: account.attributes.isMentor,
            name: account.attributes.name,
            username: account.attributes.user.data.attributes.username,
            skills: account.attributes.skills.data,
            occupation: account.attributes.occupation,
            phoneNumber: account.attributes.phoneNumber,
            userType: account.attributes.userType,
            reward: account.attributes.reward,
            bio: account.attributes.bio,
            coverPic: account.attributes.coverPic,
          };
          return accountdata;
        });

        dispatch(setContacts(modifiedContacts));
      }
    })
    .catch((e) => {
      console.log('Get all accounts error--->', e.response);
    });
};

export const getAllPosts = (cb) => (dispatch) => {
  const variables = {
    sort: 'createdAt:desc',
    pagination: {
      start: 0,
      limit: 100,
    },
  };
  strapiClient.request(GET_ALL_POSTS, variables).then((res) => {
    dispatch(setPostData(res.posts.data));
    dispatch(setTagPostData(res.posts.data));
    if (cb) cb(res.posts.data.length !== 0);
  });
};

export const onSignOut = (socket) => async (dispatch, getState) => {
  const { currentUser, isGuestUser } = getState().user;

  try {
    const logout = () => {
      window.location.replace('/');
      localStorage.clear();
      dispatch(onLogout());
    };
    if (isGuestUser) logout();
    else {
      socket.current.emit('disconnect-user', currentUser.id);
      socket.current.on('signout', (success) => {
        if (success) logout();
      });
    }
  } catch (e) {
    console.log('Signout error--->', e);
  }
};

export const getPostComments = (postId, setCommentsLoading) => (dispatch) => {
  const variables = {
    sort: 'createdAt:asc',
    filters: {
      post: {
        id: {
          eq: postId,
        },
      },
    },
    pagination: {
      start: 0,
      limit: 100,
    },
  };

  strapiClient
    .request(GET_POST_COMMENTS, variables)
    .then((res) => {
      dispatch(setComments(res.comments.data));
      if (res.comments.data) setCommentsLoading(false);
    })
    .catch((e) => {
      setCommentsLoading(false);
      console.log('Get post comments error-->', e);
    });
};

export const updateAccount =
  (variables, type, cb = null) =>
  (dispatch) => {
    strapiClient
      .request(UPDATE_ACCOUNT, variables)
      .then((res) => {
        if (res.updateAccount.data.id) {
          if (cb) cb(res.updateAccount.data.attributes.coverPic);
          if (type) Snack(`${type} updated successfully`, 2000, 'success');
          dispatch(setIsEditable());
        }
      })
      .catch((e) => {
        console.log('Update account error--->', e);
        if (cb) cb(false);
      });
  };

export const getAllTokens = () => (dispatch) => {
  dispatch(setRequestingTokens(true));
  const variables = {
    pagination: {
      start: 0,
      limit: 100,
    },
  };
  strapiClient
    .request(GET_ALL_TOKENS, variables)
    .then((data) => {
      if (data.tokens.data.length) {
        dispatch(setTokens(data.tokens.data));
      } else dispatch(setRequestingTokens(false));
    })
    .catch((e) => {
      console.log('Getting tokens data error -->', e);
      dispatch(setRequestingTokens(false));
    });
};
