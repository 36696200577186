import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import visa from '../../../assets/images/visa.png';
import mscard from '../../../assets/images/mastercard.png';
import edit from '../../../assets/images/Edit.png';
import { useMediaQuery } from '@mui/material';

function createData(transaction, amount, date, category, Wallet) {
  return { transaction, amount, date, category, Wallet };
}

function createColor(color, bgColor) {
  return {
    color,
    backgroundColor: bgColor,
  };
}

const rows = [
  createData('Kabali', 500, 'Wed 3.00pm', 'Engagement Rewards', 23456),
  createData('Baahubali', 200, 'Tue 6.10pm', 'Time Rewards', 23456),
  createData('Richard Branson', 600, 'Mon 2.20am', 'Fan Card', 23456),
  createData('Rahman Coin', 300, 'Mon 2.20am', 'Creator Coin', 23456),
];

const tableHeadStyle = {
  color: '#757575',
  fontWeight: 600,
  textAlign: 'center',
};

const tableTransactionColor = ['#50AF95', '#F8C56C', '#FE98BF', '#FE98BF'];
const tableCategoryColor = [
  createColor('#42CAF2', 'rgba(82, 202, 237, 0.13)'),
  createColor('#FF6DA5', 'rgba(254, 152, 191, 0.22)'),
  createColor('#FEAD1F', 'rgba(248, 197, 108, 0.37)'),
  createColor('#7C7EF7', '#E2E2EA'),
];
const trancBgColor = (i) => {
  return { backgroundColor: tableTransactionColor[i] };
};

const BasicTable = ({ search }) => {
  const [tableData, setTableData] = useState(rows);

  useEffect(() => {
    if (search !== '') {
      handleTableSearch();
    } else {
      setTableData(rows);
    }
  }, [search]);

  const isLg = useMediaQuery('(min-width: 960px)'); // lg
  const isMd = useMediaQuery('(min-width: 720px)'); // md
  const isSm = useMediaQuery('(min-width: 480px)'); // sm

  const handleTableSearch = async () => {
    let result = await rows.filter((data) => {
      let text = search.toLowerCase().split(' ');
      return text.some((el) => data.transaction.toLowerCase().includes(el));
    });

    setTableData(result);
  };

  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell style={tableHeadStyle}>Transaction</TableCell>
            <TableCell style={tableHeadStyle}>Amount</TableCell>
            {isSm && <TableCell style={tableHeadStyle}>Date</TableCell>}
            {isMd && <TableCell style={tableHeadStyle}>Category</TableCell>}
            {isLg && <TableCell style={tableHeadStyle}>Wallet/Card</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody className="table-body">
          {tableData.length !== 0 &&
            tableData.map((row, index) => (
              <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 }, '.MuiTableCell-root': { whiteSpace: 'nowrap' } }}>
                <TableCell className="transaction" component="th" scope="row">
                  <span style={trancBgColor(index)}>{row.transaction[0].toUpperCase()}</span> {row.transaction}
                </TableCell>
                <TableCell className="amount" align="center">
                  +{row.amount} creato
                </TableCell>
                {isSm && (
                  <TableCell className="date" align="center">
                    {row.date}
                  </TableCell>
                )}
                {isMd && (
                  <TableCell className="category" align="center">
                    <p style={tableCategoryColor[index]}>{row.category}</p>
                  </TableCell>
                )}
                {isLg && (
                  <TableCell className="wallet-card" align="center">
                    <div className="wallet-card-container">
                      <div className="wallet-card-left">
                        <div className="card-design">
                          <img src={`${index === 1 ? mscard : visa}`} alt="card" />
                        </div>
                      </div>
                      <div className="wallet-card-center">
                        <p>Visa {row.Wallet}</p>
                        <p>Expiry 04/2026</p>
                      </div>
                      <div className="wallet-card-right">
                        <img src={edit} alt="icon" />
                      </div>
                    </div>
                  </TableCell>
                )}
              </TableRow>
            ))}
          {!tableData.length && (
            <TableRow>
              <TableCell scope="row" align="center" colSpan={5}>
                No Transaction Found.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default BasicTable;
