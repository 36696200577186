import React, { useState } from 'react';
import Button from '../../../../utils/widgets/Button';
import btnArrowDown from '../../../../assets/images/btn-arrow-down.png';

const FilterDropDown = (props) => {
  const { name, list, disabled,width } = props;

  const [fitlerActive, setFilterActive] = useState(false);

  const handleFilterActive = () => {
    setFilterActive(!fitlerActive);
  };
  
  return (
    <div className="filter-btn-container">
      <Button
        className="btn filter-24-hrs"
        onClick={handleFilterActive}
        sx={{width:width && `${width} !important`}}
        content={
          <>
            {name}{' '}
            <span>
              <img src={btnArrowDown} alt="icon" />
            </span>
          </>
        }
        disabled={disabled}
      />
      {fitlerActive && (
        <ul>
          {list.map((item) => (
            <li key={item}>{item}</li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default FilterDropDown;
