import { toast } from 'react-toastify';

export const Snack = (message, autoClose, type) => {
  const toastOptions = {
    position: 'bottom-right',
    autoClose: autoClose ? autoClose : 4000,
    closeOnClick: false,
    pauseOnHover: true,
    draggable: false,
  };

  if (message) {
    toast[type](message, {
      ...toastOptions,
    });
  }
};
