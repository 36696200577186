import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { io } from 'socket.io-client';
import { strapiClient } from '../..';
import AppRoutes from '../../routes';
import Content from './content';
import SideBar from './side-bar';
import DraggableChat from '../draggableChat';
import { usePreferredState } from '../../utils/customHooks';
import WelcomeModal from '../../utils/widgets/modals/welcome-modal';
import {
  setArraivalMessages,
  setActiveUsers,
  setRequestingCommunities,
  setCommunities,
  setRegisterModal,
  setSearchCommunitiesData,
} from '../../state/user';
import { host } from '../../routes/APIRoutes';
import { ChatState } from '../context/ChatProvider';
import { GET_COMMUNITIES } from '../../config/queries';
import { getAllPosts, getAllTokens } from '../../state/user/actions';

const PostConnect = () => {
  const dispatch = useDispatch();
  const { currentUser, isGuestUser, registerModal, activeUsers, contacts } = usePreferredState('user');
  const { socket } = ChatState();

  const connectionOptions = {
    // 'force new connection': true,
    // reconnectionAttempts: 'Infinity',
    timeout: 10000,
    transports: ['websocket'],
    upgrade: false,
    query: { userId: currentUser.id },
    reconnection: false,
    rejectUnauthorized: false,
  };

  useEffect(() => {
    if (isGuestUser || !currentUser) return;
    socket.current = io(host, connectionOptions);
    socket.current.emit('setup', currentUser);
    socket.current.on('active-users', (data) => {
      dispatch(setActiveUsers(data));
    });
    socket.current.on('receive-msg', (data) => {
      dispatch(setArraivalMessages(data));
    });
    // socket.current.on('connected', () => setSocketConnected(true));
  }, [currentUser]);

  useEffect(() => {
    if (socket.current && contacts.length) {
      const contactsIds = contacts.map((e) => e.id);
      socket.current.emit('contacts', [...contactsIds, currentUser.id.toString()]);
    }
  }, [socket.current, contacts.length]);

  useEffect(() => {
    dispatch(getAllPosts());
    dispatch(getAllTokens());
    dispatch(setRequestingCommunities(true));

    //get all communities
    const variables = {
      pagination: {
        start: 0,
        limit: 100,
      },
    };
    strapiClient
      .request(GET_COMMUNITIES, variables)
      .then((res) => {
        if (res.communities.data.length) {
          dispatch(setCommunities(res.communities.data));
          dispatch(setSearchCommunitiesData(res.communities.data));
        } else dispatch(setRequestingCommunities(false));
      })
      .catch((e) => {
        console.log('Getting community data error -->', e);
        dispatch(setRequestingCommunities(false));
      });
  }, []);

  return (
    <>
      <SideBar />
      <Content>
        <AppRoutes />
        <DraggableChat />
      </Content>
      {registerModal.show && <WelcomeModal open={true} onClose={() => dispatch(setRegisterModal())} />}
    </>
  );
};

export default PostConnect;
