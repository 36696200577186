import React from 'react';

const PostHeadNav = ({ navData, navigateClass = null, setNavigate }) => {
  const handleAnimation = () => {
    setNavigate(null);
  };
  return (
    <>
      {navData.map((item, index) => (
        <p className={`${navigateClass ? navigateClass : ''} ${index === 0 ? 'active' : ''}`} onAnimationEnd={handleAnimation} key={index}>
          {item}
        </p>
      ))}
    </>
  );
};

export default PostHeadNav;
