import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setSelectedProfile, setSelectedCommunity, setIsEditable } from '../../state/user';
import { usePreferredState } from '../../utils/customHooks';
import { HorizontalLoader } from '../../utils/widgets/HorizontalLoader';
import SINGLE_STAR from '../../assets/images/single_star.png';

const HomeRightSide = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [selectedSuggestion, setSelectedSuggestion] = useState('Artists');
  const [showArtist, setShowArtist] = useState({ dp: '', name: '', userId: '' });
  const [showCommunity, setShowCommunity] = useState({ dp: '', name: '', comId: '' });
  const { contacts, communities } = usePreferredState('user');

  const profileImg = showArtist.dp.includes('https') ? showArtist.dp : `data:image/svg+xml;base64,${showArtist.dp}`;

  useEffect(() => {
    if (!contacts.length) return;
    const artists = contacts.map((c) => ({ dp: c.displayPic, name: c.name, userId: c.id }));
    const interval = setInterval(() => setShowArtist(artists[Math.floor(Math.random() * Math.floor(artists.length))]), 4500);

    return () => clearInterval(interval);
  }, [contacts.length]);

  useEffect(() => {
    if (!communities.payload.length) return;
    const allCommunities = communities.payload.map((c) => ({ dp: c.attributes.displayPic, name: c.attributes.name, comId: c.id, payload: c }));
    const interval = setInterval(() => setShowCommunity(allCommunities[Math.floor(Math.random() * Math.floor(allCommunities.length))]), 4500);

    return () => clearInterval(interval);
  }, [communities.payload.length]);

  return (
    <>
      <h3>SUGGESTIONS</h3>
      <div className="suggestion-options">
        <ul>
          {['Artists', 'Community', 'Jobs'].map((item, i) => (
            <li
              key={i}
              id={i === 2 ? 'disabled' : 'enabled'}
              className={`${selectedSuggestion === item ? 'selected' : ''} `}
              onClick={() => (i === 2 ? {} : setSelectedSuggestion(item))}
            >
              {item}
            </li>
          ))}
        </ul>
      </div>
      <div className="suggestion-container">
        {selectedSuggestion === 'Artists' ? (
          showArtist.userId ? (
            <>
              <img
                id="artist"
                src={profileImg}
                alt="artist"
                onClick={() => {
                  dispatch(setSelectedProfile(showArtist.userId));
                  dispatch(setIsEditable());
                }}
              />
              <div className="details-container">
                <span
                  onClick={() => {
                    dispatch(setSelectedProfile(showArtist.userId));
                    dispatch(setIsEditable());
                  }}
                >
                  {showArtist.name}
                </span>
                <span>
                  <img src={SINGLE_STAR} alt="SINGLE_STAR" style={{ height: '0.8125rem' }} /> 12
                </span>
              </div>
            </>
          ) : !contacts.length ? (
            <span id="no-data">No artists found.</span>
          ) : (
            <HorizontalLoader />
          )
        ) : selectedSuggestion === 'Community' ? (
          !communities.isRequesting && showCommunity.comId ? (
            <>
              <img
                id="artist"
                src={showCommunity.dp}
                alt="artist"
                onClick={() => {
                  dispatch(setSelectedCommunity({ id: showCommunity.comId, payload: showCommunity.payload }));
                  dispatch(setIsEditable());
                  navigate('/community');
                }}
              />
              <div className="details-container">
                <span
                  onClick={() => {
                    dispatch(setSelectedCommunity({ id: showCommunity.comId, payload: showCommunity.payload }));
                    dispatch(setIsEditable());
                    navigate('/community');
                  }}
                >
                  {showCommunity.name}
                </span>
              </div>
            </>
          ) : !communities.payload.length ? (
            <span id="no-data">No communities found.</span>
          ) : (
            <HorizontalLoader />
          )
        ) : (
          <span id="detail">{selectedSuggestion}</span>
        )}
        {/* <div className="varunKumar-image-container-right">
          <div>
            <img src={addFriend} alt="addFriend" />
          </div>
          <div>
            <img src={wifi} alt="wifi" />
          </div>
        </div> */}
      </div>
    </>
  );
};

export default HomeRightSide;
