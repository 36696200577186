import SideSection from './sidesection/SideSection';
import TokenDetails from './tokenDetails/TokenDetails';
import '../../assets/scss/wallet/index.scss';
import { useMediaQuery } from '@mui/material';

const Token = () => {
  const matches = useMediaQuery('(min-width: 1200px)'); // xl

  return (
    <div className="wallet-container">
      <TokenDetails />
      {matches && <SideSection />}
    </div>
  );
};

export default Token;
