import React from 'react'

function DateList({dateList,setDate}) {
  const handleDate = (e)=>{
    let item = e.target;
    let parent = item.parentElement;
    for (const list of parent.children) {
       (list !== item && list.classList.contains('active')) && list.classList.remove('active');
    }
    setDate(item.innerHTML);
    item.classList.add('active');
}
  return (
    <ul className='teamScheduleDateList'>
     {dateList.map((item,index)=>(
        <li className={index===0 ? 'active' : null} onClick={handleDate} key={Math.random()}>{item}</li>
      ))}
    </ul>
  )
}

export const TeamScheduleDateList = React.memo(DateList)