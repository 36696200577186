import React, { useState, useEffect } from 'react';
import { Grid, Typography, Checkbox } from '@mui/material';
import { TEAM_ADMIN_USERS } from '../../utils/constants/index';
import delectIcon from '../../assets/images/DeleteIconTeam.svg';
import editIcon from '../../assets/images/EditIconTeam.svg';

const UserAdminTable = () => {
  const style = {
    padding: '1rem 0.5rem',
    color: '#535353',
    fontSize: '13pxisChecked',
  };

  const [checked, setChecked] = useState(false);

  const handleClick = (e) => {
    setChecked(e.target.checked);
  };
  const [users, setUsers] = useState([]);

  useEffect(() => {
    setUsers(TEAM_ADMIN_USERS);
  }, []);

  const handleChange = (e) => {
    const { name, checked } = e.target;
    if (name === 'allSelect') {
      let tempUser = users.map((user) => {
        return { ...user, isChecked: checked };
      });
      setUsers(tempUser);
    } else {
      let tempUser = users.map((user) => (user.name === name ? { ...user, isChecked: checked } : user));
      setUsers(tempUser);
    }
  };

  return (
    <div style={{ border: '1px solid #B0B0B0', borderRadius: '16px', overflow: 'auto' }}>
      <table
        style={{
          width: '100%',
          textAlign: 'left',
          borderCollapse: 'collapse',
          overflowX: 'auto',
        }}
      >
        <thead style={{ borderBottom: '1px solid #B0B0B0', background: '#F9F9F9' }}>
          <tr>
            <th style={style}>
              {/* <Checkbox onClick={handleClick} /> Name */}
              <Checkbox
                type="checkbox"
                className="form-check-input"
                name="allSelect"
                checked={!users.some((user) => user?.isChecked !== true)}
                onChange={handleChange}
              />{' '}
              Name
            </th>
            <th style={style} className="table-cell">
              Date added
            </th>
            <th style={style} className="table-cell">
              Last active
            </th>
            <th style={style}></th>
          </tr>
        </thead>
        <tbody>
          {users.map((user) => (
            <tr key={user.id}>
              <td style={style}>
                <Grid style={{ display: 'flex' }}>
                  <div style={{ display: 'flex', alignItems: 'center', paddingRight: '1rem' }}>
                    {/* <Checkbox onClick={(e) => setChecked(e.target.value)} checked={checked} /> */}

                    <Checkbox type="checkbox" name={user.name} checked={user?.isChecked || false} onChange={handleChange} />

                    <img src={user.img} />
                  </div>
                  <div>
                    <Typography style={{ fontWeight: '600' }}>{user.name} </Typography>
                    <Typography style={{ color: '#535353', fontSize: '0.8125rem' }}>{user.email} </Typography>
                  </div>
                </Grid>
              </td>
              <td style={style} className="table-cell">
                {user.date}{' '}
              </td>
              <td style={style} className="table-cell">
                {user.lastActiveDate}{' '}
              </td>
              <td style={{ ...style, display: 'flex' }}>
                <img src={delectIcon} style={{ paddingRight: '1rem', cursor: 'pointer' }} />
                <img src={editIcon} style={{ cursor: 'pointer' }} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
export default UserAdminTable;
