import React, { useEffect, useRef, useState } from 'react';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ConfettiAnime from '../../assets/images/confetti.gif';
import ConfettiAnime2 from '../../assets/images/confetti2.gif';
import ConfettiAnime3 from '../../assets/images/confetti3.gif';
import partyIcon from '../../assets/images/party_creato.png';
import CongratsAnime from '../../assets/images/congrats.gif';
import { usePreferredState } from '../customHooks';
import { useDispatch } from 'react-redux';
import { setIsAnime } from '../../state/user';
import { RotateRightTwoTone } from '@mui/icons-material';

const ConfettiCont = (item) => {
  var colors = ['#0CD977', '#FF1C1C', '#FF93DE', '#5767ED', '#FFC61C', '#8497B0'];
  var randomColor = colors[Math.floor(Math.random() * colors.length)];
  var randomWidth = Math.floor(Math.random() * Math.max(document.documentElement.clientWidth, window.innerWidth || 0));
  var randomHeight = Math.floor(Math.random() * Math.max(document.documentElement.clientHeight, window.innerHeight || 500));
  const confettiStyle = (item) => {
    return {
      backgroundColor: randomColor,
      transform: `rotate${Math.floor(Math.random() * 360)}`,
      top: `${randomHeight}px`,
      left: `${randomWidth}px`,
      animationDelay: `${Math.floor(Math.random() * 15)}s`,
      transform: `rotate(${Math.floor(Math.random() * 360)}deg)`,
    };
  };
  // the loop
  const children = React.createElement('div', { className: 'confetti', style: confettiStyle() });
  // the div with children inside
  return React.createElement('div', { className: 'confetti-shower-container' }, children);
};

const Animations = () => {
  const { anime } = usePreferredState('user');
  const [count, setCount] = useState([]);
  const dispatch = useDispatch();
  let dummyCount = [];
  for (let i = 0; i < 100; i++) {
    dummyCount.push(i);
  }
  useEffect(() => {
    setCount([...dummyCount]);
    const timer = setInterval(() => {
      dispatch(setIsAnime());
    }, 12000);
    return () => clearInterval(timer);
  }, []);

  return (
    <div className={`creato-added-anime-container ${!anime.open ? 'inactive' : ''}`} onClick={() => dispatch(setIsAnime())}>
      {count.length > 0 && count.map((item) => <ConfettiCont key={item} />)}
      {/* <img className="confetti-bg" src={ConfettiAnime3} alt="confetti" /> */}
      <div className="creato-added-msg">
        <div className="congrats-img">
          <p>🏆</p>
        </div>
        <div className="congrats-msg">
          <p>
            You have earned <b>{anime.value}</b> CREATO..!
          </p>
        </div>
      </div>
    </div>
  );
};

export default Animations;
