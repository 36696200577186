import React, { useState, useRef } from 'react';
import styled from '@emotion/styled';
import { strapiClient } from '../../..';
import Comments from './Comments';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { TextField } from '@mui/material';
import { CREATE_CP_REPLY, UPDATE_COMMUNITY_POST } from '../../../config/mutations';
import { usePreferredState } from '../../../utils/customHooks';
import { Snack } from '../../../utils/widgets/Toast';
import thumbsUp from '../../../assets/images/thumbs-up.png';
import thumbsDown from '../../../assets/images/thumbs-down.png';

const PostContent = (props) => {
  const { data, length, i } = props;
  
  const [reply, setReply] = useState({ show: false, text: '' });
  const { currentUser, isGuestUser } = usePreferredState('user');
  const [comments, setComments] = useState(data.attributes.cp_replies.data);
  const [showComments, setShowComments] = useState(false);
  const [likeCount, setLikeCount] = useState(data.attributes.likeCount);
  const [arrowStatus, setArrowStatus] = useState(-1);
  let like = useRef(data.attributes.likeCount);

  const handleReply = () => {
    if (reply.text.trim().length) {
      const variables = {
        data: {
          account: currentUser.id,
          replyText: reply.text,
          community_post: data.id,
        },
      };
      strapiClient
        .request(CREATE_CP_REPLY, variables)
        .then((data) => {
          setComments([...comments, data.createCpReply.data]);
          setReply({ ...reply, text: '' });
        })
        .catch((e) => {
          console.log('Create cp reply error--->', e);
          Snack('Create cp reply failed!', 2000, 'success');
        });
    }
  };

  const handlePostUpdate = async (status) => {
    const likeInfo = await updateLikeCount(status);
    const variables = {
      updateCommunityPostId: data.id,
      data: {
        likeCount: likeInfo.count,
        likeStatus: likeInfo.status === 1 ? true : false,
      },
    };
    strapiClient
      .request(UPDATE_COMMUNITY_POST, variables)
      .then((data) => {
        console.log('like return', data);
      })
      .catch((e) => {
        console.log('Updating community post error--->', e);
        Snack('Post updation failed!', 2000, 'success');
      });
  };

  const updateLikeCount = (status) => {
    let likeData = { status: arrowStatus, count: likeCount };
    if (status) {
      likeData.status = arrowStatus === 1 ? -1 : 1;
      setArrowStatus((arrowStatus) => (arrowStatus === 1 ? -1 : 1));
      if (arrowStatus !== 1) {
        setLikeCount((likeCount) => likeCount + 1);
        likeData.count = likeCount + 1;
      } else {
        setLikeCount(() => like.current);
        likeData.count = like.current;
      }
    } else {
      likeData.status = arrowStatus === 0 ? -1 : 0;
      setArrowStatus((arrowStatus) => (arrowStatus === 0 ? -1 : 0));
      if (arrowStatus !== 0 && likeCount > 0) {
        setLikeCount((likeCount) => likeCount - 1);
        likeData.count = likeCount - 1;
      } else {
        setLikeCount(() => like.current);
        likeData.count = like.current;
      }
    }
    return likeData;
  };

  return (
    <div className="post-content-container">
      <div className={`post ${length - 1 === i ? 'has-no-border' : ''}`}>
        <div className="head">
          <h1>{data?.attributes?.title}</h1>
          <div className="likes-container">
            <img src={thumbsUp} alt="icon" />
            <div className="likes-per-container">
              <div className="liked-percentage"></div>
            </div>
            <img src={thumbsDown} alt="icon" />
          </div>
        </div>
        <div className="desc">
          <p id="post-content">{data?.attributes?.postBody}</p>
          <div className="comments-foot">
            <div className="foot-left">
              <p onClick={() => setReply({ ...reply, show: !reply.show })} style={{ cursor: !isGuestUser ? 'pointer' : 'not-allowed' }}>
                Reply
              </p>
              <p>Share</p>
              <p>Report</p>
            </div>
            <div className="foot-right">
              {/* <MoreHorizIcon /> */}
              <p onClick={() => setShowComments(!showComments)}>{`${showComments ? 'Hide' : 'Show'} comments`}</p>
            </div>
          </div>
          {reply.show && !isGuestUser && (
            <div className="reply-input-container">
              <NormalTextFields
                style={{ width: '70%' }}
                variant="outlined"
                value={reply.text}
                onChange={(e) => setReply({ ...reply, text: e.target.value })}
                name="occupation"
                onKeyPress={(e) => e.key === 'Enter' && handleReply(data.id)}
                placeholder="Enter your reply"
              />
              {reply.text.length ? <span id="helper-text">press Enter</span> : null}
            </div>
          )}
        </div>
        <div className="post-upvotes">
          <ArrowUpwardIcon
            style={{ color: arrowStatus === 1 ? '#52caed' : '#bdbdbd' }}
            onClick={() => handlePostUpdate(true)}
            disabled={isGuestUser}
          />
          <p style={{ textAlign: 'center' }}>{likeCount}</p>
          <ArrowDownwardIcon
            style={{ color: arrowStatus === 0 ? '#52caed' : '#bdbdbd' }}
            onClick={() => handlePostUpdate(false)}
            disabled={isGuestUser}
          />
        </div>
      </div>
      {comments.length && showComments ? (
        <div className="comments">
          {comments.map((item, i, arr) => (
            <Comments key={item.id} data={item.attributes} length={arr.length} i={i} />
          ))}
        </div>
      ) : null}
    </div>
  );
};

const NormalTextFields = styled(TextField)`
  .MuiOutlinedInput-root {
    background-color: #ffffff;
    border-radius: 12px;
    height: 37px;
    ::placeholder {
      text-align: end;
    }
  }
`;

export default PostContent;
