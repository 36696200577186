import React from 'react';
import { Grid, Typography, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import UserAdminTable from './UserAdminTable';

const Team = () => {
  return (
    <Grid item className="profile-container">
      <ContainerBox>
        <Grid container spacing={1} className="team-container">
          <Grid item md={12} lg={12} sm={12} style={{ padding: '1rem 0' }}>
            <Typography className="header-text">Team members</Typography>
            <Typography className="content">Manage yourteam members and the accounts permissions here.</Typography>
          </Grid>
          <Grid lg={3} md={12} sm={12} xs={12} className="text-container" item>
            <Typography className="content-header">Admin Users</Typography>
            <Typography className="users-text">Admins can add and remove users and manage organization-level settings. </Typography>
          </Grid>
          <Grid lg={9} md={12} sm={12} xs={12} className="table-container" item>
            <UserAdminTable />
          </Grid>
        </Grid>
        <Grid container spacing={1} className="team-container">
          <Grid lg={3} md={12} sm={12} xs={12} className="text-container" item>
            <Typography className="content-header">Account Users</Typography>
            <Typography className="users-text">
              Account users can assess and review risks quesitionnaries,data leaks and identify breached.
            </Typography>
          </Grid>
          <Grid lg={9} md={12} sm={12} xs={12} className="table-container" item>
            <UserAdminTable />
          </Grid>
        </Grid>
      </ContainerBox>
    </Grid>
  );
};

export default Team;

const ContainerBox = styled(Box)`
  border-radius: 12px;
  width: 100%;
  height: 100vh;
`;
