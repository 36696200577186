import React from 'react';
import SideSection from '../wallet/sidesection/SideSection';
import TokenCreationMainSection from './TokenCreationMainSection';
import '../../assets/scss/token-creation/index.scss';
import { useMediaQuery } from '@mui/material';

const TokenCreation = () => {
  const matches = useMediaQuery('(min-width: 1200px)');

  return (
    <div className="token-creation-container">
      <TokenCreationMainSection />
      {matches && <SideSection />}
    </div>
  );
};

export default TokenCreation;
