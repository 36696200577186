import React, { useEffect, useState } from "react";
import { cardData, dateList } from "./data";
import MarketPlaceCard from "./marketPlaceCard";
import { TeamScheduleDateList } from "./dateList";
import SideSection from "./sideSection";
import "./styles/index.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMagnifyingGlass,
  faBell,
  faPlusSquare,
  faGlobe,
  faCalendarDays,
  faSortDown,
  faAngleRight,
  faCaretRight,
} from "@fortawesome/free-solid-svg-icons";

const MarketPlace = () => {
  const [data, setData] = useState(null);
  const [date, setDate] = useState("Mon 17");
  const filterCardData = (date) => {
    return cardData.filter((item) => item.teamDate === date);
  };
  useEffect(() => {
    let res = filterCardData(date);
    setData(res);
  }, [date]);
  return (
    <div className="marketPlace">
      <div className="container">
        <div className="header">
          <div className="searchInputContainer">
            <label htmlFor="searchInput">
              <FontAwesomeIcon icon={faMagnifyingGlass} />
            </label>
            <input
              type="text"
              className="searchInput"
              placeholder="Search"
              id="searchInput"
            />
          </div>
          <div className="userDetails">
            <div className="userIcon">
              <img
                src="https://cdn5.vectorstock.com/i/thumb-large/51/99/icon-of-user-avatar-for-web-site-or-mobile-app-vector-3125199.jpg"
                alt="user"
              />
            </div>
            <p>Arun Kumar</p>
          </div>
          {/* <p>alert icon</p> */}
          <FontAwesomeIcon icon={faBell} className="bellIcon" />
        </div>
        {/* Team Schedule Container */}
        <div className="inputContainer">
          <div className="scheduleInputContainer">
            <FontAwesomeIcon icon={faPlusSquare} className="plussquareicon" />
            <input
              type="text-area"
              placeholder="Write something New..."
              className="textArea"
            />
          </div>
          <div className="scheduleContainer">
            <div className="scheduleOptionContainer">
              <div className="postDropDownContainer">
                <div className="postDropDownIcon">
                  <FontAwesomeIcon icon={faGlobe} />
                </div>
                <div className="postDropDownContent">
                  <p>Post to</p>
                  <p>Community</p>
                </div>
                <div className="dropDownArrowIcon">
                  <FontAwesomeIcon icon={faSortDown} />
                </div>
              </div>
              <div className="scheduleDateContainer">
                <div className="scheduleDateIcon">
                  <FontAwesomeIcon icon={faCalendarDays} />
                </div>
                <div className="scheduleDateContent">
                  <p>Schedulte</p>
                  <p>Jul,20,110.39 PM</p>
                </div>
              </div>
              {/* <button>Schedule</button>
            <button>Schedule</button> */}
            </div>
            <div className="scheduleButtonContainer">
              <button>Schedule</button>
            </div>
          </div>
        </div>
        {/* Team Schedule Details */}
        <div className="teamScheduleContainer">
          <div className="teamScheduleHeadContainer">
            <h4 className="teamHeader">Team Schedule</h4>
          </div>
          <div className="teamScheduleDatePicker">
            <div className="teamScheduleDatePickerIcon">
              <FontAwesomeIcon icon={faAngleRight} className="angleRight" />
            </div>
            <TeamScheduleDateList dateList={dateList} setDate={setDate} />
          </div>
          <div className="teamScheduleCardContainer">
            <div className="teamScheduleCardAlign">
              {data &&
                data.map(
                  (item, index) =>
                    index < 2 && <MarketPlaceCard key={item.id} item={item} />
                )}
            </div>
            <div className="teamScheduleCardAlign">
              {data &&
                data.map(
                  (item, index) =>
                    index > 1 && <MarketPlaceCard key={item.id} item={item} />
                )}
            </div>
          </div>
          <div className="timeScheduleContainer">
            <div className="timelineContainer">
              <div className="timeline">
                <FontAwesomeIcon
                  icon={faCaretRight}
                  style={{ color: "grey" }}
                />
                <div className="timelineTime">
                  <p>10.30</p>
                </div>
                <div className="timelineTask">
                  <p>Film Project</p>
                  <p>Jul,20,10.39 PM</p>
                </div>
              </div>
            </div>
            <div className="timelineTaskContainer">
              <div className="timelineTask aqua">
                <p>Film Project</p>
                <p>Jul,20,10.39 PM</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SideSection />
    </div>
  );
};

export default MarketPlace;
