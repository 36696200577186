import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { strapiClient } from '../../..';
import { CircularProgress } from '@mui/material';
import CreateCommunity from './CreateCommunity';
import CommunityTopics from './CommunityTopics';
import SocialLinksContainer from './SocialLinksContainer';
import CommunityRulesContainer from './CommunityRulesContainer';
import ImpInformation from './ImpInformation';
import { setCommunities, setRequestingCommunities } from '../../../state/user';
import { CREATE_COMMUNITY } from '../../../config/mutations';
import { usePreferredState } from '../../../utils/customHooks';

const CreateContainer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  let [page, setPage] = useState(0);
  let [skipBtn, setSkipBtn] = useState(false);
  const [communityName, setCommunityName] = useState('');
  const [communityDescription, setCommunityDescription] = useState('');
  const [communityImg, setCommunityImg] = useState('');
  const [communityCoverImg, setCommunityCoverImg] = useState('');
  const [communityTopics, setCommunityTopics] = useState([]);
  const [rule, setRule] = useState();
  const [rules, setRules] = useState([]);
  const [url, setUrl] = useState();
  const [urls, setUrls] = useState([]);
  const [communityLoading, setCommunityLoading] = useState(false);
  const { communities } = usePreferredState('user');

  useEffect(() => {
    page > 1 && page < 4 ? setSkipBtn(true) : setSkipBtn(false);
  }, [page]);

  let createTabList = [
    <CreateCommunity
      setName={setCommunityName}
      setDescription={setCommunityDescription}
      setImg={setCommunityImg}
      img={communityImg}
      setCoverImg={setCommunityCoverImg}
      coverImg={communityCoverImg}
    />,
    <CommunityTopics setTopics={setCommunityTopics} topics={communityTopics} />,
    <SocialLinksContainer singleUrl={url} setSingleUrl={setUrl} urls={urls} setUrls={setUrls} />,
    <CommunityRulesContainer singleRule={rule} setSingleRule={setRule} rules={rules} setRules={setRules} />,
    <ImpInformation />,
  ];

  const handlePrevPage = () => {
    setPage((prevPage) => (page = prevPage > 0 ? prevPage - 1 : 0));
  };

  const handleNextPage = () => {
    setPage((prevPage) => (page = prevPage + 1 < createTabList.length ? prevPage + 1 : createTabList.length - 1));
  };

  const handleCreateCommunity = () => {
    setCommunityLoading(true);

    const variables = {
      data: {
        name: communityName,
        purpose: communityDescription,
        rules: rules,
        website: urls,
        displayPic: communityImg,
        coverImg: communityCoverImg,
      },
    };
    strapiClient
      .request(CREATE_COMMUNITY, variables)
      .then((data) => {
        if (data.createCommunity.data.id) {
          dispatch(setCommunities([...communities.payload, data.createCommunity.data]));
          navigate('/community');
          setCommunityLoading(false);
        } else dispatch(setRequestingCommunities(false));
      })
      .catch((e) => {
        console.log('Creating new community error--->', e);
        setCommunityLoading(false);
      });
  };

  return (
    <div className="create-community-container">
      <div className="page-scroll">
        <div></div>
        <p></p>
        <p></p>
        <p></p>
      </div>
      <div className="create-community-content">{createTabList[page]}</div>
      <div className="btn-container">
        <button className={`prev-btn ${page === 4 && 'info-prev-btn'}`} onClick={handlePrevPage}>
          Back
        </button>
        <div className="fwd-btn-container">
          <button className="skip-btn" style={{ visibility: skipBtn ? 'visible' : 'hidden' }} onClick={handleNextPage}>
            Skip
          </button>
          <button
            className="next-btn"
            onClick={() => {
              page === 3 && setRules([...rules, rule]);
              page === 2 && setUrls([...urls, url]);
              page === 4 && handleCreateCommunity();
              handleNextPage();
            }}
          >
            {communityLoading ? <CircularProgress size={14} color="success" /> : page === 4 ? 'Create' : 'Next'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CreateContainer;
