import React from 'react';
import ChatArea from './chat-area';
import LeftSideMenu from './left-side-menu';
import '../../assets/scss/chat/chat.scss';
import { useMediaQuery } from '@mui/material';

const Chat = () => {
  const matches = useMediaQuery('(min-width: 1200px)'); //xl

  return (
    <div className="chat-container">
      {matches && <LeftSideMenu />}
      <ChatArea />
    </div>
  );
};

export default Chat;
