import React from 'react';
import bearIcon from '../../../assets/images/Bear.png';
import blueTick from '../../../assets/images/blue-tick.png';

const ImpInformation = () => {
  return (
    <div className="imp-info-container">
      <div className="imp-info-head">
        <h1>Important Information</h1>
        <div className="com-head-banner">
          <img src={bearIcon} alt="banner" />
        </div>
        <p>
          After pressing <span>"Create community"</span> button below, token will be debited from your wallet and next time you will need atleast
          <span> 3 votes from community</span> leaders to make any changes in community settings.If you want to make changes - go back and do it
          before saving.
        </p>
      </div>
      <div className="imp-info-body">
        <div className="checkbox-container">
          <img src={blueTick} alt="icon" />
        </div>
        <div className="condition">
          <p>
            I understand that after saving of all changes in current community I'll need at least 3 leaders in current community to make changes next
            time.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ImpInformation;
