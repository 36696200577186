import React from 'react';
import MainSection from './mainsection/Mainsection';
import SideSection from './sidesection/SideSection';
import '../../assets/scss/wallet/index.scss';
import useMediaQuery from '@mui/material/useMediaQuery';

const Wallet = () => {
  const matches = useMediaQuery('(min-width: 1200px)'); // xl

  return (
    <div className="wallet-container">
      <MainSection />
      {matches && <SideSection />}
    </div>
  );
};

export default Wallet;
