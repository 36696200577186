import React from 'react';
import MainSection from './mainSection/Mainsection';
import MyProfile from '../my-profile';
import { usePreferredState } from '../../utils/customHooks';
import '../../assets/scss/user-profile/index.scss';

const UserProfile = () => {
  const { isEditable } = usePreferredState('user');

  return <div className="user-profile">{!isEditable ? <MainSection /> : <MyProfile />}</div>;
};

export default UserProfile;
