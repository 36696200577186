import React from 'react';
import { Popper } from '@mui/material';
import "../../assets/scss/welcome-modal/add-item-popup.scss";

const AddItemPopup = ({ onClose, setItem, item, placeholder, btnContent, onClick, anchorEl }) => {
  return (
    <Popper open={Boolean(anchorEl)} anchorEl={anchorEl} sx={{ height: '15rem', boxShadow: '0px 2px 55px rgb(207 206 217 / 28%)' }}>
      <div className="popup-container">
        <button className="closeButton" onClick={() => onClose()}>
          x
        </button>
        <div className="popup-content">
          <input type="text" value={item} className="popup-input" placeholder={placeholder} onChange={(e) => setItem(e.target.value)} />
          <button
            className="popup-button"
            disabled={item === ''}
            onClick={() => {
              onClick();
              setItem('');
              onClose();
            }}
          >
            {btnContent}
          </button>
        </div>
      </div>
    </Popper>
  );
};

export default AddItemPopup;
