import BigNumber from 'bignumber.js';
import web3 from './web3';

export const web3Service = {
  getAccounts: () => {
    return web3.eth.getAccounts();
  },
  getBalance: (address) => {
    return web3.eth.getBalance(address);
  },
  getWei: (payload, curr, provider) => {
    return provider.utils.fromWei(payload, curr);
  },
  getValue: (isEth, currentProvider, amount, decimal) => {
    console.log(amount, decimal, new BigNumber(amount).times(new BigNumber(10).pow(decimal)).toString());
    return isEth ? currentProvider.utils.toWei(amount, 'ether') : new BigNumber(amount).times(new BigNumber(10).pow(decimal)).toString();
  },
};
