import React, { useState } from 'react';
import { HOME_CHAT_CONTENT } from '../../utils/constants';
import CHAT_ICON_1 from '../../assets/images/CHAT_ICON_1.png';
import CHAT_ICON_2 from '../../assets/images/CHAT_ICON_2.png';
import CHAT_ICON_3 from '../../assets/images/CHAT_ICON_3.png';
import CHAT_ICON_4 from '../../assets/images/CHAT_ICON_4.png';
import SEARCH_ICON from '../../assets/images/searchIcon.png';
import CHAT_PIN from '../../assets/images/CHAT_PIN.png';
import '../../assets/scss/home-right-side-chat.scss';

const HomeRightSideChat = ({ setShowChat, setUserData }) => {
  
  const [chatArea, setChatArea] = useState(true);
  const [searchedName, setSearchedName] = useState('');
  // const [userData, setUserData] =useState();

  const getInputValue = (e) => {
    setSearchedName(e.target.value.toLocaleLowerCase());
    console.log(searchedName);
  };

  return (
    <div className="home-right-side-chat-container">
      <div className="chat-heading">
        <p>Chats</p>
        <div className="chat-icons">
          <img src={CHAT_ICON_1} alt="CHAT_ICON_1" />
          <img src={CHAT_ICON_2} alt="CHAT_ICON_2" onClick={() => setChatArea(!chatArea)} />
          <img src={CHAT_ICON_3} alt="CHAT_ICON_3" />
          <img src={CHAT_ICON_4} alt="CHAT_ICON_4" />
        </div>
      </div>

      {chatArea && (
        <>
          <div className=" chat-search-input">
            <div className="chat-input-with-icon">
              <img src={SEARCH_ICON} alt="SEARCH_ICON" />
              <input type="text" placeholder="Search" onChange={getInputValue} />
            </div>
          </div>
          <div className="chat-content">
            {HOME_CHAT_CONTENT.filter((user) => user.name.toLocaleLowerCase().includes(searchedName)).map((item) => (
              <div
                className="chat-border"
                key={item.id}
              >
                <div className="chat-image">
                  <img src={require(`../../assets/images/${item.src}.png`)} alt={item.src} />
                </div>
                <div className="chat-message">
                  <h3>
                    {item.name}
                    {item.pin && <img src={CHAT_PIN} alt="CHAT_PIN" />}
                  </h3>
                  <p>
                    {item.message}
                    <b>·</b>

                    {item.week}
                  </p>
                </div>
                {item.seen && <img className="chat-seen" src={require(`../../assets/images/${item.seen}.png`)} alt={item.seen} />}
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default HomeRightSideChat;
