import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import styled from '@emotion/styled';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { TextField } from '@mui/material';
import { usePreferredState } from '../../../utils/customHooks';
import { setSelectedProfile } from '../../../state/user';

const Comments = (props) => {
  const { data, length, i } = props;
  
  const profileImg = data.account.data.attributes.displayPic.includes('https')
    ? data.account.data.attributes.displayPic
    : `data:image/svg+xml;base64,${data.account.data.attributes.displayPic}`;

  const [reply, setReply] = useState({ show: false, text: '' });
  const { isGuestUser } = usePreferredState('user');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <div className={`thread-container ${length - 1 === i ? 'has-border' : ''}`}>
      <div className="comments-left">
        <img
          src={profileImg}
          alt="icon"
          onClick={() => {
            dispatch(setSelectedProfile(data.account.data.id));
            navigate('/');
          }}
          style={{ cursor: 'pointer' }}
        />
        <div className="upvotes-container">
          <ArrowUpwardIcon className="up-arrow" />
          <p>{data.likeCount}</p>
          <ArrowDownwardIcon className="down-arrow" />
        </div>
      </div>
      <div className="comments-right">
        <div className="comments-head">
          <div className="profile-details">
            <p>{data.account.data.attributes.name}</p>
          </div>
          <div className="time">
            <p>{moment(data.createdAt).format('MMM D yyyy')}</p>
          </div>
        </div>
        <div className="comments-body">
          <p>{data.replyText}</p>
        </div>
        <div className="comments-foot">
          <div className="foot-left">
            {/* onClick={() => setReply({ ...reply, show: !reply.show })} */}
            <p>Reply</p>
            <p>Share</p>
            <p>Report</p>
          </div>
          <div className="foot-right">
            {/* <MoreHorizIcon /> */}
            <p>Show comments</p>
          </div>
        </div>
        {reply.show && !isGuestUser && (
          <div className="reply-input-container">
            <NormalTextFields
              style={{ width: '70%' }}
              variant="outlined"
              value={reply.text}
              onChange={(e) => setReply({ ...reply, text: e.target.value })}
              name="occupation"
              // onKeyPress={(e) => e.key === 'Enter' && handleReply(data.id)}
              placeholder="Enter your reply"
            />
            {reply.text.length ? <span id="helper-text">press Enter</span> : null}
          </div>
        )}
      </div>
    </div>
  );
};

const NormalTextFields = styled(TextField)`
  .MuiOutlinedInput-root {
    background-color: #ffffff;
    border-radius: 12px;
    height: 37px;
    ::placeholder {
      text-align: end;
    }
  }
`;

export default Comments;
