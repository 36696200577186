import React, { useEffect, useState } from 'react';
import moment from 'moment';
import axios from 'axios';
import { usePreferredState } from '../../../utils/customHooks';
import { COMMUNITY_ICONS, FOLLOWERS_LIST, GROUPS, TAG_COLORS } from '../../../utils/constants';
import STAR from '../../../assets/images/STAR.png';
import EMOJI_LEVELS from '../../../assets/images/EMOJI_LEVELS.png';
import TROPHY_ICON from '../../../assets/images/TROPHY_ICON.png';
import TROPHY_MEDAL from '../../../assets/images/TROPHY_MEDAL.png';
import CHALLENGES_LOADING from '../../../assets/images/CHALLENGES_LOADING.png';
import calender from '../../../assets/images/CALENDER.svg';

const Community = ({ profileInfo }) => {
  const { isGuestUser, selectedProfile } = usePreferredState('user');
  const [hashTags, setHashTags] = useState({ loading: false, payload: [] });
  const profileImg = profileInfo.displayPic.includes('https') ? profileInfo.displayPic : `data:image/svg+xml;base64,${profileInfo?.displayPic}`;

  useEffect(() => {
    if (selectedProfile) {
      setHashTags({ ...hashTags, loading: true });
      const getAllHashTags = async () => {
        try {
          const hashTags = await axios.get(`${process.env.REACT_APP_STRAPI_URL}/api/account/hashtags/${selectedProfile}`, {
            headers: {
              authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          });
          if (hashTags?.data?.hashtags.length) {
            setHashTags({ loading: false, payload: hashTags.data.hashtags });
          } else setHashTags({ loading: false, payload: [] });
        } catch (e) {
          setHashTags({ loading: false, payload: [] });
          console.log('Getting hashtags error --->', e);
        }
      };
      getAllHashTags();
    }
  }, [selectedProfile]);

  return (
    <div className="community-menu-main-container">
      <div className="community-menu-container">
        <div className="user-icon">
          <img src={profileImg} alt="user-icon" />
        </div>
        <div className="community-menu-container-top">
          <div className="community-menu-container-top1">
            <div className="dob">
              <img src={calender} alt="dob" />
              <span>{moment(profileInfo.DOB).format('MMM D yyyy')}</span>
            </div>
            <div className="profile-quality">
              <h2>{profileInfo.name}</h2>
              <span className="star-rating-position">
                <img src={STAR} alt="STAR" className="star" /> <p className="star-rating">4.5</p>
              </span>
              <div className="profile-loader">
                <div className="profile-loader-top">
                  <span className="profile-loader-top1">LEVEL 12</span>
                  <span className="profile-loader-top2">500/400</span>
                </div>
                <div className="profile-loader-bottom">
                  45%
                  <span className="loading-box">
                    <span className="loaded-percent"></span>
                  </span>
                </div>
              </div>
            </div>
            <p className="tag-name">@{isGuestUser ? 'Guest' : profileInfo.username}</p>
            <p className="talent">{profileInfo.occupation}</p>
            <div className="followers">
              {FOLLOWERS_LIST.map((item) => (
                <div key={item.id} className="followers-image-border" style={{ index: item.id }}>
                  <img src={require(`../../../assets/images/${item.src}.jpg`)} alt={item.src} />
                </div>
              ))}{' '}
              <p className="remaining-followers"> + 50 followers</p>
            </div>
            <div className="bio-tag-skills">
              <div className="bio-tag-skills1">
                <p>Bio</p>
                <div>
                  {profileInfo.bio
                    ? profileInfo.bio
                    : 'I am a freelance photographer & relish capturing moments, emotions and beauty in every little thing that surrounds me.'}
                </div>
              </div>
              <div className="bio-tag-skills2">
                <p>Tags</p>
                {hashTags.loading ? (
                  <span id="no-data">Loading...</span>
                ) : (
                  <div id="hash-tags">
                    {hashTags.payload.length ? (
                      hashTags.payload.map((tag) => {
                        const random = 'hsl(' + 360 * Math.random() + ',' + (25 + 70 * Math.random()) + '%,' + (85 + 10 * Math.random()) + '%)';
                        const bgColor = TAG_COLORS.includes(random) ? 'hsl(212, 87%, 58%)' : random;
                        return (
                          <div key={tag.id} style={{ background: bgColor ,color:"black"}} className="tag-buttons">
                            {tag.hashtag}
                          </div>
                        );
                      })
                    ) : (
                      <span id="no-data">No tags found.</span>
                    )}
                  </div>
                )}
              </div>
              {/* <div className="bio-tag-skills3">
                <p>Skills</p>
                <div>
                  {profileInfo?.skills?.length ? (
                    profileInfo.skills.map((s) => (
                      <div key={s.id} className="skill-buttons" style={{ backgroundColor: s.attributes.colorHex }}>
                        {s.attributes.skill}
                      </div>
                    ))
                  ) : (
                    <span id="no-data">No skills found.</span>
                  )}
                </div>
              </div> */}
            </div>
          </div>
          <div className="community-menu-container-top2">
            <h1>65.2</h1>
            <p>Impact score</p>
            <div className="impact-factor-border">
              <p>impact factor</p>
              <span>i</span>
            </div>
          </div>
          <div className="community-menu-container-top3">
            <img src={EMOJI_LEVELS} alt="EMOJI_LEVELS" />
            <p className="mood-score">Mood Score</p>
            <div className="impact-factor-border">
              <p>mood boost</p>
              <span>i</span>
            </div>
          </div>
          <div className="community-menu-container-top4">
            <img src={CHALLENGES_LOADING} alt="CHALLENGES_LOADING" />
            <p className="challenges">Challenges</p>
          </div>
        </div>
        <div className="community-menu-container-bottom">
          {COMMUNITY_ICONS.map((item) => (
            <div
              className="community-icons-border"
              key={item.id}
              style={{ backgroundColor: item.background, cursor: isGuestUser ? 'not-allowed' : 'pointer', gap: '5px' }}
            >
              <img src={require(`../../../assets/images/${item.src}.png`)} alt={item.src} />
              {item.id == 6 ? <span>{profileInfo.name}</span> : ''}
            </div>
          ))}
        </div>
      </div>
      <div className="community-menu-hall-of-fame">
        <h2>Hall of Fame</h2>
        <img src={TROPHY_ICON} alt="TROPHY_ICON" />
        <img src={TROPHY_MEDAL} alt="TROPHY_MEDAL" />
        <img src={TROPHY_ICON} alt="TROPHY_ICON" />
        <img src={TROPHY_ICON} alt="TROPHY_ICON" />
        <img src={TROPHY_ICON} alt="TROPHY_ICON" />
        <p>View All</p>
      </div>
      <div className="community-menu-groups">
        <h2>Groups</h2>
        {GROUPS.map((item) => (
          <img key={item.id} src={require(`../../../assets/images/${item.label}.png`)} alt={item.label} />
        ))}
        <p>View All</p>
      </div>
    </div>
  );
};

export default Community;
