import React from "react";
import CHECK from "../../../assets/images/check.png";
import WHITECHECK from "../../../assets/images/white_check.png";

const CheckBox = ({ isChecked, onClick,style,style2 }) => {
  return (
    <div className={`check-box ${isChecked ? 'checked' : ''}`} onClick={onClick} style={{ ...style }}>
      <img id="check" src={isChecked ? WHITECHECK : CHECK} alt="check" style={{ ...style2 }} />
    </div>
  );
};

export default CheckBox;
