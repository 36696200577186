import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { strapiClient } from '../../..';
import { LOGIN } from '../../../config/mutations';
import { Snack } from '../../../utils/widgets/Toast';
import { setCurrentUser, setLoading } from '../../../state/user';
import { checkAccount } from '../../../state/user/actions';
import NEXTSTEP from '../../../assets/images/next step.svg';
import BACKARROW from '../../../assets/images/back-arrow.svg';
import PASSWORD_VISIBLE from '../../../assets/images/PASSWORD_VISIBLE.png';

const SignInPage = ({ previousStep, setForgotPage }) => {
  const dispatch = useDispatch();
  const [password, setPassword] = useState(false);
  const [loginInfo, setLoginInfo] = useState({});

  const formSchema = Yup.object().shape({
    email: Yup.string().email().required('Enter the name'),
    password: Yup.string().required('Password is required'),
    acceptterms: Yup.bool().oneOf([true], 'Accept UserAgreement to Login'),
  });

  const validationOpt = { resolver: yupResolver(formSchema), mode: 'onTouched' };

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm(validationOpt);

  const handleLogin = async (data) => {
    if (data?.email) {
      const { email, password } = data;

      const variables = {
        input: {
          identifier: email,
          password: password,
          provider: 'local',
        },
      };
      dispatch(setLoading(true));
      strapiClient
        .request(LOGIN, variables)
        .then((res) => {
          localStorage.setItem('token', res.login.jwt);
          strapiClient.setHeader('authorization', `Bearer ${res.login.jwt}`);
          dispatch(checkAccount(res.login.user.id, res.login.jwt)).then((success) => {
            if (success) Snack('Logged in successfully!', 2000, 'success');
          });
        })
        .catch((e) => {
          console.log('Login error--->', e);
          dispatch(setLoading(false));
          if (e.response?.errors) {
            Snack(e.response.errors[0].message, 2000, 'error');
          } else if (e.response?.error) {
            Snack(e.response.error.message, 2000, 'error');
          }
        });
    }
  };

  const handleRegister = async (data) => {
    setLoginInfo({ ...data });
    handleLogin(data);
  };

  return (
    <form className="sign-in-page-header" onSubmit={handleSubmit(handleRegister)}>
      <div className="sign-in-username-container">
        <label>Email</label>
        <div className="toggle-button sign-in-username-input">
          <input type="email" {...register('email')} />
          <p className="error-text2">{errors.email?.message}</p>
        </div>
      </div>
      <div className="sign-in-password-container">
        <label>Password</label>
        <div className="toggle-button sign-in-password-input">
          <input type={password ? 'text' : 'password'} {...register('password')} />
          <img src={PASSWORD_VISIBLE} alt="PASSWORD_VISIBLE" onClick={() => setPassword(!password)} />
          <p className="error-text2">{errors.password?.message}</p>
        </div>
      </div>
      <div className="sign-in-user-agreement">
        <div>
          <label className="checkbox-container">
            <input type="checkbox" {...register('acceptterms')} />
            <span className="checkmark"></span>
          </label>
        </div>
        <p id="user-agreement-p">
          I certify that I'm 18 years of age or older and agree to the{' '}
          <span>
            <b> User Agreement and Privacy Policy</b>
          </span>
        </p>
        <p className="error-text-accept-terms">{errors.acceptterms?.message}</p>
      </div>
      <div className="sign-in-note">
        <p id="sign-in-note-p">
          Note: Your username is a user-friendly way to identify you to other users on Creative Network, similar to a Twitter handle.{' '}
        </p>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <p id="sign-in-note-p">
            Example: <span>Banksy82</span>
          </p>

          <p>
            <span style={{ cursor: 'pointer' }} onClick={() => setForgotPage(true)}>
              Forgot Password?
            </span>
          </p>
        </div>
      </div>
      <div className="button-field">
        <div className="back-arrow" onClick={previousStep}>
          <img src={BACKARROW} alt="BACKARROW" />
        </div>

        <button
          type="submit"
          // disabled={isDirty}
          style={{ cursor: isDirty ? 'pointer' : 'not-allowed' }}
        >
          Login &nbsp;
          <img src={NEXTSTEP} alt="NEXTSTEP" />
        </button>
      </div>
    </form>
  );
};

export default SignInPage;
