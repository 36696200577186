import React, { useState } from 'react';
import PostHeadNav from './PostHeadNav';
import navLeftArrow from '../../../assets/images/nav-left-arrow.png';
import navRightArrow from '../../../assets/images/nav-right-arrow.png';
import { Menu } from '../data';
import 'animate.css';

const PostSection = () => {
  const [navData, setNavData] = useState(Menu);
  const [navigate, setNavigate] = useState(null);

  let navigateClass =
    navigate === 'left' ? 'animate__animated animate__fadeInRight' : navigate === 'right' ? 'animate__animated animate__fadeInLeft' : null;

  const handleRight = () => {
    let res = navData.splice(-1, 1);
    setNavigate('right');
    setNavData([...res, ...navData]);
  };

  const handleLeft = () => {
    let res = navData.splice(0, 1);
    setNavigate('left');
    setNavData([...navData, ...res]);
  };

  return (
    <div className="post-section-container">
      <div className="post-section-head">
        <div className="nav-left-arrow">
          <img onClick={handleLeft} src={navLeftArrow} alt="left-icon" />
        </div>
        <PostHeadNav navData={navData} navigateClass={navigateClass} setNavigate={setNavigate} />
        <div className="nav-right-arrow">
          <img onClick={handleRight} src={navRightArrow} alt="right-icon" />
        </div>
      </div>
      {/* <div className="add-post-section">
        <div className="add-post-left">
          <img src={naveenMd} alt="icon" />
        </div>
        <div className="add-post-center">
          <input placeholder="What's New?" />
        </div>
        <div className="add-post-right">
          <img src={picture} alt="icon" />
          <img src={edit} alt="icon" />
          <img src={textCircle} alt="icon" />
        </div>
      </div> */}
      {/* <div className="post-details-section">
        <div className="post-details-head">
          <div className="post-details-left">
            <button>Sort</button>
            <button>
              Popular{' '}
              <span>
                <img src={btnArrowDown} alt="icon" />
              </span>
            </button>
            <button>
              Post 24 hrs{' '}
              <span>
                <img src={btnArrowDown} alt="icon" />
              </span>
            </button>
          </div>
          <div className="post-details-right">
            <p>Filter by</p>
            <button>
              Impact Score{' '}
              <span>
                <img src={btnArrowDown} alt="icon" />
              </span>
            </button>
          </div>
        </div>
        {POSTS.map((post, i) => (
          <PostCard key={i} post={post} />
        ))}
      </div> */}
    </div>
  );
};

export default PostSection;
