import { gql } from 'graphql-request';

export const REGISTER_USER = gql`
  mutation Register($input: UsersPermissionsRegisterInput!) {
    register(input: $input) {
      jwt
      user {
        id
        username
        email
        confirmed
        blocked
      }
    }
  }
`;

export const LOGIN = gql`
  mutation Login($input: UsersPermissionsLoginInput!) {
    login(input: $input) {
      jwt
      user {
        id
        username
        email
        confirmed
      }
    }
  }
`;

export const CREATE_ACCOUNT = gql`
  mutation CreateAccount($data: AccountInput!) {
    createAccount(data: $data) {
      data {
        id
        attributes {
          address
          name
          phoneNumber
          countryCode
          userType
          gender
          occupation
          DOB
          expertiseIn
          experience
          isMentor
          isFreelance
          displayPic
          reward
          bio
          coverPic
          user {
            data {
              id
              attributes {
                username
                email
                provider
                confirmed
                blocked
              }
            }
          }
          skills {
            data {
              id
              attributes {
                colorHex
                skill
                createdAt
              }
            }
          }
        }
      }
    }
  }
`;

export const CREATE_POST = gql`
  mutation Mutation($data: PostInput!) {
    createPost(data: $data) {
      data {
        id
        attributes {
          description
          mediaUrl
          likeCount
          hashtags {
            data {
              id
              attributes {
                hashtag
              }
            }
          }
          owner {
            data {
              id
              attributes {
                name
              }
            }
          }
        }
      }
    }
  }
`;

export const CREATE_HASH_TAGS = gql`
  mutation CreateHashTag($data: HashtagInput!) {
    createHashtag(data: $data) {
      data {
        id
        attributes {
          hashtag
        }
      }
    }
  }
`;

export const CREATE_TOKEN = gql`
  mutation CreateToken($data: TokenInput!) {
    createToken(data: $data) {
      data {
        id
        attributes {
          name
          symbol
          about
          description
          tokenPic
          erc20Address
          creator {
            data {
              id
              attributes {
                name
              }
            }
          }
        }
      }
    }
  }
`;

export const CREATE_COMMENT = gql`
  mutation CREATE_COMMENT($data: CommentInput!) {
    createComment(data: $data) {
      data {
        id
        attributes {
          comment
          post {
            data {
              id
            }
          }
          user {
            data {
              id
              attributes {
                name
                displayPic
              }
            }
          }
          createdAt
        }
      }
    }
  }
`;

export const CREATE_COMMUNITY = gql`
  mutation CreateCommunity($data: CommunityInput!) {
    createCommunity(data: $data) {
      data {
        id
        attributes {
          name
          purpose
          rules {
            id
            ruleIdentity
            description
            name
          }
          website {
            websiteType
            website
            id
          }
          displayPic
          coverImg
          community_posts {
            data {
              id
              attributes {
                title
                postBody
                postType
                likeCount
                cp_replies {
                  data {
                    id
                    attributes {
                      account {
                        data {
                          id
                          attributes {
                            address
                            name
                            displayPic
                          }
                        }
                      }
                      replyText
                      likeCount
                      createdAt
                      cp_replies {
                        data {
                          id
                          attributes {
                            account {
                              data {
                                id
                                attributes {
                                  address
                                  name
                                  displayPic
                                }
                              }
                            }
                            likeCount
                            createdAt
                            replyText
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const UPDATE_ACCOUNT = gql`
  mutation addRewards($updateAccountId: ID!, $data: AccountInput!) {
    updateAccount(id: $updateAccountId, data: $data) {
      data {
        id
        attributes {
          address
          name
          reward
          coverPic
        }
      }
    }
  }
`;

export const CREATE_COMMUNITY_POST = gql`
  mutation CreateCommunityPost($data: CommunityPostInput!) {
    createCommunityPost(data: $data) {
      data {
        id
        attributes {
          postType
          title
          postBody
          isNSFW
          community {
            data {
              id
              attributes {
                community_posts {
                  data {
                    id
                    attributes {
                      title
                      postBody
                      postType
                      likeCount
                      cp_replies {
                        data {
                          id
                          attributes {
                            account {
                              data {
                                id
                                attributes {
                                  address
                                  name
                                  displayPic
                                }
                              }
                            }
                            replyText
                            likeCount
                            createdAt
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const CREATE_CP_REPLY = gql`
  mutation CreateCpReply($data: CpReplyInput!) {
    createCpReply(data: $data) {
      data {
        id
        attributes {
          replyText
          likeCount
          community_post {
            data {
              id
            }
          }
          createdAt
          account {
            data {
              id
              attributes {
                address
                name
                displayPic
              }
            }
          }
        }
      }
    }
  }
`;

export const UPDATE_COMMUNITY_POST = gql`
  mutation UpdateCommunityPost($updateCommunityPostId: ID!, $data: CommunityPostInput!) {
    updateCommunityPost(id: $updateCommunityPostId, data: $data) {
      data {
        id
        attributes {
          likeCount
          likeStatus
        }
      }
    }
  }
`;
