import React, { useState } from 'react';
import { Grid, IconButton, Typography } from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import ReplyIcon from '@mui/icons-material/Reply';
import StarIcon from '@mui/icons-material/Star';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import Button from '../Button';
import { updateAccount } from '../../../state/user/actions';
import { likePost, setIsAnime } from '../../../state/user';
import { usePreferredState } from '../../customHooks';
import Reactions from '../Reactions';
import { EMOJIS } from '../../constants';

const Footer = ({ post, isLiked, setSharePost }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [reactionEl, setReactionEl] = useState(null);
  const [reactions, setReactions] = useState(null);

  const { currentUser, isGuestUser } = usePreferredState('user');

  const handleLikes = async (post) => {
    axios
      .post(
        `${process.env.REACT_APP_STRAPI_URL}/api/likePost/${post.id}`,
        {
          accountId: currentUser.id,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${localStorage.getItem('token')} `,
          },
        },
      )
      .then((res) => {
        if (res.data.data.id) {
          let rewards = currentUser.reward;
          rewards = res.data.data.likeCount > post.attributes.likeCount ? rewards + 10 : rewards - 10;

          const rewardVar = {
            updateAccountId: currentUser.id,
            data: {
              reward: rewards,
            },
          };
          if (res.data.data.likeCount > post.attributes.likeCount) dispatch(setIsAnime({ value: '10', open: true }));
          dispatch(
            likePost({
              postId: res.data.data.id,
              liked: res.data.data.likeCount,
              user: { id: currentUser.id, attributes: { name: currentUser.name } },
            }),
          );
          dispatch(updateAccount(rewardVar));
        }
      });
  };

  const handleEmoji = (emoji) => {
    let result = EMOJIS.filter((item) => {
      return item.label === emoji;
    });
    setReactions(result[0]);
  };

  return (
    <>
      <Grid className="post-card-footer">
        <Grid className="icon-grp">
          <IconButton className="fav-icon-button" onClick={() => handleLikes(post)} disabled={isGuestUser}>
            {isLiked ? <FavoriteIcon className="fav-icon" style={{ color: '#e65a90' }} /> : <FavoriteIcon className="fav-icon" />}
          </IconButton>
          <IconButton
            className="chat-icon-button"
            disabled={isGuestUser}
            onClick={() => {
              navigate(`/post/${post.id}`);
            }}
          >
            <ChatBubbleIcon className="chat-icon" />
          </IconButton>
          <IconButton className="forward-icon-button" disabled={isGuestUser} onClick={() => setSharePost({ postId: post.id, show: true })}>
            <ReplyIcon className="forward-icon" />
          </IconButton>
        </Grid>
        <Grid className="btn-grp">
          <Grid
            className="reaction-count"
            disabled={isGuestUser}
            onClick={(e) => setReactionEl(reactionEl ? null : e.currentTarget)}
            style={{ cursor: !isGuestUser ? 'pointer' : 'not-allowed' }}
          >
            {reactions === null ? (
              <StarIcon className="star-icon"  />
            ) : (
              <div className="emoji-reaction">
                <p>{reactions.emoji}</p>
              </div>
            )}
            <Typography className="count">{post.attributes.likedUsers.data.length}</Typography>
            {!isGuestUser && <Reactions handleEmoji={handleEmoji} anchorEl={reactionEl} isGuestUser={isGuestUser} />}
          </Grid>
          <Button content="STAKE" disabled={isGuestUser} />
          <IconButton className="money-icon-container" disabled={isGuestUser}>
            <AttachMoneyIcon className="money-icon" />
          </IconButton>
        </Grid>
      </Grid>
    </>
  );
};

export default Footer;
