import english1 from '../../assets/images/english1.png';
import english2 from '../../assets/images/english2.png';
import english3 from '../../assets/images/english3.png';
import english4 from '../../assets/images/english4.png';
import english5 from '../../assets/images/english5.png';

import behance from '../../assets/images/Behance.png';
import dribble from '../../assets/images/Dribbble.png';
import github from '../../assets/images/Github.png';
import webLink from '../../assets/images/web-link.png';
import facebook from '../../assets/images/facebook.png';

export const AfterTokenData = [
  {
    title: '1000 Creato tokens',
    desc: 'Will be burned for public goods',
  },
  {
    title: '367 961.112 Creato tokens',
    desc: 'Will be burned for public goods',
  },
  {
    title: '3415 329.619 Creato tokens',
    desc: 'Will be burned for public goods',
  },
];

export const CommunityTopicsHeadData = {
  title: 'Select Community Topics',
  desc: 'Topics are needed for recommendation service. In that way, for users interested in your community will be much easier to find it in a list.',
  img:true
};

export const SocialLinksHeadData = {
  title: 'Add social links',
  desc: 'You can add a social links for your community, your personal website or any other link you think will give more info about community',
  img: true,
};

export const CommunityRulesHeadData = {
  title: 'Add community rules',
  desc: "We've added some default rules for your community.You can edit,remove or add new rules for your community.",
  img: false,
};

export const SocialLinkProviderData = [
  {
    name:'Behance',
    img:behance
  },
  {
    name:'Dribble',
    img:dribble
  },
  {
    name:'Github',
    img:github
  },
  {
    name:'Facebook',
    img:facebook
  },
  {
    name:'Website',
    img:webLink
  }
]

export const LanguageListData = [
  {
    id: Math.random(),
    lang: 'English',
    img: english1,
  },
  {
    id: Math.random(),
    lang: 'English',
    img: english2,
  },
  {
    id: Math.random(),
    lang: 'English',
    img: english3,
  },
  {
    id: Math.random(),
    lang: 'English',
    img: english4,
  },
  {
    id: Math.random(),
    lang: 'English',
    img: english1,
  },
  {
    id: Math.random(),
    lang: 'English',
    img: english5,
  },
  {
    id: Math.random(),
    lang: 'Tamil',
    img: english1,
  },
  {
    id: Math.random(),
    lang: 'Telugu',
    img: english2,
  },
  {
    id: Math.random(),
    lang: 'Malayalam',
    img: english3,
  },
  {
    id: Math.random(),
    lang: 'Hindi',
    img: english4,
  },
  {
    id: Math.random(),
    lang: 'Urudu',
    img: english5,
  },
  {
    id: Math.random(),
    lang: 'Arabic',
    img: english5,
  },
];
