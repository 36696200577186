import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { onSignOut } from '../../../state/user/actions';
import { setSelectedCommunity, setSelectedProfile } from '../../../state/user';
import LOGO from '../../../assets/images/Logo.jpg';
import LOGOUT from '../../../assets/images/logout.jpg';
import '../../../assets/scss/side-bar.scss';
import { ChatState } from '../../context/ChatProvider';
import { usePreferredState } from '../../../utils/customHooks';

const SideBar = ({ className }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const tokenId = pathname.split('').splice(8).join('');

  const [active, setActive] = useState(-1);
  const { socket } = ChatState();

  useEffect(() => {
    setActive(
      pathname === '/tokens'
        ? 2
        : pathname === `/tokens/${tokenId}`
        ? 2
        : pathname === '/tokencreation'
        ? 2
        : pathname === '/dashboard'
        ? 3
        : pathname === '/community'
        ? 4
        : -1,
    );
  }, [pathname]);

  const handleClick = (id) => {
    id === 2 && navigate('/tokens');
    id === 3 && navigate('/dashboard');
    id === 4 && navigate('/community');
    if (id !== 2 && id !== 3 && id !== 4) {
      return;
    }
    setActive(id);
  };

  const handleSignout = () => dispatch(onSignOut(socket));

  return (
    <div className={` ${className ? className : 'side-bar'}`}>
      <div className="container-margin" id="logo">
        <img
          className="main-logo"
          src={LOGO}
          alt="logo-icon"
          onClick={() => {
            setActive(-1);
            navigate('/');
            dispatch(setSelectedCommunity());
            dispatch(setSelectedProfile());
          }}
        />
      </div>
      <div className="middle-icons container-margin">
        <ul>
          {[1, 2, 3, 4, 5].map((id) => (
            <li key={`sidebar_${id}`} onClick={() => handleClick(id)} className={`sidebar${id}`}>
              <img src={require(`../../../assets/images/sidebar_${id}.jpg`)} alt={`sidebar_${id}`} className={`sidebar${id}`} />
              <div className={id === active ? 'navigate active' : 'navigate'}></div>
            </li>
          ))}
          <img src={LOGOUT} alt="Log-out" onClick={handleSignout} id="logout" />
        </ul>
      </div>
      <div className="container-margin" id="logout-container">
        <img src={LOGOUT} alt="Log-out" onClick={handleSignout} style={{ cursor: 'pointer' }}/>
      </div>
    </div>
  );
};

export default SideBar;
