import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Backdrop, Modal, Grid, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import '../../../../assets/scss/welcome-modal/welcome-modal.scss';
import Header from '../../post-card/Header';
import PageIndicator from './PageIndicator';
import WelcomeModal1 from './WelcomeModal1';
import WelcomeModal2 from './WelcomeModal2';
import WelcomeModal3 from './WelcomeModal3';
import Footer from './Footer';
import { updateProfile } from '../../../../state/user';
import { usePreferredState } from '../../../customHooks';
import { updateAccount } from '../../../../state/user/actions';

const WelcomeModal = ({ open, onClose, style }) => {
  const [modalPage, setModalPage] = useState(1);
  const [welcomeModalData, setWelcomeModalData] = useState({
    AboutUs: '',
    profileImage: '',
    portfolioImage: [],
    yourMoodToday: null,
    characterType: [],
  });
  const { currentUser } = usePreferredState('user');
  const dispatch = useDispatch();

  useEffect(() => {
    setWelcomeModalData({ ...welcomeModalData, profileImage: currentUser.displayPic });
  }, []);

  const handleUpdation = () => {
    const variables = {
      data: {
        displayPic: welcomeModalData.profileImage,
        bio: welcomeModalData.AboutUs,
      },
      updateAccountId: currentUser.id,
    };
    dispatch(updateAccount(variables, 'Profile'));
    dispatch(updateProfile({ bio: welcomeModalData.AboutUs, displayPic: welcomeModalData.profileImage }));
    onClose();
  };

  return (
    <>
      <Modal
        open={open}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        onClick={(e) => e.stopPropagation()}
        onClose={(e, reason) => {
          reason !== 'backdropClick' && onClose();
          e.stopPropagation();
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
          style: { backgroundColor: '#E0E0E0', opacity: '0.5' },
        }}
      >
        <Grid container className="welcome-modal" style={style}>
          <IconButton
            className="close-icon-button"
            onClick={() => {
              onClose();
              setModalPage(1);
            }}
          >
            <CloseIcon className="close-icon" />
          </IconButton>

          <Grid className="body">
            <PageIndicator modalPage={modalPage} />
            {modalPage === 1 && <WelcomeModal1 setWelcomeModalData={setWelcomeModalData} welcomeModalData={welcomeModalData} />}
            {modalPage === 2 && <WelcomeModal2 setWelcomeModalData={setWelcomeModalData} welcomeModalData={welcomeModalData} />}
            {modalPage === 3 && <WelcomeModal3 setWelcomeModalData={setWelcomeModalData} welcomeModalData={welcomeModalData} />}
            <Footer setModalPage={setModalPage} handleUpdation={handleUpdation} modalPage={modalPage} />
          </Grid>
        </Grid>
      </Modal>
    </>
  );
};

export default WelcomeModal;
