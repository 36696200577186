import React from 'react';
import Head from './Head';
import Chart from './Chart';
import Category from './Category';
import Table from './Table';
import SideBar from '../../../components/post-connect/side-bar';

const MainSection = () => {
  return (
    <div className="main-section-container scroll-bar-1">
      <Head />
      <Chart />
      <Category />
      <Table />
      <SideBar className="side-bar-mb mb" />
    </div>
  );
};

export default MainSection;
