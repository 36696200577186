//import "./marketplace.css";
function MarketPlaceCard(props) {
  return (
    <div style={{backgroundColor:props.item.bgColor}} className='teamScheduleCard'>
        <div className='teamScheduleCardIcon'>
            <div className='cardIconImage'>
                  <img src={props.item.iconImage} alt="image" />
            </div>
        </div>
        <div className='teamScheduleCardContent'>
            <div className='cardContentCategory'>
            <h1 style={{backgroundColor:props.item.categoryBg}}>{props.item.category}</h1>
            </div>
            <div className='cardContentDetails'>
            <p className='cardContentTitle'>{props.item.title}</p>
            <p className='cardContentDate'>{props.item.date}</p>
            </div>
        </div>
        <div className='teamScheduleCardImage'>
            <div className='cardImage'>
            <img src={props.item.cardImage} alt="image" />
        </div>
        </div>
    </div>
  )
}

export default MarketPlaceCard