import React from 'react';
import CategoryCard from './CategoryCard';
import { CategoryList } from '../data';

const Category = () => {
  return (
    <div className="category-container">
      <div className="category-head">
        <ul>
          {CategoryList.map((item) => (
            <li key={item}>{item}</li>
          ))}
        </ul>
      </div>
      <CategoryCard />
    </div>
  );
};

export default Category;
