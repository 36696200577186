import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { uploadFile } from 'react-s3';
import Description from './Description';
import { styled } from '@mui/material/styles';
import { Grid, Typography, Box, Link, TextField, InputAdornment, Checkbox } from '@mui/material';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import Button from '../../utils/widgets/Button';
import { usePreferredState } from '../../utils/customHooks';
import { updateProfile } from '../../state/user';
import { updateAccount } from '../../state/user/actions';
import { collectiblesConfigs } from '../../config';

const Profile = () => {
  const { currentUser } = usePreferredState('user');
  const dispatch = useDispatch();
  const hiddenFileInput = useRef(null);

  const [profileData, setProfileData] = useState({
    website: '',
    occupation: '',
    checkBox: '',
    name: '',
    displayPic: '',
  });

  const [bio, setBio] = useState(currentUser.bio);
  const profileImg = profileData.displayPic.includes('https') ? profileData.displayPic : `data:image/svg+xml;base64,${profileData?.displayPic}`;

  useEffect(() => {
    setProfileData({
      ...profileData,
      occupation: currentUser?.occupation,
      name: currentUser.name,
      displayPic: currentUser.displayPic,
    });
  }, [currentUser]);

  const handleChange = (e) => {
    setProfileData({ ...profileData, [e.target.name]: e.target.value });
  };

  const editProfile = () => {
    dispatch(updateProfile({ name: profileData.name, occupation: profileData.occupation, bio: bio, displayPic: profileData.displayPic }));
    const updateVar = {
      updateAccountId: currentUser.id,
      data: {
        bio: bio,
        name: profileData.name,
        displayPic: profileData.displayPic,
        occupation: profileData.occupation,
      },
    };
    dispatch(updateAccount(updateVar, 'Profile'));
  };

  const handleClick = async (e) => {
    hiddenFileInput.current.click();
  };

  const handleImageUpload = (e) => {
    const uploadedFile = e.target.files[0];
    uploadFile(uploadedFile, collectiblesConfigs)
      .then((res) => {
        setProfileData({ ...profileData, displayPic: res.location });
      })
      .catch((e) => {
        console.log('Update display pic error--->', e);
      });
  };

  return (
    <Grid item className="profile-container">
      <Grid container spacing={1} className="details-container">
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography className="header-text">Profile</Typography>
          <Typography className="content">Update your photo and personal details here.</Typography>
        </Grid>

        <Grid lg={5} md={5} sm={12} xs={12} item>
          <Typography className="content-header">Username</Typography>
        </Grid>
        <Grid lg={7} md={7} sm={12} xs={12} item style={{ display: 'flex' }}>
          <NormalTextFields
            className="text-field"
            variant="outlined"
            value={currentUser.username}
            readOnly
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" readOnly>
                  <UserNameText variant="h6">creatverse.com</UserNameText>
                </InputAdornment>
              ),
            }}
            name="username"
            onChange={handleChange}
          />
        </Grid>

        <Grid item lg={5} md={5} sm={12} xs={12}>
          <Typography className="content-header">Website</Typography>
        </Grid>
        <Grid item lg={7} md={7} sm={12} xs={12}>
          <NormalTextFields
            className="text-field"
            variant="outlined"
            value={profileData.website}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" readOnly>
                  <UserNameText variant="h6">https://</UserNameText>
                </InputAdornment>
              ),
            }}
            name="website"
            onChange={handleChange}
          />
        </Grid>

        <Grid item lg={5} md={5} sm={12} xs={12}>
          <Typography className="content-header">Your Photo</Typography>
          <Typography className="content">This is will be displayed on your profile</Typography>
        </Grid>
        <YourphotoContainer item lg={7} md={7} sm={12} xs={12}>
          <Grid item style={{ display: 'flex' }}>
            <Grid item sx={{ cursor: 'pointer', position: 'relative' }}>
              <img style={{ width: '60px', height: '58px', borderRadius: '50%' }} src={profileImg} />
              <CameraAltIcon
                style={{
                  position: 'absolute',
                  margin: '-20px -50px',
                  right: '85%',
                  bottom: '30%',
                  borderRadius: '50%',
                  fontSize: '1rem',
                  color: 'grey',
                }}
                onClick={handleClick}
              />
              <input type="file" name="file" style={{ display: 'none' }} ref={hiddenFileInput} onChange={handleImageUpload} />
            </Grid>

            <Grid item style={{ paddingLeft: '1rem', display: 'flex', flexDirection: 'column' }}>
              <TextField value={profileData.name} variant="standard" InputProps={{ disableUnderline: true }} onChange={handleChange} name="name" />
              <Link href="#">{currentUser.address}</Link>
            </Grid>
          </Grid>
          <Grid item>
            <Button
              style={{ fontSize: '0.8rem', padding: '0.25rem 1.5rem', borderRadius: '0.5rem', height: '2rem' }}
              content="Update Now"
              onClick={editProfile}
            />
          </Grid>
        </YourphotoContainer>

        <Grid item lg={5} md={5} sm={12} xs={12}>
          <Typography className="content-header">Your Bio</Typography>
          <Typography className="content">Write a short introduction</Typography>
        </Grid>

        <Grid item lg={7} md={7} sm={12} xs={12}>
          <Description setBio={setBio} bio={bio} />
          <SecondaryText>230 characters left</SecondaryText>
        </Grid>

        <Grid item lg={5} md={5} sm={12} xs={12}>
          <Typography className="content-header">Job title</Typography>
        </Grid>
        <Grid item lg={7} md={7} sm={12} xs={12}>
          <NormalTextFields className="text-field" variant="outlined" value={profileData.occupation} onChange={handleChange} name="occupation" />
          <Grid style={{ display: 'flex', alignItems: 'center' }} item>
            <div>
              <Checkbox
                value={profileData.checkBox}
                name="checkBox"
                color="secondary"
                size="small"
                onChange={handleChange}
                defaultChecked
                style={{ color: '#808080', fontSize: '10px', marginRight: '8px' }}
              />
            </div>

            <SecondaryText>Show my job title in my profile</SecondaryText>
          </Grid>
        </Grid>
        <Grid item lg={5} md={5} sm={12} xs={12}>
          <Typography className="content-header">Alternative contact email</Typography>
          <Typography style={{ color: '#808080' }} className="content">
            Enter alternative email if u want to
          </Typography>
        </Grid>
        <Grid lg={7} md={7} sm={12} xs={12} item>
          <NormalTextFields
            className="text-field"
            variant="outlined"
            value={currentUser.address}
            readOnly
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <MailOutlineIcon />
                </InputAdornment>
              ),
            }}
            onChange={handleChange}
            name="email"
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

const ContainerBox = styled(Box)`
  background: #f9f9f9;
  border-radius: 12px;
  padding: 2rem;
  height: 100vh;
`;
const UserNameText = styled(Typography)`
  border-right: 1px solid #808080;
  padding: 0 1rem;
  color: #808080;
  font-size: 1rem;
`;

const NormalTextFields = styled(TextField)`
  .MuiOutlinedInput-root {
    background-color: #ffffff;
    border-radius: 12px;

    height: 37px;
  }
`;

const SecondaryText = styled(Typography)`
  color: #808080;
  font-size: 13px;
`;

const YourphotoContainer = styled(Grid)`
  display: flex;
  gap: 2rem;
  align-items: center;
  flex-wrap: wrap;
`;
const YourBioBoxContainer = styled(Grid)`
  display: flex;
  align-items: center;
  padding-top: 1rem;
`;

const BioTextFields = styled(TextField)`
  .MuiOutlinedInput-root {
    background-color: #ffffff;
    border-radius: 12px;
  }
`;

export default Profile;
