import React, { useState } from 'react';
import { LiquidPoolData } from '../data';
import toggle from '../../../assets/images/toggle.png';
import dollar from '../../../assets/images/dollar-icon.png';
import greenDot from '../../../assets/images/green-dot.png';
import headLogo from '../../../assets/images/logo-trans.png';
import crown from '../../../assets/images/crown.png';
import edit from '../../../assets/images/edit-white.png';

const SideSection = () => {
  const [displayHide, setDisplayHide] = useState(false);

  const handleClick = () => {
    setDisplayHide(!displayHide);
  };

  return (
    <div className={displayHide ? 'hw-side-section toggle-width' : 'hw-side-section scroll-bar-1'}>
      <div className="toggle-widget">
        <img onClick={handleClick} src={toggle} alt="icon" />
      </div>
      <div className={displayHide ? 'side-section-content display-hide' : 'side-section-content'}>
        <div className="side-section-head">
          <div className="side-head-left">
            <h1>$ COMMUNITY LIQUIDITY POOL</h1>
            <p className="coins">3,00,000 $ COINS</p>
            <p>Total Burned : 45,000 Coins</p>
          </div>
          <div className="side-head-right">
            <img src={dollar} alt="dollar-icon" />
          </div>
        </div>
        <div className="side-section-community">
          <h1>$ COMMUNITY LIQUIDITY POOL</h1>
          <div className="side-community-body">
            <ul>
              {LiquidPoolData.map((item) => (
                <li key={item.id}>
                  <div className="side-list-body">
                    <div className="side-body-left">
                      <p>{item.num}</p>
                      <img src={greenDot} alt="dot" />
                    </div>
                    <div className="side-body-center">
                      <p>{item.name}</p>
                    </div>
                    <div className="side-body-right">
                      <button className="cursor">Join</button>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
          <button className="view-all-btn cursor">View All</button>
        </div>
        <div className="side-section-premium">
          <div className="side-head-image">
            <img src={headLogo} alt="logo" />
            <div></div>
          </div>
          <div className="side-head-title">
            <div className="side-head-details">
              <h1>CREATEVERSE PREMIUM</h1>
              <p>Try premium with free coins</p>
            </div>
            <button className="try-now cursor">
              <p>Try Now</p>{' '}
              <span>
                <img src={crown} alt="icon" />
              </span>
            </button>
          </div>
        </div>
        <div className="side-section-earn">
          <h1>EARN BY CREATING</h1>
          <p>Creatverse is a token powered social network where you can earn a daily income by simply posting content and chatting with others. </p>
          <button className="side-earn-btn cursor">
            CREATE POST{' '}
            <span>
              <img src={edit} alt="icon" />
            </span>
          </button>
          <button className="side-earn-btn cursor">
            CREATE COMMUNITY{' '}
            <span>
              <img src={edit} alt="icon" />
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default SideSection;
