import React, { useState, useEffect } from 'react';
import Header from './Header';
import Community from './Community';
import PostSection from './Post';
import { usePreferredState } from '../../../utils/customHooks';
import SideBar from '../../../components/post-connect/side-bar';

const MainSection = () => {
  const [profileInfo, setProfileInfo] = useState({ name: '', username: '', displayPic: '', address: '', skills: [], occupation: '' });
  const { currentUser, contactsById, selectedProfile } = usePreferredState('user');

  useEffect(() => {
    if (currentUser.id === selectedProfile) {
      const { name, username, displayPic, address, skills, occupation, DOB, bio, coverPic } = currentUser;
      setProfileInfo({ name, username, displayPic, address, skills, occupation, DOB, bio, coverPic });
    } else {
      let user = contactsById[selectedProfile][0];
      const { name, username, displayPic, address, skills, occupation, DOB, bio, coverPic } = user;
      setProfileInfo({ name, username, displayPic, address, skills, occupation, DOB, bio, coverPic });
    }
  }, [selectedProfile, currentUser]);

  return (
    <div className="hw-main-section scroll-bar-1">
      <Header profileInfo={profileInfo} />
      <Community profileInfo={profileInfo} />
      <PostSection />
      <SideBar className="side-bar-mb mb" />
    </div>
  );
};

export default MainSection;
