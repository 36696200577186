import React from 'react';
import { Button } from '@mui/material';

const AppButton = ({ content, style, onClick, disabled }) => {
  return (
    <Button className="btn" sx={{ ...style, cursor: disabled ? 'not-allowed' : 'pointer' }} onClick={onClick} disabled={disabled}>
      {content}
    </Button>
  );
};

export default AppButton;
