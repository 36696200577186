import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import '../../../../assets/scss/welcome-modal/welcome-modal-content2.scss';
import PROFILE_PICTURE from '../../../../assets/images/PROFILE_PICTURE.png';
import PROFILE_EDIT_ICON from '../../../../assets/images/PROFILE_EDIT_ICON.png';
import PORTFOLIO_IMAGE_BUTTON from '../../../../assets/images/PORTFOLIO_IMAGE_BUTTON.png';
import { uploadFile } from 'react-s3';
import { PORTFOLIO_IMAGES } from './insert-page-res/data';
import { welcomeModalPortfolioImage, welcomeModalProfileImage } from '../../../../config';
import { usePreferredState } from '../../../customHooks';
// import { setProfileData, setPortfolioImage } from '../../../../state/user';

const WelcomeModal2 = ({ setWelcomeModalData, welcomeModalData }) => {
  const [selectedPortfolioImg, setSelectedPortfolioImg] = useState(1);
  const [banner, setBanner] = useState([...PORTFOLIO_IMAGES]);
  const [profileData, setProfileData] = useState({
    profilePic: '',
  });
  const { currentUser } = usePreferredState('user');

  useEffect(() => {
    setProfileData({ profilePic: currentUser.displayPic });
  }, []);

  const hiddenProfileImageInput = useRef(null);
  const hiddenPortfolioImageInput = useRef(null);

  const profileImg = profileData.profilePic.includes('https') ? profileData.profilePic : `data:image/svg+xml;base64,${profileData?.profilePic}`;

  const handleProfileClick = async (e) => {
    hiddenProfileImageInput.current.click();
  };

  const handleProfileImageUpload = (e) => {
    const uploadedFile = e.target.files[0];
    uploadFile(uploadedFile, welcomeModalProfileImage)
      .then((res) => {
        setProfileData({ profilePic: res.location });
        setWelcomeModalData({ ...welcomeModalData, profileImage: res.location });
      })
      .catch((e) => {
        console.log('Update Profile Image error--->', e);
      });
  };

  const handlePortfolioClick = async (e) => {
    hiddenPortfolioImageInput.current.click();
  };

  const handlePortfolioImageUpload = (e) => {
    console.log(e.target.files[0].name);
    let variables = { id: banner.length + 1, src: URL.createObjectURL(e.target.files[0]) };
    setBanner([...banner, variables]);
    setWelcomeModalData({ ...welcomeModalData, portfolioImage: [variables] });
  };
  console.log('banner', banner);

  return (
    <div className="welcome-modal-content2">
      <div className="profile-picture-container">
        <p className="profile-picture-text">Add a profile picture</p>
        <div className="profile-picture-image">
          {/* <img src={PROFILE_PICTURE} alt="PROFILE_PICTURE" /> */}
          <img src={profileImg} className="profile-picture-image-tag" />
          <span className="profile-edit-icon" onClick={handleProfileClick}>
            <img src={PROFILE_EDIT_ICON} alt="PROFILE_EDIT_ICON" />
            <input type="file" name="file" style={{ display: 'none' }} ref={hiddenProfileImageInput} onChange={handleProfileImageUpload} />
          </span>
        </div>
      </div>
      <div className="portfolio-container">
        <p className="portfolio-text">Add some images to your portfolio</p>
        <div className="portfolio-images">
          {banner.map((item) => (
            <div className="portfolio-image-border " key={item.id}>
              <img src={item.src} alt={item.id} />
            </div>
          ))}
          <button className="portfolio-button" onClick={handlePortfolioClick}>
            <img src={PORTFOLIO_IMAGE_BUTTON} alt="PORTFOIO_IMAGE_BUTTON" />
            <input type="file" style={{ display: 'none' }} ref={hiddenPortfolioImageInput} onChange={handlePortfolioImageUpload} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default WelcomeModal2;
