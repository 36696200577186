import React, { useState } from 'react';
import CreateCommunityHead from './head/CreateCommunityHead';
import SocialLinks from './SocilaLinks';
import { SocialLinksHeadData } from '../data';

const SocialLinksContainer = (props) => {
  const { singleUrl, setSingleUrl, urls, setUrls } = props;
  
  const [socialLinks, setSocialLinks] = useState([1]);

  const handleRemoveLink = (index) => {
    let newList = [...socialLinks];
    newList.splice(index, 1);
    setSocialLinks([...newList]);
  };

  const handleAddNewLink = () => {
    let newLink = socialLinks.length + 1;
    setSocialLinks([newLink, ...socialLinks]);
    setUrls([...urls, singleUrl]);
  };

  return (
    <div className="social-links-container">
      <CreateCommunityHead head={SocialLinksHeadData} />
      <div className="socialLinks scroll-bar-4">
        {socialLinks.map((item, index) => (
          <SocialLinks
            key={index}
            index={index}
            handleRemoveLink={handleRemoveLink}
            zIndex={socialLinks.length - index}
            url={singleUrl}
            setUrl={setSingleUrl}
          />
        ))}
      </div>
      <div className="add-social-links-btn">
        <p onClick={handleAddNewLink}>+ Add new link</p>
      </div>
    </div>
  );
};

export default SocialLinksContainer;
