export const dateList = ['Mon 17','Tue 18','Wed 19','Thu 20','Fri 21','Sat 22'];

export const cardData = [
    {
      id : Math.random(),
      bgColor : '#C2CBE3',
      iconImage:'https://cdn.allthings.how/wp-content/uploads/2020/10/allthings.how-how-to-change-your-profile-picture-on-google-meet-profile-photo-759x427.png?width=800',
      category:'Photos',
      categoryBg:'#7C7EF7 ',
      title:'LandScape Picture',
      date:'Mon 17,10.39 PM',
      cardImage:'https://i.pinimg.com/736x/28/48/20/284820872ee9997322d6e43084eff3d7--french-art-th-century.jpg',
      teamDate:'Mon 17'
    },
    {
      id : Math.random(),
      bgColor : '#CFEDEA',
      iconImage:'https://icons-for-free.com/download-icon-business+costume+male+man+office+user+icon-1320196264882354682_512.png',
      category:'Stories',
      categoryBg:'#52CAED ',
      title:'Behind the scenes',
      date:'Mon 17,10.39 PM',
      cardImage:'https://i.pinimg.com/originals/bc/71/e4/bc71e4de124da9ea80dfe55c6b9a271e.png',
      teamDate:'Mon 17'
    },
    {
      id : Math.random(),
      bgColor : 'white',
      iconImage:'https://icons-for-free.com/download-icon-female+person+user+woman+young+icon-1320196266256009072_512.png',
      category:'Videos',
      categoryBg:'#F8C56C ',
      title:'Interviews',
      date:'Mon 17,10.39 PM',
      cardImage:'https://i.pinimg.com/550x/8d/25/ff/8d25ffba5a1d84d02860fa1aef8fda56.jpg',
      teamDate:'Mon 17'
    },
    {
      id : Math.random(),
      bgColor : '#F3D3E8 ',
      iconImage:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTtF3o2PvqxOMHgdrpj_YRItsLBjxyTeNZu_Q&usqp=CAU',
      category:'Polls',
      categoryBg:'#FF98BF  ',
      title:'Do you like this Design',
      date:'Mon 17,10.39 PM',
      cardImage:'https://helpx.adobe.com/content/dam/help/en/illustrator/how-to/design-draw-shapes/jcr_content/main-pars/image/design-draw-shapes_hero-v2.jpg',
      teamDate:'Mon 17'
    },
    {
      id : Math.random(),
      bgColor : '#C2CBE3',
      iconImage:'https://cdn-icons-png.flaticon.com/512/219/219969.png',
      category:'Photos',
      categoryBg:'#7C7EF7 ',
      title:'LandScape Picture',
      date:'Tue 18,10.39 PM',
      cardImage:'https://static.scientificamerican.com/sciam/cache/file/14F112FA-089F-4D20-A6108348A2FE0763_source.jpg?w=590&h=800&12E84123-8A23-43D0-A0A5A10015C6F666',
      teamDate:'Tue 18'
    },
    {
      id : Math.random(),
      bgColor : '#CFEDEA',
      iconImage:'https://www.clipartkey.com/mpngs/m/102-1029067_student-flat-icon-www-pixshark-com-images-galleries.png',
      category:'Stories',
      categoryBg:'#52CAED ',
      title:'Behind the scenes',
      date:'Tue 18,10.39 PM',
      cardImage:'https://images.pexels.com/photos/1147124/pexels-photo-1147124.jpeg?cs=srgb&dl=pexels-matthias-cooper-1147124.jpg&fm=jpg',
      teamDate:'Tue 18'
    },
    {
      id : Math.random(),
      bgColor : 'white',
      iconImage:'https://www.eastriverdental.com/wp-content/uploads/2021/03/Avatar-PNG-High-Quality-Image.png',
      category:'Videos',
      categoryBg:'#F8C56C ',
      title:'Interviews',
      date:'Tue 18,10.39 PM',
      cardImage:'https://images.unsplash.com/photo-1593062096033-9a26b09da705?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8M3x8d29yayUyMGRlc2t8ZW58MHx8MHx8&w=1000&q=80',
      teamDate:'Tue 18'
    },
    {
      id : Math.random(),
      bgColor : '#F3D3E8 ',
      iconImage:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRpY0PT9Nx-q6ogDmoB8NH6Qg3IB9YrdsQRleDrQ4_u7WcafFUFv7gEVJ5GVTnd6CA0cpo&usqp=CAU',
      category:'Polls',
      categoryBg:'#FF98BF  ',
      title:'Do you like this Design',
      date:'Tue 18,10.39 PM',
      cardImage:'https://cdn4.vectorstock.com/i/thumb-large/74/68/spruce-forest-on-moon-background-vector-27557468.jpg',
      teamDate:'Tue 18'
    },
    {
      id : Math.random(),
      bgColor : '#C2CBE3',
      iconImage:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSs0Ee6Vt59yZWyRdonttwOL2CqqYvvXSfmZOZ7_pogGrxibbbKqEMU_TiTv_GBbZRxS5s&usqp=CAU',
      category:'Photos',
      categoryBg:'#7C7EF7 ',
      title:'LandScape Picture',
      date:'Wed 19,10.39 PM',
      cardImage:'https://cdn.shopify.com/s/files/1/0969/9128/collections/oldmasters.jpg?v=1442040501',
      teamDate:'Wed 19'
    },
    {
      id : Math.random(),
      bgColor : '#CFEDEA',
      iconImage:'https://toppng.com/uploads/preview/girl-flat-icon-11549760838cubn4qroau.png',
      category:'Stories',
      categoryBg:'#52CAED ',
      title:'Behind the scenes',
      date:'Wed 19,10.39 PM',
      cardImage:'https://i.etsystatic.com/15915041/r/il/6db7c5/1319318876/il_570xN.1319318876_c1gn.jpg',
      teamDate:'Wed 19'
    },
    {
      id : Math.random(),
      bgColor : 'white',
      iconImage:'https://esquimaltmfrc.com/wp-content/uploads/2015/09/flat-faces-icons-circle-woman-3.png',
      category:'Videos',
      categoryBg:'#F8C56C ',
      title:'Interviews',
      date:'Wed 19,10.39 PM',
      cardImage:'https://images.unsplash.com/photo-1591091087362-590d1cfa311a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTZ8fHdvcmslMjBkZXNrfGVufDB8fDB8fA%3D%3D&w=1000&q=80',
      teamDate:'Wed 19'
    },
    {
      id : Math.random(),
      bgColor : '#F3D3E8 ',
      iconImage:'https://esquimaltmfrc.com/wp-content/uploads/2015/09/flat-faces-icons-circle-man-6.png',
      category:'Polls',
      categoryBg:'#FF98BF  ',
      title:'Do you like this Design',
      date:'Wed 19,10.39 PM',
      cardImage:'https://static.vecteezy.com/system/resources/thumbnails/000/427/048/small/Mental-Health.jpg',
      teamDate:'Wed 19'
    },
    {
      id : Math.random(),
      bgColor : '#C2CBE3',
      iconImage:'https://cdn5.vectorstock.com/i/1000x1000/23/89/man-face-circle-icon-in-trendy-flat-style-vector-5782389.jpg',
      category:'Photos',
      categoryBg:'#7C7EF7 ',
      title:'LandScape Picture',
      date:'Thu 20,10.39 PM',
      cardImage:'https://images.unsplash.com/flagged/photo-1572392640988-ba48d1a74457?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8b2xkJTIwYXJ0fGVufDB8fDB8fA%3D%3D&w=1000&q=80',
      teamDate:'Thu 20'
    },
    {
      id : Math.random(),
      bgColor : '#CFEDEA',
      iconImage:'https://cdn2.vectorstock.com/i/1000x1000/40/71/avatar-men-icon-circle-vector-15604071.jpg',
      category:'Stories',
      categoryBg:'#52CAED ',
      title:'Behind the scenes',
      date:'Thu 20,10.39 PM',
      cardImage:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRWvWL6pa8job5aWqLBNVfoON8NrD36rJzQHR2LqI149q5L7fzS7M8LR2DPkplhf0ptnJQ&usqp=CAU',
      teamDate:'Thu 20'
    },
    {
      id : Math.random(),
      bgColor : 'white',
      iconImage:'https://cdn3.vectorstock.com/i/1000x1000/47/87/man-icon-gentleman-logo-a-with-a-beard-vector-23194787.jpg',
      category:'Videos',
      categoryBg:'#F8C56C ',
      title:'Interviews',
      date:'Thu 20,10.39 PM',
      cardImage:'https://images.unsplash.com/photo-1575318634028-6a0cfcb60c59?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1yZWxhdGVkfDE0fHx8ZW58MHx8fHw%3D&w=1000&q=80',
      teamDate:'Thu 20'
    },
    {
      id : Math.random(),
      bgColor : '#F3D3E8 ',
      iconImage:'https://www.pngmart.com/files/21/Admin-Profile-PNG-Clipart.png',
      category:'Polls',
      categoryBg:'#FF98BF  ',
      title:'Do you like this Design',
      date:'Thu 20,10.39 PM',
      cardImage:'https://thumbs.dreamstime.com/b/black-man-cartoon-afro-side-view-orange-background-vector-design-boy-male-person-people-human-social-media-portrait-193858488.jpg',
      teamDate:'Thu 20'
    },
    {
      id : Math.random(),
      bgColor : '#C2CBE3',
      iconImage:'https://image.shutterstock.com/image-vector/flat-icon-female-school-principal-260nw-1086826781.jpg',
      category:'Photos',
      categoryBg:'#7C7EF7 ',
      title:'LandScape Picture',
      date:'Fri 21,10.39 PM',
      cardImage:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRn7K1DNAS5nxHe5rUnF4vZe_vngEQ1k8Typ5gtMtl9X9P3lZjEh3ZEGoOnaDZLj87DGV4&usqp=CAU',
      teamDate:'Fri 21'
    },
    {
      id : Math.random(),
      bgColor : '#CFEDEA',
      iconImage:'https://mpng.subpng.com/20180403/eqe/kisspng-computer-icons-teacher-clip-art-avatar-5ac3db46122e89.4817606315227850940745.jpg',
      category:'Stories',
      categoryBg:'#52CAED ',
      title:'Behind the scenes',
      date:'Fri 21,10.39 PM',
      cardImage:'https://cdn.idropnews.com/wp-content/uploads/2017/10/02114847/Waves-Crashing-At-Point-Reyes-iPhone-Wallpaper-1080x1920.jpg',
      teamDate:'Fri 21'
    },
    {
      id : Math.random(),
      bgColor : 'white',
      iconImage:'https://greenlearning.ca/assets/uploads/img/staff/_800xAUTO_crop_center-center_70_none/Male-Avatar.jpg',
      category:'Videos',
      categoryBg:'#F8C56C ',
      title:'Interviews',
      date:'Fri 21,10.39 PM',
      cardImage:'https://images.unsplash.com/photo-1551527559-a0d94ca99202?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1yZWxhdGVkfDV8fHxlbnwwfHx8fA%3D%3D&w=1000&q=80',
      teamDate:'Fri 21'
    },
    {
      id : Math.random(),
      bgColor : '#F3D3E8 ',
      iconImage:'https://cdn-icons-png.flaticon.com/512/146/146035.png',
      category:'Polls',
      categoryBg:'#FF98BF  ',
      title:'Do you like this Design',
      date:'Fri 21,10.39 PM',
      cardImage:'https://i.pinimg.com/originals/c2/37/ff/c237ff878d0820ba2c190d5b92191b2a.jpg',
      teamDate:'Fri 21'
    },
    {
      id : Math.random(),
      bgColor : '#C2CBE3',
      iconImage:'https://cdn.allthings.how/wp-content/uploads/2020/10/allthings.how-how-to-change-your-profile-picture-on-google-meet-profile-photo-759x427.png?width=800',
      category:'Photos',
      categoryBg:'#7C7EF7 ',
      title:'LandScape Picture',
      date:'Sat 22,10.39 PM',
      cardImage:'http://static.demilked.com/wp-content/uploads/2015/08/17-year-old-digital-artist-martina-stipan-croatia-thumb290.jpg',
      teamDate:'Sat 22'
    },
    {
      id : Math.random(),
      bgColor : '#CFEDEA',
      iconImage:'https://icons-for-free.com/download-icon-business+costume+male+man+office+user+icon-1320196264882354682_512.png',
      category:'Stories',
      categoryBg:'#52CAED ',
      title:'Behind the scenes',
      date:'Sat 22,10.39 PM',
      cardImage:'https://i0.wp.com/ideawallpapers.com/wp-content/uploads/2019/04/iphone-wallpapers-1-25.jpg?resize=671%2C1192&ssl=1',
      teamDate:'Sat 22'
    },
    {
      id : Math.random(),
      bgColor : 'white',
      iconImage:'https://icons-for-free.com/download-icon-female+person+user+woman+young+icon-1320196266256009072_512.png',
      category:'Videos',
      categoryBg:'#F8C56C ',
      title:'Interviews',
      date:'Sat 22,10.39 PM',
      cardImage:'https://images.unsplash.com/photo-1601656269222-fda862e6dc7d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1yZWxhdGVkfDIwfHx8ZW58MHx8fHw%3D&w=1000&q=80',
      teamDate:'Sat 22'
    },
    {
      id : Math.random(),
      bgColor : '#F3D3E8 ',
      iconImage:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTtF3o2PvqxOMHgdrpj_YRItsLBjxyTeNZu_Q&usqp=CAU',
      category:'Polls',
      categoryBg:'#FF98BF  ',
      title:'Do you like this Design',
      date:'Sat 22,10.39 PM',
      cardImage:'https://i.pinimg.com/736x/ca/01/d4/ca01d415bfcfc011582372dc820c7a35.jpg',
      teamDate:'Sat 22'
    },
  ]

  export const chartData = [
    {
      id : Math.random(),
      day:'Mon',
      bar1:'80',
      bar2:'60'
  },
    {
      id : Math.random(),
      day:'Tue',
      bar1:'55',
      bar2:'35'
  },
    {
      id : Math.random(),
      day:'Wed',
      bar1:'30',
      bar2:'20'
  },
    {
      id : Math.random(),
      day:'Thu',
      bar1:'58',
      bar2:'32'
  },
]