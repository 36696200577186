import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { strapiClient } from '../..';
import ResetPasswordPage from '../../pages/reset-password';
import { ChatState } from '../../components/context/ChatProvider';
import LandingPage from '../../components/landing-page';
import PostConnect from '../../components/post-connect';
import { setGuestUser, setLoading } from '../../state/user';
import { getAccount } from '../../state/user/actions';
import { useWallet } from '../../state/wallet';
import { handleBalance } from '../../state/wallet/helper';
import { usePreferredState } from '../../utils/customHooks';
import { Loader } from '../../utils/widgets/Loader';
import Animations from '../../utils/widgets/Animations';

const Layout = () => {
  const dispatch = useDispatch();
  const { connected, loading } = usePreferredState('user');
  const wallet = useWallet();
  const { socket } = ChatState();

  useEffect(() => {
    let interval;
    if (wallet.isConnected) {
      interval = setInterval(() => {
        dispatch(handleBalance());
      }, 5000);
    }
    return () => clearInterval(interval);
  }, [wallet.isConnected, dispatch]);

  useEffect(() => {
    let token = localStorage.getItem('token');
    let accountId = localStorage.getItem('accountId');
    const isGuestUser = localStorage.getItem('isGuestUser');

    if (token && accountId) {
      dispatch(setLoading(true));
      strapiClient.setHeader('authorization', `Bearer ${token}`);
      dispatch(getAccount(accountId, socket));
    }
    if (isGuestUser) {
      dispatch(setGuestUser());
    }
  }, [dispatch]);

  if (loading)
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          zIndex: '1000',
        }}
      >
        <Loader />
      </div>
    );

  if (connected)
    return (
      <div className="layout post-connect">
        <PostConnect />
        <Animations />
      </div>
    );

  // Postconnect is what we see after successful login
  //Landing page displays the login implementation
  return (
    <>
      <Routes>
        <Route path={`/reset-password`} element={<ResetPasswordPage />} />
        <Route path={'*'} element={<LandingPage />} />
      </Routes>
    </>
  );
};

export default Layout;
