import React from 'react';
import { useDispatch } from 'react-redux';
import { usePreferredState } from '../../../utils/customHooks';
import { setCurrentChat } from '../../../state/user';
import FOURDOTS from '../../../assets/images/four_dots.png';
import IMAGE from '../../../assets/images/image.png';
import FILE from '../../../assets/images/file.png';
import MITHRA from '../../../assets/images/mithra.png';
import ARUN from '../../../assets/images/arun.png';
import CLOSE from '../../../assets/images/close.png';
import { useMediaQuery } from '@mui/material';

const HEADER_MENU = [
  { id: 1, label: 'phone' },
  { id: 2, label: 'wallet' },
  // { id: 3, label: 'black_more' },
];

const ChatAreaHeader = () => {
  const dispatch = useDispatch();
  const { currentChat, activeUsers } = usePreferredState('user');
  const matches = useMediaQuery('(max-width: 480px)'); //sm

  const isOnline = activeUsers.includes(currentChat.id);
  const profileImg = currentChat.displayPic.includes('https') ? currentChat.displayPic : `data:image/svg+xml;base64,${currentChat?.displayPic}`;

  return (
    <div className="chat-area-header">
      <div className="hide-scroll">
        <div className="profile-container">
          <div className="profile-image">
            <img src={profileImg} alt="avatar" />
            <div id="name-container">
              <p id="name">{currentChat.name}</p>
              <p className={`last-seen ${isOnline ? 'online' : ''}`}>{isOnline ? 'online' : 'Last seen 3 hours ago'}</p>
            </div>
          </div>
          <div className="header-menu">
            {HEADER_MENU.map((item, i) => (
              <img
                key={item.id}
                id="icon"
                src={require(`../../../assets/images/${item.label}.png`)}
                alt={item.label}
                style={{ ...(i === 1 ? { width: '1.75rem' } : null) }}
              />
            ))}
            <img src={CLOSE} id="icon" alt="close" onClick={() => dispatch(setCurrentChat(null))} />
          </div>
        </div>
      </div>
      {!matches && (
        <div className="profile-menu-container">
          <div className="chats">
            <img src={FOURDOTS} alt="four-dots" />
            <span id="tag">Chats</span>
            <span id="count">24</span>
          </div>
          <div id="icons">
            <img src={IMAGE} alt="image" />
            <img src={FILE} alt="file-img" />
          </div>
          <div className="friends">
            <img src={MITHRA} alt="mithra-img" />
            <img src={ARUN} alt="arun-img" style={{ height: '1.6875rem !important', width: '1.6875rem' }} />
            <span id="friends-count">+6</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChatAreaHeader;
