import React, { useState } from 'react';
import ChatHead from './ChatHead';
import ChatBody from './ChatBody';
import { usePreferredState } from '../../../utils/customHooks';

const ChatComponent = ({ drag, openChat, setOpenChat }) => {
  const { contacts, isGuestUser, activeUsers } = usePreferredState('user');

  const containerStyle = {
    width: openChat ? '350px' : '220px',
    height: openChat ? '50vh' : '50px',
    transition: openChat ? 'width 0.3s, height 0.5s ease-in-out' : 'width 0.3s, height 0.2s ease-in-out',
  };

  return (
    <div style={containerStyle} className="chat-container">
      <ChatHead setOpenChat={setOpenChat} drag={drag} openChat={openChat} activeUsers={activeUsers.length} isGuestUser={isGuestUser} />
      {openChat && <ChatBody contacts={contacts} />}
    </div>
  );
};

export default ChatComponent;
