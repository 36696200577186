import React from 'react';

const TradingTable = () => {
  const themeGreen = {
    color: '#6FCD96',
  };
  const amountStyle = {
    fontWeight: 600,
    color: 'black',
  };

  return (
    <div className="trading-table">
      <div>
        <p className="trading-table-head">KAB/CREATO</p>
        <p className="trading-table-data">Vol : 232,566</p>
      </div>
      <div>
        <p className="trading-table-head" style={themeGreen}>
          35.66 $CREATO
        </p>
        <p className="trading-table-data" style={{ fontWeight: 'bold' }}>
          = 50.16 INR
        </p>
      </div>
      <div>
        <p className="trading-table-head">24H Change</p>
        <p className="trading-table-data" style={themeGreen}>
          3.88 %
        </p>
      </div>
      <div>
        <p className="trading-table-head">Highest Price</p>
        <p className="trading-table-data" style={themeGreen}>
          12.98
        </p>
      </div>
      <div>
        <p className="trading-table-head">Lowest Price</p>
        <p className="trading-table-data" style={themeGreen}>
          12.98
        </p>
      </div>
    </div>
  );

  return (
    <table className="trading-table">
      <tr>
        <th>KAB/CREATO</th>
        <th style={themeGreen}>35.66 $CREATO</th>
        <th>24H Change</th>
        <th>Highest Price</th>
        <th>Lowest Price</th>
      </tr>
      <tr>
        <td>Vol : 232,566</td>
        <td style={amountStyle}>= 50.16 INR</td>
        <td style={themeGreen}>3.88 %</td>
        <td>12.98</td>
        <td>12.98</td>
      </tr>
    </table>
  );
};

export default TradingTable;
