import React, { useState } from 'react';
import ReactCrop from 'react-image-crop';
import { createCanvas } from './createCanvas';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import 'react-image-crop/dist/ReactCrop.css';
import '../../assets/scss/image-crop-preview.scss';

export default function ImagePreview(props) {
  const { image, setImage, handleFinalImage } = props;

  const [crop, setCrop] = useState({
    unit: '%',
    width: 30,
    aspect: 1 / 0.25,
  });

  const [imageRef, setImageRef] = useState(null);
  const [pixelCrop, setPixelCrop] = useState(null);
  const [finalImage, setFinalImage] = useState(null);

  const handleCroppedImage = (data) => {
    setFinalImage(data);
  };

  const handleOnComplete = async (pixelData) => {
    if (imageRef && pixelData.width && pixelData.height) {
      let result = await createCanvas(imageRef, pixelData);
      handleCroppedImage(result);
    }
  };

  const handleOnUpload = () => {
    setImage(null);
    let finalImageData = finalImage.croppedImage;
    handleFinalImage(finalImageData);
  };

  const handleImageLoaded = (image) => {
    setImageRef(image);
  };

  const handleClosePreview = () => {
    setImage(null);
  };

  return (
    <div className="image-cropper-container">
      <div className="image-pop-up">
        <div className="close-icon" onClick={handleClosePreview}>
          <CloseRoundedIcon />
        </div>
        <div className="main-image-container">
          <ReactCrop
            className="react-cropper"
            crop={crop}
            onChange={(c) => setCrop(c)}
            onComplete={(c) => handleOnComplete(c)}
            src={image}
            ruleOfThirds
            onImageLoaded={(item) => handleImageLoaded(item)}
            crossorigin="anonymous"
          />
        </div>
        <div className="image-details-section">
          <p>Select Image</p>
          <div className="preview-image-container">
            {finalImage ? <img src={finalImage.croppedImageUrl} alt={'wallpaper'} /> : <p className="image-preview-title">Image Preview</p>}
          </div>
          <div className="upload-btn">
            <button onClick={handleOnUpload}>Upload</button>
          </div>
        </div>
      </div>
    </div>
  );
}
