import React, { createContext, useContext, useRef } from 'react';

const ChatContext = createContext();

const ChatProvider = ({ children }) => {
  const socket = useRef();

  return (
    <ChatContext.Provider
      value={{
        socket,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};

export const ChatState = () => {
  return useContext(ChatContext);
};

export default ChatProvider;
