import React, { useState } from 'react';
import { Chip, Grid, Stack, IconButton, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import navLeftArrow from '../../../../assets/images/nav-left-arrow.png';
import navRightArrow from '../../../../assets/images/nav-right-arrow.png';
import { strapiClient } from '../../../..';
import { GET_ALL_HASH_TAGS } from '../../../../config/queries';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setTags } from '../../../../state/user';
import AddItemPopup from '../../AddItemPopup';
import { CREATE_HASH_TAGS } from '../../../../config/mutations';
import { usePreferredState } from '../../../customHooks';
import { HorizontalLoader } from '../../HorizontalLoader';

const HashTag = ({ setSelectedHashTag }) => {
  const { tags } = usePreferredState('user');

  const [navigate, setNavigate] = useState(null);
  const [defaultTags, setDefaultTags] = useState([]);
  const [selectedChips, setSelectedChips] = useState([]);
  const [isPopup, setIsPopup] = useState(null);
  const [tag, setTag] = useState('');
  const [tagsLoading, setTagsLoading] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    getHashTags();
  }, [tags.length]);

  useEffect(() => {
    setSelectedHashTag([...selectedChips]);
  }, [selectedChips]);

  const getHashTags = () => {
    setTagsLoading(true);
    strapiClient
      .request({
        document: GET_ALL_HASH_TAGS,
      })
      .then((data) => {
        let hash = data.hashtags.data.map((data) => {
          let randomColor = Math.floor(Math.random() * 16777215).toString(16);
          return {
            ...data,
            color: '#' + randomColor,
          };
        });
        dispatch(setTags(hash));
        setDefaultTags(hash);
        setTagsLoading(false);
      })
      .catch((e) => {
        console.log('Get hash tags error--->', e);
        setTagsLoading(false);
      });
  };

  const handleRight = () => {
    setNavigate('right');
    let res = tags.splice(-1, 1);
    setDefaultTags([...res, ...tags]);
  };

  const handleLeft = () => {
    setNavigate('left');
    let res = tags.splice(0, 1);
    setDefaultTags([...tags, ...res]);
  };

  const handleSelectChip = (data) => {
    setSelectedChips((prev) => [...prev, data]);
    let temp = defaultTags.filter((tag) => tag.id !== data.id);
    setDefaultTags([...temp]);
  };

  const handleUnselectChip = (data) => {
    let temp = selectedChips.filter((chip) => chip.id !== data.id);
    setSelectedChips([...temp]);
    setDefaultTags([...defaultTags, data]);
  };

  const addHashTag = () => {
    const variables = {
      data: {
        hashtag: tag,
      },
    };

    strapiClient
      .request({
        document: CREATE_HASH_TAGS,
        variables: variables,
      })
      .then((data) => {
        if (data.createHashtag.data) getHashTags();
      });
  };

  return (
    <Grid sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
      <Typography sx={{ fontSize: '1rem', fontWeight: 700 }}>Hash Tags</Typography>
      <Grid sx={{ display: 'flex', justifyContent: 'space-between', gap: '1rem', paddingBottom: '0.5rem' }}>
        <Stack direction="row" spacing={1} className="chip-stack">
          {/* <img className="skill-arrow" onClick={handleLeft} src={navLeftArrow} alt="left-icon" style={{ cursor: 'pointer' }} /> */}
          {defaultTags.map((data) => (
            <Chip
              key={data.id}
              label={data.attributes.hashtag}
              style={{ color: `black`, background: `${data.color}` }}
              className="chip"
              onClick={() => handleSelectChip(data)}
            />
          ))}
          {/* <img className="skill-arrow" onClick={handleRight} src={navRightArrow} alt="right-icon" style={{ cursor: 'pointer' }} /> */}
        </Stack>
        {tagsLoading && <HorizontalLoader style={{ height: '20px' }} />}
        <Chip
          label={<AddIcon style={{ height: '1rem', width: '1rem' }} />}
          className="chip"
          style={{ color: 'black', width: '5rem', borderRadius: '0.5rem' }}
          onClick={(e) => setIsPopup(isPopup ? null : e.currentTarget)}
        />
        <AddItemPopup
          anchorEl={isPopup}
          placeholder="Enter Tag"
          btnContent="Add Tag"
          onClose={() => setIsPopup(false)}
          setItem={setTag}
          item={tag}
          onClick={() => addHashTag()}
        />
      </Grid>
      <Grid className="tag-container">
        {selectedChips?.map((data) => (
          <Chip
            key={data.id}
            label={data.attributes.hashtag}
            style={{ color: 'black', background: `${data.color}` }}
            className="chip"
            deleteIcon={
              <IconButton>
                <CloseIcon id="close-icon" />
              </IconButton>
            }
            onDelete={() => handleUnselectChip(data)}
          />
        ))}
      </Grid>
    </Grid>
  );
};

export default HashTag;
