import React, { useEffect, useRef, useState } from 'react';
import { CircularProgress, Grid, Typography, useMediaQuery } from '@mui/material';
import { uploadFile } from 'react-s3';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import { collectiblesConfigs } from '../../../../config';
window.Buffer = window.Buffer || require('buffer').Buffer;

const ImageUpload = ({ setFileSize, imgUrl, setImgUrl, setFileExt }) => {
  const [file, setFile] = useState({});
  const [progress, setProgress] = useState(0);

  const matches = useMediaQuery('(max-width: 1024px)');
  const hiddenFileInput = useRef(null);

  const handleClick = (e) => {
    hiddenFileInput.current.click();
  };

  useEffect(() => {
    if (file?.name) setProgress(100);
  }, [file]);

  useEffect(() => {
    const fileUrl = imgUrl;
    const fileExt = fileUrl?.split(/[#?]/)[0].split('.').pop().trim();
    if (fileExt?.length) setFileExt(fileExt);
  }, [imgUrl]);

  const handleImageUpload = (e) => {
    let timer = setInterval(() => {
      setProgress((prevProgress) => (prevProgress >= 100 ? 100 : prevProgress + 10));
    }, 1000);
    const uploadedFile = e.target.files[0];
    uploadFile(uploadedFile, collectiblesConfigs)
      .then((res) => {
        setImgUrl(res.location);
        setFileSize(getFileSizeInMB(uploadedFile.size));
        setFile(uploadedFile);
      })
      .catch((e) => {
        clearInterval(timer);
        setProgress(0);
      });
  };

  return (
    <Grid sx={{ display: 'flex', flexDirection: 'column', gap: '4rem' }} onClick={handleClick}>
      <Typography sx={{ fontSize: '1.5rem', textAlign: `${matches ? 'center' : ''}` }}>Upload image</Typography>
      <Grid className="image-upload">
        <CircularProgress
          value={progress}
          variant="determinate"
          thickness={1.5}
          className="circular-progress"
          style={{ color: 'linear-gradient(to right, red , yellow) !important' }}
        />
        <input type="file" name="file" style={{ display: 'none' }} ref={hiddenFileInput} onChange={handleImageUpload} />
        {!file?.name ? (
          <UploadFileIcon sx={{ height: '1.75em', width: '1.75em', zIndex: '100' }} />
        ) : (
          <FilePresentIcon sx={{ height: '1.75em', width: '1.75em' }} />
        )}
        <Typography sx={{ textAlign: 'center', width: '5rem', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
          {file?.name ? file.name : 'File Name'}
        </Typography>
        <Typography
          sx={{ textAlign: 'center', width: '5rem', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', fontSize: '0.75rem' }}
        >
          {progress} % ready
        </Typography>
      </Grid>
    </Grid>
  );
};

const getFileSizeInMB = (size) => {
  const sizeInMB = size / 1000000;
  return +parseFloat(sizeInMB).toFixed(2);
};

export default ImageUpload;
