import React from 'react';
import '../../../../assets/scss/welcome-modal/welcome-modal-content1.scss';
import HAND from '../../../../assets/images/hand.svg';

const WelcomeModal1 = ({ setWelcomeModalData, welcomeModalData }) => {
  return (
    <div className="welcome-modal-content1">
      <div className="welcome-modal-header">
        <h1 className="heading">Welcome to Creatverse</h1>
        <img src={HAND} alt="HAND" className="hand-wave-icon" />
      </div>
      <p className="tell-us-more">Tell us more about yourself</p>

      <div className="about-me-container">
        <p className="about-me-text">
          About me <span>*</span>{' '}
        </p>
        <textarea
          className="about-me-text-area"
          value={welcomeModalData?.AboutUs}
          onChange={(e) => setWelcomeModalData({ ...welcomeModalData, AboutUs: e.target.value })}
        />
      </div>
    </div>
  );
};
export default WelcomeModal1;
