import ARUN from '../../assets/images/arun.png';
import RAHUL from '../../assets/images/rahul.png';
import KUMAR from '../../assets/images/kumar.png';
import PIN from '../../assets/images/pin.png';
import STAR from '../../assets/images/star.png';
import LOCK from '../../assets/images/lock.png';
import IMG0 from '../../assets/images/img_0.png';
import IMG1 from '../../assets/images/img_1.png';
import IMG2 from '../../assets/images/img_2.png';
import BABINCHAT from '../../assets/images/BABIN_CHAT_IMG.png';
import KAVINCHAT from '../../assets/images/KAVIN_CHAT_IMG.png';
import MITHRA from '../../assets/images/MITHRA_CHAT_IMG.png';

import SARANYA from '../../assets/images/SANJANA_CHAT_IMG.png';
import deleteIcon from '../../assets/images/DeleteIconTeam.svg';
import editIcon from '../../assets/images/EditIconTeam.svg';

export const OPTIONS_LIST = [
  {
    id: 1,
    label: 'drafts',
    style: {
      color: '#7C7EF7',
      marginRight: '1rem',
    },
  },
  {
    id: 2,
    label: 'archieved',
    style: {
      color: '#52CAED',
      marginRight: '1rem',
    },
  },
  {
    id: 3,
    label: 'trash',
    style: {
      color: '#FE98BF',
      marginRight: '1rem',
    },
  },
];

export const MEMBERS_LIST = [
  {
    id: 1,
    name: 'Rahul',
    message: 'Hey! How you doing?',
    count: 3,
    pin: PIN,
    color: '#EAF9E6',
    src: RAHUL,
    countClr: '#52CAED',
    badge: true,
  },
  {
    id: 2,
    name: 'Arun',
    message: 'Sure! I’ll be there.',
    time: 9.34,
    color: '#F2F2FF',
    src: ARUN,
  },
  {
    id: 3,
    name: 'Naveen',
    message: '8 images',
    count: 8,
    color: '#FDF0D8',
    src: RAHUL,
    countClr: '#F8C56C',
    badge: true,
  },
  {
    id: 4,
    live: true,
    color: '#8C8EF8',
  },
];

export const CONTRIBUTERS = [
  { id: 1, label: 'rahul' },
  { id: 2, label: 'arun' },
];

export const HEADER_LIST = [
  {
    id: 1,
    options: [
      {
        id: 1,
        label: 'Members',
        style: { marginRight: '0.5rem', color: '#1e152f' },
      },
      { id: 2, label: 8, isNum: true },
    ],
    accordians: [
      {
        id: 1,
        images: {
          front: [KUMAR],
          last: [],
        },
        label: 'Rahul Kumar',
        subLabel: 'Creator',
        imgStyle: { height: '1.75rem', width: '1.75rem' },
        style: { marginRight: '0.5rem' },
        isMem: true,
      },
      {
        id: 2,
        images: {
          front: [RAHUL],
        },
        label: 'Saravanan',
        imgStyle: { height: '1.75rem', width: '1.75rem' },
        style: { marginRight: '0.5rem' },
        isMem: true,
      },
      {
        id: 3,
        images: {
          front: [ARUN],
        },
        label: 'Naveen',
        imgStyle: { height: '1.75rem', width: '1.75rem' },
        style: { marginRight: '0.5rem' },
        isMem: true,
      },
    ],
  },
  {
    id: 2,
    options: [
      {
        id: 1,
        label: 'Media',
        style: { marginRight: '2.3rem' },
        clickable: true,
      },
      {
        id: 2,
        label: 'Files',
        style: { marginRight: '2.3rem' },
        clickable: true,
      },
      {
        id: 3,
        label: 'Links',
        style: { marginRight: '2.3rem' },
        clickable: true,
      },
    ],
    accordians: [
      {
        id: 1,
        images: {
          front: [IMG0, IMG1, IMG2],
        },
        imgStyle: { height: '4.375rem', width: '4.375rem' },
        style: { marginRight: '0.5rem' },
        isGallery: true,
      },
    ],
  },
  {
    id: 3,
    options: [
      {
        id: 1,
        label: 'Tasks',
        style: { marginRight: '0.5rem', color: '#1e152f' },
      },
      { id: 2, label: 20, isNum: true },
    ],
    accordians: [
      {
        id: 1,
        check: true,
        images: {
          front: [STAR],
          last: [LOCK],
        },
        label: 'Design System',
        imgStyle: { height: '1.125rem', width: '1.125rem' },
        style: { marginRight: '0.5rem' },
      },
      {
        id: 2,
        check: true,
        images: {
          front: [STAR],
        },
        label: 'Dark Mode',
        imgStyle: { height: '1.125rem', width: '1.125rem' },
        style: { marginRight: '0.5rem' },
      },
      {
        id: 3,
        check: true,
        images: {
          front: [STAR],
        },
        label: 'New Content Page',
        imgStyle: { height: '1.125rem', width: '1.125rem' },
        style: { marginRight: '0.5rem' },
      },
    ],
    isTask: true,
  },
];

export const SEARCHBAR_NAMES = [
  {
    id: 1,
    name: 'Logesh',
    src: 'LOGESH_SB',
  },
  {
    id: 2,
    name: 'Saran',
    src: 'SARAN_SB',
  },
  {
    id: 3,
    name: 'Vignesh',
    src: 'VIGNESH_SB',
  },
  {
    id: 4,
    name: 'Saranya',
    src: 'SARANYA_SB',
  },
  {
    id: 5,
    name: 'Mithra',
    src: 'MITHRA_SB',
  },
  {
    id: 6,
    name: 'Rahul',
    src: 'RAHUL_SB',
  },
  {
    id: 7,
    name: 'Rahul',
    src: 'RAHUL_SB',
  },
];

export const BRAND_ACCOUNTS = [
  {
    name: 'Behance',
    src: 'BRAND_1',
  },
  {
    name: 'Dribble',
    src: 'BRAND_2',
  },
  {
    name: 'ArtStation',
    src: 'BRAND_3',
  },
  {
    name: 'Youtube',
    src: 'BRAND_4',
  },
  {
    name: 'Letterboxd',
    src: 'BRAND_5',
  },
  {
    name: 'Custom URL',
    src: 'BRAND_6',
  },
  {
    name: 'Spotify',
    src: 'BRAND_7',
  },
  {
    name: 'Imdb',
    src: 'BRAND_8',
  },
];

export const SKILLS_AND_EXPERIENCE = [
  {
    name: 'Dancer',
    color: '#F8C56C',
  },
  {
    name: 'Artist',
    color: '#FE98BF',
  },
  {
    name: 'Singer',
    color: '#7C7EF7',
  },
  {
    name: 'Photographer',
    color: '#52CAED',
  },
  {
    border: '1px dotted #BCBDE9',
  },
];

export const TOKEN_PRICE = [
  { token: '6060', price: '900' },
  { token: '15,151', price: '8,900' },
  { token: '30,303', price: '2,250' },
  { token: '60,606', price: '17,900' },
  { token: '121,212', price: '4,800' },
];

export const CREATO_COIN_BUYERS = [
  { name: 'A R Rahman', creato: '6000 CREATO' },
  { name: 'Richad Branson', creato: '5000 CREATO' },
  { name: 'Rajini', creato: '2500 CREATO' },
  { name: 'Dhoni', creato: '1300 CREATO' },
];

export const HOME_CHAT_CONTENT = [
  { id: 1, name: 'Rahul Kumar', src: 'RAHUL_KUMAR_CHAT_IMG', message: 'Hey, How you doing?', week: '3w', pin: true },
  { id: 2, name: 'Naveen', src: 'NAVEEN_CHAT_IMG', message: 'can you send me the attachments?', week: '6w' },
  { id: 3, name: 'Vignesh', src: 'VIGNESH_CHAT_IMG', message: 'you:send an attachment?', week: '20w', seen: 'VIGNESH_SEEN' },
  { id: 4, name: 'Mithra', src: 'MITHRA_CHAT_IMG', message: 'Hi,Arun! Please let me Know ho...', week: '23w' },
  { id: 5, name: 'Sanjana', src: 'SANJANA_CHAT_IMG', message: 'you:here is my contact number 99...', week: '30w', seen: 'SANJANA_SEEN' },
  { id: 6, name: 'Babin', src: 'BABIN_CHAT_IMG', message: "Yo! I'm babin here. I'm here to info...", week: '31w' },
  { id: 7, name: 'Prakash', src: 'PRAKASH_CHAT_IMG', message: 'Would you like to join our group Wh...', week: '37w' },
  { id: 8, name: 'Mustafah', src: 'MUSTAFAH_CHAT_IMG', message: 'can you send me the images of ystry', week: '37w' },
  { id: 9, name: 'Kavin', src: 'KAVIN_CHAT_IMG', message: 'Send me link of your project', week: '37w' },
  { id: 10, name: 'Jeffina', src: 'JEFFINA_CHAT_IMG', message: 'you:take a look of these images', week: '37w', seen: 'JEFFINA_SEEN' },
  { id: 11, name: 'Priya', src: 'PRIYA_CHAT_IMG', message: 'join this like http://designmeet6g7d7d', week: '37w' },
];

//HASH_TAG_CHIPS
export const HashTags = [
  { name: 'SUN', color: 'orange', id: 1 },
  { name: 'SKY', color: 'blue', id: 2 },
  { name: 'NATURE', color: 'pink', id: 3 },
  { name: 'BEACH', color: 'aqua', id: 4 },
  { name: 'RESORT', color: 'aquamarine', id: 5 },
];

//POST_DATA
export const POSTS = [
  { name: 'Mahesh Kumar', stars: 2.5, location: 'Chennai', timeLeft: '30 minutes', img: 'maheshkumar' },
  // { name: 'Vignesh', stars: 3.5, location: 'Banaglore', timeLeft: '10 minutes', img: 'VIGENSH_SB' },
  { name: 'Logesh', stars: 4, location: 'Delhi', timeLeft: '1 hour 20 minutes', img: 'LOGESH_SB' },
  { name: 'Saranya', stars: 3, location: 'Hyderabad', timeLeft: '50 minutes', img: 'SARANYA_SB' },
];

//PROFILE_HEADERS
export const PROFILE_HEADERS = ['Profile', 'Interest', 'Team', 'Billing', 'Store'];

//USER_TYPE
export const USER_TYPE = ['creator', 'mentor', 'fan'];

export const FOLLOWERS_LIST = [
  {
    id: 1,
    src: 'LOGESH_SB',
  },
  {
    id: 2,
    src: 'SARAN_SB',
  },
  {
    id: 3,
    src: 'VIGNESH_SB',
  },
];

export const COMMUNITY_ICONS = [
  {
    id: 1,
    src: 'phone',
    background: '#ECECFE',
  },
  {
    id: 2,
    src: 'message',
    background: '#EDF9FD',
  },
  {
    id: 3,
    src: 'wallet',
    background: '#FDF0D8',
  },
  {
    id: 4,
    src: 'MULTIPLE_PEOPLE',
    background: '#ECECFE',
  },
  {
    id: 5,
    src: 'ADD_FRIEND',
    background: '#EDF9FD',
  },
  {
    id: 6,
    src: 'DOLLOR_SMALL',
    background: '#FDF0D8',
  },
  {
    id: 7,
    src: 'DATA_SHARING',
    background: '',
  },
];

export const TAGS = [
  {
    id: 1,
    label: 'Wedding',
    color: '#7C7EF7',
  },
  {
    id: 2,
    label: 'Wildlife',
    color: '#FFC35A',
  },
  {
    id: 3,
    label: 'Events',
    color: '#52CAED',
  },
];
export const GROUPS = [
  { id: 1, label: 'COMMUNITY_GROUP_ICON_1' },
  { id: 2, label: 'COMMUNITY_GROUP_ICON_2' },
  { id: 3, label: 'COMMUNITY_GROUP_ICON_3' },
  { id: 4, label: 'COMMUNITY_GROUP_ICON_4' },
  { id: 5, label: 'COMMUNITY_GROUP_ICON_5' },
  { id: 6, label: 'COMMUNITY_GROUP_ICON_6' },
];
export const SKILLS = ['#52CAED', '#7C7EF7', '#FFC35A', '1px dotted #AAA9A9'];

export const EMOJIS = [
  {
    label: 'like',
    emoji: String.fromCodePoint(0x1f44d),
  },
  {
    label: 'love',
    emoji: String.fromCodePoint(0x1f60d),
  },
  {
    label: 'haha',
    emoji: String.fromCodePoint(0x1f606),
  },
  {
    label: 'wow',
    emoji: String.fromCodePoint(0x1f62e),
  },
  {
    label: 'sad',
    emoji: String.fromCodePoint(0x1f622),
  },
  {
    label: 'angry',
    emoji: String.fromCodePoint(0x1f621),
  },
];

export const YEARS_OF_EXPERIENCE = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39,
  40,
];

export const NEG_COLORS = ['ffffff', '000000'];
export const TAG_COLORS = ['hsl(0, 0%, 100%)', 'hsl(0, 0%, 0%)'];
export const PORTFOLIO_IMAGES = [
  { id: 1, src: 'PORTFOLIO_IMAGE_1' },
  { id: 2, src: 'PORTFOLIO_IMAGE_2' },
  { id: 3, src: 'PORTFOLIO_IMAGE_3' },
  { id: 4, src: 'PORTFOLIO_IMAGE_4' },
  { id: 5, src: 'PORTFOLIO_IMAGE_5' },
];

export const TEAM_ADMIN_USERS = [
  {
    id: 1,
    img: ARUN,
    name: 'Arun Kumar',
    email: '@arunkumar6654.gmailcom',
    date: 'Feb 22,2021',
    lastActiveDate: 'Mar 23,2022',
  },
  {
    id: 2,
    img: BABINCHAT,
    name: 'Naveen',
    email: '@naveen6654.gmailcom',
    date: 'Mar 15,2021',
    lastActiveDate: 'Feb 15,2022',
  },
  {
    id: 3,
    img: KAVINCHAT,
    name: 'Akash',
    email: '@akash6654.gmailcom',
    date: 'Jan 20,2021',
    lastActiveDate: 'Jan 16,2022',
  },
  {
    id: 4,
    img: MITHRA,
    name: 'Mihtra',
    email: '@mithra6654.gmailcom',
    date: 'Seb 26,2021',
    lastActiveDate: 'Mar 05,2022',
  },
  {
    id: 5,
    img: SARANYA,
    name: 'Saranya',
    email: '@saranya6654.gmailcom',
    date: 'Jan 06,2021',
    lastActiveDate: 'Mar 02,2022',
  },
];

export const MALE = 'male';
export const FEMALE = 'female';
