import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Box, IconButton, Menu, MenuItem, Button as MuiButton, Stack, Typography } from '@mui/material';
import 'react-calendar/dist/Calendar.css';
import ChatPage from '../../components/chat';
import HomeRightSide from './HomeRightSide';
import HomeRightSideChat from './HomeRightSideChat';
import NotificationModal from './NotificationModal';
import UserProfile from '../user-profile';
import { SEARCHBAR_NAMES } from '../../utils/constants';
import UploadImageModal from '../../utils/widgets/modals/upload-image';
import PostCard from '../../utils/widgets/post-card';
import Button from '../../utils/widgets/Button';
import MenuIcon from '@mui/icons-material/Menu';
import { Loader } from '../../utils/widgets/Loader';
import SharePostModal from '../../utils/widgets/modals/SharePostModal';
import { usePreferredState } from '../../utils/customHooks';
import { setSelectedProfile } from '../../state/user';
import { getAllPosts } from '../../state/user/actions';
import creato_wallet from '../../assets/images/creato_wallet.png';
import SearchIcon from '../../assets/images/searchIcon.png';
import vectorImg from '../../assets/images/Vector.jpg';
import settingIcon from '../../assets/images/setting.png';
import DOLLOR_MINI from '../../assets/images/DOLLOR_MINI.svg';
import DIAMOND from '../../assets/images/DIAMOND.png';
import NOTIFICATION_BELL from '../../assets/images/notification_bell.png';
import NOTIFICATION_TRIANGLE from '../../assets/images/NOTIFICATION_TRIANGLE.png';
import NOTIFICATION_BELL_WITHOUT_NOTIFICATION from '../../assets/images/NOTIFICATION_BELL_WITHOUT_NOTIFICATION.png';
import '../../assets/scss/home.scss';
import PLUS from '../../assets/images/plus.png';
import CHAT from '../../assets/images/home-chat.png';
import img1 from '../../assets/images/Rectangle 1047.jpg';
import { Carousel } from 'react-responsive-carousel';
import img3 from '../../assets/images/Rectangle 1036.jpg';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import SideBar from '../../components/post-connect/side-bar';
import useMediaQuery from '@mui/material/useMediaQuery';
import styled from '@emotion/styled';

const Home = () => {
  const images = [img1, img3];
  const [showChat, setShowChat] = useState(false);
  const [selectedPage, setSelectedPage] = useState('Home');
  const [homeRight, setHomeRight] = useState(true);
  const [hideRight, setHideRight] = useState(true);
  const [userData, setUserData] = useState({});
  const [uploadImage, setUploadImage] = useState(false);
  const [postsState, setPostsState] = useState({ noPosts: false, loading: false });
  const [category, setCategory] = useState('All');
  const [likedPosts, setLikedPosts] = useState([]);
  const [notificationModal, setNotificationModal] = useState(false);
  const [sharePost, setSharePost] = useState({ postId: null, show: false });
  const [anchorEl, setAnchorEl] = useState(null);

  const matches = useMediaQuery('(min-width:720px)');

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const { notifications, currentUser, isGuestUser, selectedProfile, postData } = usePreferredState('user');
  const profileImg = currentUser.displayPic.includes('https') ? currentUser.displayPic : `data:image/svg+xml;base64,${currentUser?.displayPic}`;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const hasNotifications = Boolean(Object.keys(notifications).length);
  const filteredPost = category === 'Likes' ? postData.filter((post) => likedPosts.includes(post.id)) : postData;

  useEffect(() => {
    if (postData.length) {
      const likedPostIds = [];
      postData.forEach((post) => {
        let hasLike = post.attributes.likedUsers.data.some((user) => user.id === currentUser.id);
        if (hasLike) likedPostIds.push(post.id);
      });
      setLikedPosts(likedPostIds);
    }
  }, [postData]);

  useEffect(() => {
    if (filteredPost.length === 0) setPostsState({ loading: false, noPosts: true });
    else setPostsState({ loading: false, noPosts: false });
  }, [filteredPost.length]);

  useEffect(() => {
    setPostsState({ ...postsState, loading: true });
    dispatch(
      getAllPosts((success) => {
        if (success) setPostsState({ ...postsState, loading: false });
        else setPostsState({ noPosts: true, loading: false });
      }),
    );
  }, []);

  return (
    <div className="container">
      {selectedProfile ? (
        <UserProfile currentUser={currentUser} />
      ) : (
        <div className="container1">
          <div className="header-container">
            <div className="notification-bell-container">
              <img
                src={!hasNotifications ? NOTIFICATION_BELL_WITHOUT_NOTIFICATION : NOTIFICATION_BELL}
                alt="notification_bell_without_notification"
                className="notification-bell"
                style={{ backgroundColor: notificationModal ? 'white' : '' }}
                onClick={() => (hasNotifications ? setNotificationModal(!notificationModal) : {})}
              />
              {notificationModal && <img src={NOTIFICATION_TRIANGLE} alt="NOTIFICATION_TRIANGLE" className="notification-triangle" />}
              {notificationModal && <NotificationModal notifications={notifications} setNotificationModal={setNotificationModal} />}
              <div className="search-bar">
                <img src={SearchIcon} alt="search" />
                <input type="text" placeholder="Search" />
              </div>
            </div>
            <div className="icons-container">
              <img src={PLUS} alt="PLUS" className="plus-icon" onClick={() => dispatch(setSelectedProfile(currentUser.id))} />
              <span className="chat-icon-container">
                <img src={CHAT} alt="CHAT" className="chat-icon" onClick={() => (hasNotifications ? setNotificationModal(!notificationModal) : {})} />
                {hasNotifications && <span className="chat-icon-badge"></span>}
              </span>
              {notificationModal && <img src={NOTIFICATION_TRIANGLE} alt="NOTIFICATION_TRIANGLE" className="notification-triangle" />}
              {notificationModal && <NotificationModal notifications={notifications} setNotificationModal={setNotificationModal} />}
            </div>
            {matches ? (
              <ul>
                {['Home', 'Community', 'Chat', 'Mentors', 'Wallet', 'Activity'].map((item, i) =>
                  item === 'Community' ? (
                    <li
                      key={i}
                      className={`${selectedPage === item ? 'selected' : ' '}`}
                      onClick={() => {
                        setSelectedPage(item);
                        navigate('/community');
                      }}
                    >
                      <p className="container-margin">{item}</p>
                      <div className="navigate"></div>
                    </li>
                  ) : (
                    <li
                      key={i}
                      className={`${selectedPage === item ? 'selected' : 'select'}`}
                      // onClick={() => {
                      //   setSelectedPage(item);
                      // }}
                    >
                      <p className="container-margin">{item}</p>
                    </li>
                  ),
                )}
              </ul>
            ) : (
              <div className="menu-container">
                <MuiButton
                  id="basic-button"
                  aria-controls={open ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  onClick={handleClick}
                  className="menu-mui-btn"
                >
                  <MenuIcon />
                </MuiButton>
                <CustomMenu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                  className="menu-item-wrap"
                >
                  {['Home', 'Community', 'Chat', 'Mentors', 'Wallet', 'Activity'].map((item, i) =>
                    item === 'Community' ? (
                      <CustomMenuItem
                        onClick={() => {
                          setSelectedPage(item);
                          navigate('/community');
                        }}
                        className="menu-item"
                        key={`${item}_${i}`}
                      >
                        {item}
                      </CustomMenuItem>
                    ) : (
                      <CustomMenuItem className="menu-item" key={`${item}_${i}`}>
                        {item}
                      </CustomMenuItem>
                    ),
                  )}
                </CustomMenu>
              </div>
            )}
            <h2 id="app-title" style={{ color: '#1E152F', textAlign: 'center' }}>
              CREATVERSE
            </h2>
            {!isGuestUser && (
              <>
                <div className="creato-wallet-container">
                  <img src={creato_wallet} alt="creato_wallet" style={{ cursor: isGuestUser ? 'not-allowed' : 'pointer' }} />
                  <Typography className="creato-wallet-container-text">
                    <b>{currentUser?.reward ? currentUser.reward : 0}</b> CREATO
                  </Typography>
                </div>
                <div className="creato-wallet-container-mb">
                  <img src={DOLLOR_MINI} alt="DOLLOR_MINI" className="creato-wallet-mb-dollor-icon" />
                  <Typography className="creato-wallet-mb-text">{currentUser?.reward ? currentUser.reward : 0}</Typography>
                </div>
              </>
            )}
          </div>
          {/* stories */}
          {/* <div className="header2-container"> */}
          {/* {SEARCHBAR_NAMES.map((item, i) => (
              <div className="search-image-container" key={i}>
                <div className="image-container">
                  <img src={require(`../../assets/images/${item.src}.jpg`)} alt={item.src} />
                </div>
                <p>{item.name}</p>
              </div>
            ))} */}
          {/* </div> */}
          <div className="home-content-area">
            <Stack className="left-container" spacing={2}>
              <div className="Whats-up-container">
                <div className="Whats-up-container-left" onClick={() => dispatch(setSelectedProfile(currentUser.id))}>
                  <img src={profileImg} alt="profile" />
                </div>
                <div className="Whats-up-container-right">
                  <p>
                    <b>Hey Folks!</b>
                  </p>
                  <h3>What's Up</h3>
                </div>
              </div>
              <Stack className="left-container-events" spacing={2}>
                <p>
                  <b>Nearest Events </b>
                </p>
                <Carousel showArrows={false} showIndicators={false} showThumbs={false} showStatus={false} autoPlay infiniteLoop swipeable>
                  <div className="left-side-image-container">
                    <img id="event-img" src={img1} alt="NearestEventImg1" />
                  </div>
                  <div className="left-side-image-container">
                    <img id="event-img" src={img3} alt="NearestEventImg1" />
                  </div>
                  <div className="left-side-image-container">
                    <img id="event-img" src={img1} alt="NearestEventImg1" />
                  </div>
                  <div className="left-side-image-container">
                    <img id="event-img" src={img3} alt="NearestEventImg1" />
                  </div>
                </Carousel>
                <p>
                  <b style={{ color: ' gray', cursor: 'not-allowed' }}>Discover&nbsp;|&nbsp;Trending</b>
                </p>
                {['Rectangle 1047', 'Rectangle 1036', 'Rectangle 1047', 'Rectangle 1036'].map((item, i) => (
                  <img key={`${item}_${i}`} id="event-img" src={require(`../../assets/images/${item}.jpg`)} alt={item} />
                ))}
              </Stack>
            </Stack>

            <div className="right-container-scroll">
              <div className="right-container">
                <div className="status-container">
                  <div className="status-container-top">
                    <div className="status-container-top1 ">
                      <img src={profileImg} alt="displayPic" onClick={() => dispatch(setSelectedProfile(currentUser.id))} />
                    </div>
                    <div className="status-container-top2 ">
                      <div className="color-squares">
                        {/* Color badges */}
                        {/* {[1, 2, 3, 4, 5, 6].map((item) =>
                          item == 6 ? (
                            <div className={`square${item}`} key={item}>
                              +
                            </div>
                          ) : (
                            <div className={`square${item}`} key={item}></div>
                          ),
                        )} */}
                      </div>

                      <div className="status-bar">
                        <input type="text" placeholder="What’s on your mind ?" disabled={isGuestUser} />
                        <select className="everyone-select" disabled={isGuestUser} style={{ cursor: isGuestUser ? 'not-allowed' : 'pointer' }}>
                          <option>Everyone</option>
                          <option>Friends</option>
                          <option>Private</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="status-container-bottom">
                    <div className="status-container-bottom-left">
                      {/* <p className="plus18">
                        <b>18+</b>
                      </p> */}
                      <select className="article" disabled={isGuestUser} style={{ cursor: isGuestUser ? 'not-allowed' : 'pointer' }}>
                        <option value="Article">Article</option>
                        <option value="Magazine">Magazine</option>
                        <option value="Poem">Poem</option>
                      </select>
                      <select
                        className="choose-community"
                        defaultValue={'Choose Community'}
                        disabled={isGuestUser}
                        style={{ cursor: isGuestUser ? 'not-allowed' : 'pointer' }}
                      >
                        <option value={'Choose Community'}>Choose Community</option>
                        <option value="Vlogger">Vlogger</option>
                        <option value="Model">Model</option>
                        <option value="Photographer">Photographer</option>
                      </select>
                    </div>
                    <div className="status-container-bottom-right">
                      <img src={DOLLOR_MINI} alt="DOLLOR_MINI" className="dollor-image" />
                      <div className="switch">
                        <input className="react-switch-checkbox" id={`react-switch-new`} type="checkbox" disabled={isGuestUser} />
                        <label
                          className="react-switch-label"
                          htmlFor={`react-switch-new`}
                          style={{ cursor: isGuestUser ? 'not-allowed' : 'pointer' }}
                        >
                          <span className={`react-switch-button`} />
                        </label>
                      </div>
                      <Button
                        content="Post"
                        style={{ padding: '0.25rem 2rem', borderRadius: '1rem' }}
                        onClick={() => setUploadImage(true)}
                        disabled={isGuestUser}
                      />
                    </div>
                  </div>
                  <div className="diamond-icon-container">
                    <img src={DIAMOND} alt="DIAMOND" />
                  </div>
                </div>
                <div className="pages-selector">
                  <ul>
                    <li onClick={() => setCategory('All')} className="page-selector-li" style={{ color: `${category === 'All' ? 'black' : ''}` }}>
                      All
                      <div className={`under ${category === 'All' ? 'selected' : ''}`} />
                    </li>
                    <li style={{ cursor: 'not-allowed', display: !matches ? 'none' : 'block' }}>Followings</li>
                    <li onClick={() => setCategory('Likes')} className="page-selector-li" style={{ color: `${category === 'Likes' ? 'black' : ''}` }}>
                      Likes
                      <div className={`under ${category === 'Likes' ? 'selected' : ''}`} />
                    </li>
                    <li style={{ cursor: 'not-allowed', display: !matches ? 'none' : 'block' }}>My NFTs</li>
                  </ul>
                  <img
                    src={settingIcon}
                    id="settingIcon"
                    alt="settingIcon"
                    // style={{ cursor: isGuestUser ? 'not-allowed' : 'pointer' }}
                    style={{ cursor: 'not-allowed', display: !matches ? 'none' : 'block' }}
                  />
                </div>
                {filteredPost.map((post, i) => (
                  <PostCard key={i} post={post} isLiked={likedPosts.includes(post.id)} setSharePost={setSharePost} />
                ))}
                {!postsState.loading && postsState.noPosts && (
                  <h1 style={{ textAlign: 'center' }} id="no-data">
                    No Posts...
                  </h1>
                )}
                {postsState.loading && !postsState.noPosts && <Loader />}
                <SideBar className="side-bar-mb mb" />
              </div>
            </div>
          </div>
        </div>
      )}
      <div className={hideRight ? 'container2-scroll ' : 'container2-scroll-hide'}>
        <div className="grid-option">
          <img src={vectorImg} alt="vectorImg" onClick={() => setHideRight(!hideRight)} />
        </div>
        {hideRight && <>{homeRight ? <HomeRightSide /> : <HomeRightSideChat setShowChat={setShowChat} setUserData={setUserData} />}</>}
      </div>
      {showChat && <ChatPage onClose={() => setShowChat(false)} userData={userData} />}
      <UploadImageModal open={uploadImage} onClose={() => setUploadImage(false)} />
      {sharePost.show && (
        <SharePostModal
          open={true}
          onClose={() => setSharePost({ postId: null, show: false })}
          title="Hey there! Check this new post on Creatverse."
          subUrl={`post/${sharePost.postId}`}
        />
      )}
    </div>
  );
};

export default Home;

const CustomMenu = styled(Menu)`
  padding: 0;
`;
const CustomMenuItem = styled(MenuItem)`
  padding: 5px 14px;
`;
