import React from 'react';

const ToggleButton = ({ selectedMode, setSelectedMOde, options, style, subStyle }) => {
  return (
    <div
      className="toggle-button"
      style={{
        display: 'flex',
        padding: '0.25rem',
        borderRadius: '7.4375rem',
        alignItems: 'center',
        gap: '0.5rem',
        height: '2.5rem',
        width: '12.5rem',
        ...style,
      }}
    >
      {options.map((option,i) => (
        <span
        key={i}
          onClick={() => setSelectedMOde(option)}
          style={{
            width: '50%',
            padding: '0.25rem',
            textAlign: 'center',
            color: '#636B76',
            borderRadius: '1.1875rem',
            cursor: 'pointer',
            ...(selectedMode === option && {
              background: `linear-gradient(107.9deg, #C7C8FF -42.27%, #7C7EF7 53.1%)`,
              color: '#FFFFFF',
            }),
            ...subStyle,
          }}
        >
          {option}
        </span>
      ))}
    </div>
  );
};

export default ToggleButton;
