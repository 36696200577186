const config = {
  bucketName: process.env.REACT_APP_AWS_BUCKET_NAME ?? null,
  region: process.env.REACT_APP_AWS_REGION ?? null,
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY ?? null,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY ?? null,
};

export const itemsConfigs = {
  ...config,
  dirName: 'items',
};

export const collectiblesConfigs = {
  ...config,
  dirName: 'collectibles',
};
// for profile image portfolio image
export const welcomeModalProfileImage ={
  ...config,
  dirName: 'WelcomeProfileImage'
}

export const welcomeModalPortfolioImage ={
  ...config,
  dirName: 'WelcomePortfolioImage'
}
// 
export const communityCoverConfig = {
  ...config,
  dirName: 'communitycover',
};

export const communityImgConfig = {
  ...config,
  dirName: 'communityImg',
};

export const tokensConfigs = {
  ...config,
  dirName: 'tokens',
};

export const userConfigs = {
  ...config,
  dirName: 'users',
};
