import {chartData} from './data';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faGlobe,faCalendarDays,faSortDown,faCaretRight} from '@fortawesome/free-solid-svg-icons';
//import "./sidesection.css";

function SideSection() {
  return (
    <div className='sidebar'>
        <div className='analytics'>
            <div className='analyticsHead'>
            <h1>Analytics</h1>
            </div>
            <div className='analyticsChartContainer'>
                <div className='analyticsChart'>
                    <div className='analyticsChartLeft'>
                        <ul>
                            <li>80%</li>
                            <li>60%</li>
                            <li>40%</li>
                            <li>0%</li>
                        </ul>
                        <div></div>
                    </div>
                    <div className='analyticsChartRight'>
                        {chartData.map(item=>(
                            <div key={item.id} className='chartBarContainer'>
                            <ul>
                                <li style={{height:`${item.bar1}%`}}></li>
                                <li style={{height:`${item.bar2}%`}}></li>
                            </ul>
                            <p>{item.day}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className="chartPointerContainer">
                <div className="chartArrowPointerContainer">
                <FontAwesomeIcon icon={faCaretRight} style={{color:'gray'}}/>
                </div>
                <p className='chart-pointer-tex'>chart-pointer</p>
            </div>
        </div>
        <div className='sidebarDescription'>
            <h1 className='addDesc'>Add Description</h1>
            <h1 className='addDescTitle'>New NFT Characters Builder System</h1>
            <div className='mockupDetailsContainer'>
                <div className='mockupImage'>
                    <img src="./images/mock.jpg" alt="image"/>
                </div>
                <div className='mockupDesc'>
                    <p>Here is a new mockup explanation I've made for nft art dashboard. Hope you like it</p>
                </div>
                <div className='mockupFoot'>
                  <div className='mockupDateContainer'>
                    <div className='mockupDateIcon'>
                    <FontAwesomeIcon icon={faCalendarDays}/>
                    </div>
                    <div className='mockupDateContent'>
                        <p>Jul,20,110.39 PM</p>
                    </div>
                  </div>
                  <div className='mockupCommunityContainer'>
                    <div className='mockupCommunityIcon'>
                        <FontAwesomeIcon icon={faGlobe}/>
                    </div>
                    <div className='mockupCommunityContent'>
                        <p>Community</p>
                    </div>
                  </div>
                  <div className='scheduleButtonContainer'>
                  <button>Schedule</button>
                  </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default SideSection