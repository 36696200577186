import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { strapiClient } from '../../..';
import { CircularProgress } from '@mui/material';
import PostHeadNav from './PostHeadNav';
import PostContent from './PostContent';
import FilterDropDown from './widget/FilterDropdown';
import Button from '../../../utils/widgets/Button';
import { usePreferredState } from '../../../utils/customHooks';
import { Snack } from '../../../utils/widgets/Toast';
import { CREATE_COMMUNITY_POST } from '../../../config/mutations';
import { setIsAnime, setSelectedProfile, updateRewards } from '../../../state/user';
import { updateAccount } from '../../../state/user/actions';
import navLeftArrow from '../../../assets/images/nav-left-arrow.png';
import navRightArrow from '../../../assets/images/nav-right-arrow.png';
import picture from '../../../assets/images/image.png';
import textCircle from '../../../assets/images/text_circle.png';
import edit from '../../../assets/images/Edit.png';
import { Menu, ScoreFilterList, PopularFilterList, DaysFilterList } from '../data';
import 'animate.css';

const PostSection = ({ data }) => {
  const { isGuestUser, currentUser } = usePreferredState('user');
  const dispatch = useDispatch();
  const navigation = useNavigate();

  const [navData, setNavData] = useState(Menu);
  const [navigate, setNavigate] = useState(null);
  const [postTitle, setPostTitle] = useState('');
  const [postBody, setPostBody] = useState('');
  const [communityPosts, setCommunityPosts] = useState([]);
  const [communityLoading, setCommunityLoading] = useState(false);
  const [isNSFW, setIsNSFW] = useState(false);

  const profileImg = currentUser.displayPic.includes('https') ? currentUser.displayPic : `data:image/svg+xml;base64,${currentUser.displayPic}`;
  let navigateClass =
    navigate === 'left' ? 'animate__animated animate__fadeInRight' : navigate === 'right' ? 'animate__animated animate__fadeInLeft' : null;

  const handleRight = () => {
    let res = navData.splice(-1, 1);
    setNavigate('right');
    setNavData([...res, ...navData]);
  };

  const handleLeft = () => {
    let res = navData.splice(0, 1);
    setNavigate('left');
    setNavData([...navData, ...res]);
  };

  const handlePostCommunity = () => {
    const rewards = currentUser.reward ? currentUser.reward + 100 : 100;

    setCommunityLoading(true);
    if (postTitle.trim().length || postBody.trim().length) {
      const variables = {
        data: {
          postType: 'post',
          title: postTitle,
          postBody: postBody,
          community: data.data.id,
          isNSFW,
        },
      };
      strapiClient
        .request(CREATE_COMMUNITY_POST, variables)
        .then((data) => {
          const rewardVar = {
            updateAccountId: currentUser.id,
            data: {
              reward: rewards,
            },
          };
          setCommunityPosts(data?.createCommunityPost?.data?.attributes?.community?.data?.attributes?.community_posts?.data);
          dispatch(updateRewards(rewards));
          dispatch(updateAccount(rewardVar));
          dispatch(setIsAnime({ value: '100', open: true }));
          setPostTitle('');
          setPostBody('');
          setCommunityLoading(false);
          Snack('Post successfully uploaded!', 2000, 'success');
        })
        .catch((e) => {
          console.log('Posting community post error--->', e);
          Snack('Post updation failed!', 2000, 'success');
          setCommunityLoading(false);
        });
    }
  };

  return (
    <div className="post-section-container">
      <div className="post-section-head">
        <div className="nav-left-arrow">
          <img onClick={handleLeft} src={navLeftArrow} alt="left-icon" />
        </div>
        <PostHeadNav navData={navData} navigateClass={navigateClass} setNavigate={setNavigate} />
        <div className="nav-right-arrow">
          <img onClick={handleRight} src={navRightArrow} alt="right-icon" />
        </div>
      </div>
      <div className="add-post-section">
        <div className="add-post-left">
          <img
            src={profileImg}
            alt="avatar"
            onClick={() => {
              dispatch(setSelectedProfile(currentUser.id));
              navigation('/');
            }}
          />
        </div>
        <div style={{ display: 'flex',width:'50%',flexDirection: 'column', gap: '1rem' }}>
          <div className="add-post-center">
            <input placeholder="Title" onChange={(e) => setPostTitle(e.target.value)} disabled={isGuestUser} value={postTitle} />
          </div>
          <div className="add-post-center">
            <input
              placeholder="What's New?"
              className="whatsnew"
              onChange={(e) => setPostBody(e.target.value)}
              disabled={isGuestUser}
              value={postBody}
            />
          </div>
        </div>
        <div className={`add-post-right ${isGuestUser ? 'guest' : ''}`}>
          <img className="cursor" src={picture} alt="icon" />
          <img className="cursor" src={edit} alt="icon" />
          <img className="cursor" src={textCircle} alt="icon" />
        </div>
        <div className="post-btn-cont">
          <p
            onClick={() => (isGuestUser ? {} : setIsNSFW(!isNSFW))}
            className={`eteen-plus-icon ${isNSFW ? 'eteen-plus-active' : ''}`}
            style={{ cursor: !isGuestUser ? 'pointer' : 'not-allowed' }}
          >
            18+
          </p>
          <button className={`post-btn ${isGuestUser ? 'guest' : ''}`} onClick={() => handlePostCommunity()} disabled={isGuestUser}>
            {communityLoading ? <CircularProgress size={20} /> : 'Post'}
          </button>
        </div>
      </div>
      <div className="post-details-section">
        <div className="post-details-head">
          <div className="post-details-left">
            <Button className="btn" content={'Sort'} style={{ padding: '0.5rem 1rem', borderRadius: '0.5rem' }} disabled={isGuestUser} />
            <FilterDropDown name={'Popular'} list={PopularFilterList} disabled={isGuestUser} />
            <FilterDropDown name={'Past 24 hours'} list={DaysFilterList} disabled={isGuestUser} width={'150px'} />
          </div>
          <div className="post-details-right">
            <p>Filter by</p>
            <FilterDropDown name={'Impact Score'} list={ScoreFilterList} disabled={isGuestUser} />
          </div>
        </div>
        <div className="post-details-body">
          {(communityPosts.length > 0 ? communityPosts : data?.data?.attributes?.community_posts?.data).map((data, i, arr) => {
            return <PostContent data={data} key={data.id} length={arr.length} i={i} />;
          })}
        </div>
      </div>
    </div>
  );
};

export default PostSection;
