import CHAT_ICON_1 from '../../../assets/images/CHAT_ICON_1.png';
import CHAT_ICON_2 from '../../../assets/images/CHAT_ICON_2.png';
import CHAT_ICON_3 from '../../../assets/images/CHAT_ICON_3.png';
import CHAT_ICON_4 from '../../../assets/images/CHAT_ICON_4.png';
import MessageIcon from '../../../assets/images/Chat-New.svg';

const ChatHead = (props) => {
  
  const { openChat, setOpenChat, activeUsers, isGuestUser } = props

  const messageIconStyle = {
    width: '25px',
    height: '25px',
  };

  const handleChatContainer = () => {
    if (isGuestUser) return;
    setOpenChat(!openChat);
  };

  const headContainerStyle = {
    height: openChat ? '10%' : 'inherit',
  };

  return (
    <div style={headContainerStyle} className="chat-heading">
      <img src={MessageIcon} style={messageIconStyle} alt="message-icon" className='chat-heading-icon'/>
      <p onClick={handleChatContainer}>Active Chats - {activeUsers}</p>
      {openChat && (
        <div className="chat-icons">
          <img src={CHAT_ICON_1} alt="CHAT_ICON_1" />
          <img src={CHAT_ICON_2} alt="CHAT_ICON_2" onClick={handleChatContainer} />
          <img src={CHAT_ICON_3} alt="CHAT_ICON_3" />
          <img src={CHAT_ICON_4} alt="CHAT_ICON_4" />
        </div>
      )}
    </div>
  );
};

export default ChatHead;
