import { gql } from 'graphql-request';

export const GET_ALL_HASH_TAGS = gql`
  query Query {
    hashtags {
      data {
        id
        attributes {
          hashtag
          posts {
            data {
              attributes {
                owner {
                  data {
                    id
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_ACCOUNT = gql`
  query GetAccount($accountId: ID) {
    account(id: $accountId) {
      data {
        id
        attributes {
          address
          name
          phoneNumber
          userType
          gender
          countryCode
          occupation
          DOB
          expertiseIn
          experience
          isMentor
          isFreelance
          reward
          bio
          coverPic
          skills {
            data {
              id
              attributes {
                colorHex
                skill
                createdAt
              }
            }
          }
          displayPic
          user {
            data {
              id
              attributes {
                username
                email
                provider
                confirmed
                blocked
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_ALL_ACCOUNTS = gql`
  query GetAllAccounts($pagination: PaginationArg, $filters: AccountFiltersInput) {
    accounts(pagination: $pagination, filters: $filters) {
      data {
        id
        attributes {
          address
          name
          phoneNumber
          userType
          gender
          occupation
          DOB
          expertiseIn
          experience
          isMentor
          isFreelance
          reward
          bio
          coverPic
          skills {
            data {
              id
              attributes {
                colorHex
                skill
                createdAt
              }
            }
          }
          user {
            data {
              id
              attributes {
                username
                email
                confirmed
              }
            }
          }
          displayPic
          countryCode
        }
      }
    }
  }
`;

export const GET_ALL_POSTS = gql`
  query Query($pagination: PaginationArg, $sort: [String]) {
    posts(pagination: $pagination, sort: $sort) {
      data {
        id
        attributes {
          description
          owner {
            data {
              id
              attributes {
                name
                displayPic
              }
            }
          }
          mediaUrl
          likeCount
          createdAt
          updatedAt
          comments {
            data {
              id
              attributes {
                comment
                user {
                  data {
                    id
                    attributes {
                      name
                      displayPic
                    }
                  }
                }
              }
            }
          }
          hashtags {
            data {
              id
              attributes {
                hashtag
              }
            }
          }
          likedUsers {
            data {
              id
              attributes {
                name
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_POST_COMMENTS = gql`
  query GET_COMMENTS($pagination: PaginationArg, $sort: [String], $filters: CommentFiltersInput) {
    comments(pagination: $pagination, sort: $sort, filters: $filters) {
      data {
        id
        attributes {
          comment
          post {
            data {
              id
            }
          }
          user {
            data {
              id
              attributes {
                name
                displayPic
              }
            }
          }
          createdAt
        }
      }
    }
  }
`;

export const GET_ALL_TOKENS = gql`
  query getAllTokens($pagination: PaginationArg) {
    tokens(pagination: $pagination) {
      data {
        id
        attributes {
          name
          symbol
          supply
          about
          description
          tokenPic
          erc20Address
          creator {
            data {
              id
              attributes {
                name
                phoneNumber
                address
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_COMMUNITIES = gql`
  query GetCommunities($pagination: PaginationArg) {
    communities(pagination: $pagination) {
      data {
        id
        attributes {
          name
          purpose
          rules {
            id
            ruleIdentity
            description
            name
          }
          website {
            websiteType
            website
            id
          }
          displayPic
          coverImg
          community_posts {
            data {
              id
              attributes {
                title
                postBody
                postType
                isNSFW
                likeCount
                cp_replies {
                  data {
                    id
                    attributes {
                      account {
                        data {
                          id
                          attributes {
                            address
                            name
                            displayPic
                          }
                        }
                      }
                      replyText
                      likeCount
                      createdAt
                      cp_replies {
                        data {
                          id
                          attributes {
                            account {
                              data {
                                id
                                attributes {
                                  address
                                  name
                                  displayPic
                                }
                              }
                            }
                            likeCount
                            createdAt
                            replyText
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
