import React from 'react';
import { useDispatch } from 'react-redux';
import { setSelectedCommunity } from '../../../state/user';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { IconButton } from '@mui/material';
import homeBanner from '../../../assets/images/home-widget-banner.png';
import WAVING_HAND_EMOJI from '../../../assets/images/WAVING_HAND_EMOJI.png';
import notifyBell from '../../../assets/images/notify.svg';

const Header = ({ data }) => {
  const dispatch = useDispatch();

  return (
    <div className="hw-header-community">
      <div className="hw-header-title">
        <IconButton className="icon-btn" onClick={() => dispatch(setSelectedCommunity())}>
          <ArrowBackIcon />
        </IconButton>
        <h1>
          Welcome to {data?.data?.attributes?.name} <img src={WAVING_HAND_EMOJI} alt="WAVING_HAND_EMOJI" />{' '}
        </h1>
      </div>

      <div
        className="banner-container"
        style={{ backgroundImage: `url(${data?.data?.attributes?.coverImg !== '' ? data?.data?.attributes?.coverImg : homeBanner})` }}
      ></div>
      <div className="head-widgets-community">
        <img className="bell-icon cursor" src={notifyBell} alt="bell-icon" />
        <div className="join-button-container">
          <button className="btn join-btn cursor">Joined ✔</button>
        </div>
      </div>
    </div>
  );
};

export default Header;
