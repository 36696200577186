import React from 'react';
import DashBoardHeader from './Header';
import DashBoardCard from './Card';
import SideSection from './SideSection';
import label from '../../assets/images/label.svg';
import filter from '../../assets/images/Filter.svg';
import { UserData } from './data';
import '../../assets/scss/dashboard/index.scss';
import { useMediaQuery } from '@mui/material';
import SideBar from '../../components/post-connect/side-bar';

const DashBoard = () => {
  const matches = useMediaQuery('(max-width: 1200px)'); //xl

  return (
    <div className="dashboard">
      <div className="d-container">
        <DashBoardHeader />
        <div className="d-body">
          <div className="d-body-head">
            <div className="status">
              <p className="status-pointer"></p>
              <h1>LIVE NOW</h1>
            </div>
            <div className="body-widget">
              <p>
                <span>
                  <img src={label} alt="label" />
                </span>{' '}
                Labels
              </p>
              <p>
                <span>
                  <img src={filter} alt="filter" />
                </span>{' '}
                Filter
              </p>
            </div>
          </div>
          <div className="d-body-content scroll-bar">
            {UserData.map((item) => (
              <DashBoardCard key={item.id} item={item} />
            ))}
          </div>
        </div>
      </div>
      {!matches && <SideSection />}
      <SideBar className="side-bar-mb mb" />
    </div>
  );
};

export default DashBoard;
