import arun from '../../assets/images/arun.png';
import babin from '../../assets/images/BABIN_CHAT_IMG.png';
import kavin from '../../assets/images/kavin.png';

export const Menu = ['Posts', 'Threads', 'Leaders', 'Tasks', 'Rewards', 'Rules', 'About', 'Settings'];

export const LiquidPoolData = [
  {
    id: Math.random(),
    num: 987,
    name: 'Movie Making',
  },
  {
    id: Math.random(),
    num: 600,
    name: 'Editing/VFX',
  },
  {
    id: Math.random(),
    num: 854,
    name: 'Photography',
  },
  {
    id: Math.random(),
    num: 916,
    name: 'Music',
  },
  {
    id: Math.random(),
    num: 887,
    name: 'Politics',
  },
];

export const CommentsData = [
  {
    name: 'Varun Kumar',
    desc: 'Become a good - if possible a great - visual artist or a very bad visual artist. Do you want to be world-famous for your artistic ability or just world-famous?',
    upvote: '10',
    time: 12,
    img: arun,
  },
  {
    name: 'Nishanth',
    desc: 'Take classes and practice a lot',
    upvote: '06',
    time: 6,
    img: babin,
  },
  {
    name: 'Nishanth',
    desc: 'Get some inspiration from the top designers',
    upvote: '15',
    time: 6,
    img: kavin,
  },
];

export const ScoreFilterList = ['Impact Score','Other Score'];
export const PopularFilterList = ['Popular','Old'];
export const DaysFilterList = ['Past 24 hours','Past 3 days','Past 7 days'];
