import React, { useState, useEffect } from 'react';
import '../../../../assets/scss/welcome-modal/welcome-modal-content3.scss';
import { movieDataRaw } from './insert-page-res/data';
import { bookDataRaw } from './insert-page-res/data';
import AddItemPopup from '../../../../utils/widgets/AddItemPopup';

import ZEROSTARRATING from '../../../../assets/images/NO_STAR_RATING.png';
import FOURSTARRATING from '../../../../assets/images/FOUR_STAR_RATING.png';
import SINGLESTAR from '../../../../assets/images/SINGLE_STAR.png';
import PLUSWHITE from '../../../../assets/images/PLUS_WHITE.png';
import PLUSGREY from '../../../../assets/images/PLUS_GREY.png';
import SearchIcon from '../../../../assets/images/searchIcon.png';
import ImageClose from '../../../../assets/images/image-close.png';
import ImageTick from '../../../../assets/images/image-tick.png';
import NavLeftArrow from '../../../../assets/images/nav-left-arrow.png';
import NavRightArrow from '../../../../assets/images/nav-right-arrow.png';
import { BRAND_ACCOUNTS, SKILLS_AND_EXPERIENCE } from '../../../../utils/constants';
import 'animate.css';

const WelcomeModal3 = ({ setWelcomeModalData, welcomeModalData }) => {
  const [movieData, setMovieData] = useState({ data: null, start: 0, end: 0, next: false });
  const [bookData, setBookData] = useState({ data: null, start: 0, end: 0, next: false });
  const [emojiActive, setEmojiActive] = useState(null);
  const [character, setCharacter] = useState([]);
  const [isPopUp, setIsPopUp] = useState(false);
  const [skills, setSkills] = useState([]);
  const [allSkillsRaw, setAllSkillsRaw] = useState([]);
  const [allSkills, setAllSkills] = useState({ data: null, start: 0, end: 0, next: false });

  useEffect(() => {
    setMovieData(handleInitialData(movieDataRaw));
    setBookData(handleInitialData(bookDataRaw));
    let result = SKILLS_AND_EXPERIENCE.filter((item, index) => {
      return index !== 4 && item;
    });
    setAllSkillsRaw([...result]);
    setAllSkills(handleInitialData(result));
  }, []);

  useEffect(() => {
    if (skills.length > 0) {
      let newSKill = { name: skills[0], color: allSkillsRaw[3].color };
      setAllSkillsRaw([newSKill, ...allSkillsRaw]);
      //setAllSkills({ data:result,...allSkills});
      setSkills('');
    }
  }, [skills]);

  useEffect(() => {
    setAllSkills(handleInitialData(allSkillsRaw));
  }, [allSkillsRaw]);

  const handleEmoji = (ind) => {
    setEmojiActive(ind);

    setWelcomeModalData({ ...welcomeModalData, yourMoodToday: ind });
  };
  const handleCharacter = (charc) => {
    let result = [...character];
    if (result.includes(charc)) {
      let index = result.indexOf(charc);
      result.splice(index, 1);
      setCharacter(result);
    }
    !character.includes(charc) && setCharacter([...character, charc]);
    setWelcomeModalData({ ...welcomeModalData, characterType: [result] });
  };
  const handlePopUp = () => {
    setIsPopUp(true);
  };
  /* Image Navigate */
  const handleNextData = (data, file) => {
    if (data.next) {
      let end = data.end + 4 <= file.length - 1 ? file.length : data.end + 4;
      let next = end < file.length;
      let movieResult = file.filter((item, index) => {
        return index > data.end && index <= end && item;
      });
      return { data: movieResult, start: data.start + 4, end: end, next: next };
    }
    return null;
  };
  const handlePrevData = (data, file) => {
    if (data.start > 0) {
      let end = data.end - 4;
      let start = data.start - 4 < 0 ? 0 : data.start - 4;
      let movieResult = file.filter((item, index) => {
        return index >= start && index <= end && item;
      });
      return { data: movieResult, start: start, end: end, next: true };
    }
    return null;
  };
  const handleInitialData = (file) => {
    let next = file.length > 4;
    let movieResult = file.filter((item, index) => {
      return index <= 3 && item;
    });
    return { data: movieResult, start: 0, end: 3, next: next };
  };

  const handleLeft = (type) => {
    if (type == 'movie') {
      let movie = handlePrevData(movieData, movieDataRaw);
      movie && setMovieData(movie);
    }
    if (type == 'book') {
      let book = handlePrevData(bookData, bookDataRaw);
      book && setBookData(book);
    }
    if (type == 'skill') {
      if (allSkills.start > 0) {
        let start = allSkills.end - 5 < 0 ? 0 : allSkills.end - 5;
        let end = start + 3;
        let next = allSkillsRaw.length - 1 !== end;
        let data = [...allSkillsRaw];
        let result = data.splice(start, 4);
        setAllSkills({ data: result, start: start, end: end, next: next });
      }
    }
  };
  const handleRight = (type) => {
    if (type === 'movie') {
      let movie = handleNextData(movieData, movieDataRaw);
      movie && setMovieData(movie);
    }
    if (type === 'book') {
      let book = handleNextData(bookData, bookDataRaw);
      book && setBookData(book);
    }
    if (type == 'skill') {
      if (allSkills.next) {
        let start = allSkills.end + 1;
        let length = allSkillsRaw.length;
        let end = start + 4 >= length ? length - 1 : start + 4;
        let next = allSkillsRaw.length - 1 !== end;
        let data = [...allSkillsRaw];
        let result = data.splice(start, 4);
        setAllSkills({ data: result, start: start, end: end, next: next });
      }
    }
  };

  return (
    <div className="welcome-modal-content3">
      {/* <div className="interest-page-header"> */}
      <p className="heading">Pick your interests</p>
      <p>What's your mood today?</p>
      <div className="mood-emojies-container">
        {['EMOJI_1', 'EMOJI_2', 'EMOJI_3', 'EMOJI_4', 'EMOJI_5'].map((item, index) => (
          <div className={emojiActive === index ? 'mood-emoji-border emoji-active' : 'mood-emoji-border'} onClick={() => handleEmoji(index)}>
            <img src={require(`../../../../assets/images/${item}.png`)} alt={item} />
          </div>
        ))}
      </div>
      <p>What's your character type?</p>
      <div className="character-container">
        {['Funny', 'Creative', 'Curious', 'Experimental'].map((item) => (
          <div onClick={() => handleCharacter(item)} className={character.includes(item) ? 'character-border character-active' : 'character-border'}>
            <img src={require(`../../../../assets/images/EMOJI_1.png`)} alt="" />
            <p>{item}</p>
          </div>
        ))}
      </div>
      <p>
        Choose your favourite movie / TV Series <span style={{ whiteSpace: 'nowrap' }}>( pick any 10 )</span>
      </p>
      <div className="movie-image-nav-container">
        <img
          onClick={() => handleLeft('movie')}
          className={movieData.start > 0 ? 'nav-left' : 'nav-left deactive'}
          src={NavLeftArrow}
          alt="nav-left"
        />
        <div className="movie-image-container">
          {movieData.data &&
            movieData.data.map((item, index) => (
              <div key={item.id} className="movie-image animate__animated animate__fadeIn">
                <div style={{ backgroundImage: item.backgroundImage }} className={`movie ${index === 0 ? 'blurred-image' : ''} image-action`}>
                  {item.id === movieDataRaw[0].id && (
                    <div className="input-container">
                      <img src={SearchIcon} alt="search-icon" />
                      <input className="movie-image-input" />
                    </div>
                  )}
                  <div className="action-container">
                    <img src={ImageClose} alt="icon" />
                    <img src={ImageTick} alt="icon" />
                  </div>
                </div>
                {index === 1 && <img src={ZEROSTARRATING} alt="ZEROSTARRATING" />}
                {index > 1 && <img src={FOURSTARRATING} alt="FOURSTARRATING" />}
              </div>
            ))}
        </div>
        <img
          src={NavRightArrow}
          onClick={() => handleRight('movie')}
          className={movieData.next ? 'nav-right' : 'nav-right deactive'}
          alt="nav-right"
        />
      </div>
      {/* Movie Nav Container Ends Here */}
      <img
        src={SINGLESTAR}
        alt="SINGLESTAR"
        style={{
          marginBottom: '20px',
        }}
      />
      <p>
        Choose your facourite Books <span style={{ whiteSpace: 'nowrap' }}>( pick any 5 )</span>
      </p>
      {/* Book Nav Container */}
      <div className="book-image-nav-container">
        <img onClick={() => handleLeft('book')} className={bookData.start > 0 ? 'nav-left' : 'nav-left deactive'} src={NavLeftArrow} alt="nav-left" />
        <div className="book-image-container">
          {bookData.data &&
            bookData.data.map((item, index) => (
              <div key={item.id} className="book-image animate__animated animate__fadeIn">
                <div style={{ backgroundImage: item.backgroundImage }} className={`book ${index === 0 ? 'blurred-image' : ''} image-action`}>
                  {item.id === bookDataRaw[0].id && (
                    <div className="input-container">
                      <img src={SearchIcon} alt="search-icon" />
                      <input className="movie-image-input" />
                    </div>
                  )}
                  <div className="action-container">
                    <img src={ImageClose} alt="icon" />
                    <img src={ImageTick} alt="icon" />
                  </div>
                </div>
                {index > 0 && (
                  <>
                    <img src={FOURSTARRATING} alt="FOURSTARRATING" />
                    <span>x</span>
                  </>
                )}
              </div>
            ))}
        </div>
        <img src={NavRightArrow} onClick={() => handleRight('book')} className={bookData.next ? 'nav-right' : 'nav-right deactive'} alt="nav-right" />
      </div>
      {/* Book Nav Container Ends Here */}
      <img
        src={SINGLESTAR}
        alt="SINGLESTAR"
        style={{
          marginBottom: '20px',
        }}
      />
      <p>Add your Skills & Experience</p>
      <div className="skills-nav-container">
        <img
          src={NavLeftArrow}
          onClick={() => handleLeft('skill')}
          className={allSkills.start > 0 ? 'nav-left' : 'nav-left deactive'}
          alt="nav-right"
        />
        <div className="skills-container">
          {allSkills.data &&
            allSkills.data.map((item, index) =>
              item.border ? (
                <div
                  key={item.name}
                  onClick={index + 1 === SKILLS_AND_EXPERIENCE.length && handlePopUp}
                  className="skill-border animate__animated animate__fadeIn"
                  style={{ border: item.border, justifyContent: 'space-evenly' }}
                >
                  <img src={PLUSGREY} alt="PLUSGREY" />
                </div>
              ) : (
                <div key={item.name} className="skill-border animate__animated animate__fadeIn" style={{ backgroundColor: item.color }}>
                  <p>{item.name}</p>
                  <img src={PLUSWHITE} alt="PLUSWHITE" />
                </div>
              ),
            )}
        </div>
        <img
          src={NavRightArrow}
          onClick={() => handleRight('skill')}
          className={allSkills.next ? 'nav-right' : 'nav-right deactive'}
          alt="nav-right"
        />
        <div onClick={handlePopUp} className="skill-border" style={{ border: '1px dotted #BCBDE9', justifyContent: 'space-evenly' }}>
          <img src={PLUSGREY} alt="PLUSGREY" />
        </div>
      </div>
      <p>Connect you accounts & showcase your talent</p>
      <div className="brand-container">
        {BRAND_ACCOUNTS.map((item) => (
          <div className="brand-border">
            <div>
              <img src={require(`../../../../assets/images/${item.src}.png`)} alt={item.src} />
              <p>{item.name}</p>
            </div>
            <img src={PLUSGREY} alt="PLUSGREY" />
          </div>
        ))}
      </div>
      {/* </div> */}
      {isPopUp && (
        <AddItemPopup onClose={() => setIsPopUp(false)} setItem={setSkills} item={skills} placeholder="Enter Skills" btnContent="Add Skills" />
      )}{' '}
    </div>
  );
};

export default WelcomeModal3;
