import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import ToggleButton from '../../../utils/widgets/ToggleButton';
import { USER_TYPE, YEARS_OF_EXPERIENCE } from '../../../utils/constants';
import BackArrowIcon from '../../../assets/images/back-arrow.svg';
import AddItemPopup from '../../../utils/widgets/AddItemPopup';
import '../../../assets/scss/landing-page/profile.scss';
import 'animate.css';

const UserProfile = (props) => {
  const { handleRegister, previousStep, setUserProfileInfo, userProfileInfo } = props;

  const [gender, setGender] = useState('I’m a male');
  const [enroll, setEnroll] = useState('true');
  const [freelance, setFreelance] = useState('true');
  const [isButton, setIsButton] = useState('creator');
  const [skills, setSkills] = useState(['Art', 'music', 'dance']);
  const [isPopup, setIsPopUp] = useState(false);
  const [experience, setExperience] = useState(1);
  const [navigate, setNavigate] = useState(null);
  // skill bar movement animatioin
  const handleRight = () => {
    setNavigate('right');
    let res = skills.splice(-1, 1);
    setSkills([...res, ...skills]);
  };

  const handleLeft = () => {
    setNavigate('left');
    let res = skills.splice(0, 1);
    setSkills([...skills, ...res]);
  };

  let navigateClass =
    navigate === 'left' ? 'animate__animated animate__fadeInRight' : navigate === 'right' ? 'animate__animated animate__fadeInLeft' : null;

  const handleAnimation = () => {
    setNavigate(null);
  };

  //validation
  const formSchema = Yup.object().shape({
    occupation: Yup.string().required('this field is required'),
    expertiseIn: Yup.string().required('this field is required'),
  });

  const validationOpt = { resolver: yupResolver(formSchema), mode: 'onTouched' };

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isDirty },
  } = useForm(validationOpt);

  const formatDate = (date) => {
    const splitted = date.split('/');
    const reversed = splitted.reverse();
    const joined = reversed.join('-');
    console.log(joined);
    return joined;
  };

  const handleUserProfileInfo = async (data) => {
    if (data && skills.length > 0) {
      if (gender === 'I’m a male') {
        data = { ...data, gender: 'male' };
      }
      if (gender === 'I’m a female') {
        data = { ...data, gender: 'female' };
      }

      data = await {
        ...data,
        isMentor: Boolean(enroll),
        isFreelance: Boolean(freelance),
        userType: isButton,
        skills: [...skills],
        experience: +experience,
      };
      setUserProfileInfo(data);

      handleRegister(data);
    }
  };

  return (
    <>
      <form className="user-container" onSubmit={handleSubmit(handleUserProfileInfo)}>
        <div className="user-container-header">
          <div className="profile-header">Create your user profile</div>
          <div className="profile-describe">
            <p className="describe-text">Describe yourself</p>
            <div className="profile-describe-box">
              {USER_TYPE.map((data, i) => (
                <div
                  key={i}
                  style={{
                    border: isButton === data ? 'none' : '1.4px solid #c4c4c4',
                    cursor: data === 'fan' ? 'not-allowed' : data === 'mentor' ? 'not-allowed' : 'pointer',
                    display: 'grid',
                    placeContent: 'center',
                    background:
                      isButton === data
                        ? 'linear-gradient(107.9deg, #c7c8ef -42.27%, #7c7ef7 53.1%)'
                        : 'linear-gradient(#ffffff, #ffffff) padding-box, linear-gradient( 90.94deg,#7c7ef7 22.28%,#fe98bf 122.25%,rgba(188, 189, 233, 0) 122.26%)border-box',
                    color: isButton === data ? '#ffffff' : '#9c9ea9',
                  }}
                  className="custom-border-button"
                  // onClick={() => setIsButton(data)}
                >
                  {data}
                </div>
              ))}
              {/* <CustomBorderButton>Mentor</CustomBorderButton>
            <CustomBorderButton> Creator</CustomBorderButton> */}
            </div>
          </div>
          <div className="profile-content">
            <div className="profile-content-text-box">
              <p className="profile-content-text">Gender</p>
            </div>
            <div className="profile-content-input-box">
              <ToggleButton
                selectedMode={gender}
                setSelectedMOde={setGender}
                options={['I’m a male', 'I’m a female']}
                style={{ width: '240px', height: '35px', border: '1.4px solid #C4C4C4' }}
              />
            </div>
          </div>
          <div className="profile-content">
            <div className="profile-content-text-box">
              <p className="profile-content-text">Occupation</p>
            </div>
            <div className="profile-content-input-box">
              <input type="text" className="profile-input" {...register('occupation')} />
              <p className="error-text">{errors.occupation?.message}</p>
            </div>
          </div>
          <div className="profile-content">
            <div className="profile-content-text-box">
              <p className="profile-content-text">DOB</p>
            </div>
            <div className="profile-content-input-box">
              <input
                type="date"
                className="profile-input"
                defaultValue="1996-01-20"
                pattern="\d{4}-\d{2}-\d{2}"
                style={{ color: '#666779' }}
                {...register('DOB', { required: true })}
              />
            </div>
          </div>
          <div className="profile-content">
            <div className="profile-content-text-box">
              <p className="profile-content-text">Expertise in Industry</p>
            </div>
            <div className="profile-content-input-box">
              <input type="text" className="profile-input" {...register('expertiseIn')} />
              <p className="error-text">{errors.expertiseIn?.message}</p>
            </div>
          </div>
          <div className="profile-content">
            <div className="profile-content-text-box">
              <p className="profile-content-text">Years of Experience</p>
            </div>
            <div className="profile-content-input-box">
              <select name="Years" id="years" className="profile-select" onChange={(e) => setExperience(e.target.value)}>
                {/* <option value={1}>1</option>
                  <option value={2}>2</option>
                  <option value={3}>3</option>
                  <option value={4}>4</option> */}
                {YEARS_OF_EXPERIENCE.map((item) => (
                  <option value={item} key={item}>
                    {item}
                  </option>
                ))}
              </select>
            </div>
          </div>
          {/* <div className="profile-content">
              <div style={{ textAlign: 'end', width: '40%' }}>
                <p className="profile-content-text">Skills</p>
              </div>
              <div
                style={{
                  textAlign: 'start',
                  width: '60%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'start',
                  gap: '0.5rem',
                }}
                className="skills-content"
              >
                <img className="skill-arrow" onClick={handleLeft} src={navLeftArrow} alt="left-icon" style={{ cursor: 'pointer' }} />
                <div className="skill-button-container">
                  {skills.map((data, i) => (
                    <button
                      className={navigateClass}
                      onAnimationEnd={handleAnimation}
                      key={i}
                      style={{ backgroundColor: (i + 1) % 3 === 0 ? '#7c7ef7' : (i + 1) % 2 === 0 ? '#f8c56c' : '#52caed' }}
                    >
                      {data}
                    </button>
                  ))}
                </div>
                <img className="skill-arrow" onClick={handleRight} src={navRightArrow} alt="right-icon" style={{ cursor: 'pointer' }} />

                <button
                  className="skills-button"
                  style={{ background: 'none', border: '1px dashed #AAA9A9', cursor: 'pointer' }}
                  onClick={() => setIsPopUp(true)}
                >
                  +
                </button>
              </div>
            </div> */}
          <div className="profile-content">
            <div className="profile-content-text-box">
              <p className="profile-content-text">Enroll to be a Mentor</p>
            </div>
            <div className="profile-content-input-box">
              <ToggleButton
                selectedMode={enroll}
                setSelectedMOde={setEnroll}
                options={['true', 'false']}
                style={{ width: '134px', height: '35px', border: '1.4px solid #C4C4C4' }}
              />
            </div>
          </div>
          <div className="profile-content">
            <div className="profile-content-text-box">
              <p className="profile-content-text">Looking to freelance in Creatverse?</p>
            </div>
            <div className="profile-content-input-box">
              <ToggleButton
                selectedMode={freelance}
                setSelectedMOde={setFreelance}
                options={['true', 'false']}
                style={{ width: '134px', height: '35px', border: '1.4px solid #C4C4C4' }}
              />
            </div>
          </div>
          <div className="button-container">
            <div className="back-arrow">
              <img src={BackArrowIcon} alt="BackArrowIcon" onClick={previousStep} />
            </div>
            <button type="submit" style={{ cursor: isDirty ? 'pointer' : 'not-allowed' }}>
              Register &nbsp;
            </button>
          </div>
        </div>
        {isPopup === true && (
          <AddItemPopup onClose={() => setIsPopUp(false)} setItem={setSkills} item={skills} placeholder="Enter Skills" btnContent="Add Skills" />
        )}
      </form>
    </>
  );
};

export default UserProfile;
