import naveen from '../../assets/images/Ellipse 2.jpg';
import rahul from '../../assets/images/Ellipse 3.jpg';
import ranjith from '../../assets/images/Ellipse 4.jpg';
import user1 from '../../assets/images/Ellipse 5.jpg';
import arun from '../../assets/images/Ellipse 7.jpg';
import saranya from '../../assets/images/Ellipse 6.jpg';

export const UserData = [
  {
    id: Math.random(),
    userImg: saranya,
    userName: 'Saranya',
    guest: 'Mithra Selvan',
    available: [user1, saranya, arun, rahul],
  },
  {
    id: Math.random(),
    userImg: rahul,
    userName: 'Rahul',
    guest: 'Prakash Kumar',
    available: [user1, saranya, arun, rahul],
  },
  {
    id: Math.random(),
    userImg: naveen,
    userName: 'Naveen',
    guest: 'Preethi Kalai',
    available: [user1, saranya, arun, rahul],
  },
  {
    id: Math.random(),
    userImg: ranjith,
    userName: 'Ranjith',
    guest: 'Ajay Slin',
    available: [user1, saranya, arun, rahul],
  },
  {
    id: Math.random(),
    userImg: rahul,
    userName: 'Rahul',
    guest: 'Prakash Kumar',
    available: [user1, saranya, arun, rahul],
  },
];
